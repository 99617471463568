import RouterNames from '../../../containers/App/RouteNames';

export default {
  label: {
    parentId: 'Id Padre',
    accountId: 'Id de la Cuenta',
    firstName: 'Primer Nombre',
    middleName: 'Segundo Nombre',
    lastName: 'Apellido',
    city: 'Ciudad',
    state: 'Estado',
    organization: 'Organización',
    status: 'Estatus',
    search: 'Búsqueda',
    clear: 'Limpiar',
    home: 'Inicio',
    currency: 'Divisa',
    reason: 'Razón',
    action: 'Acción',
    pageSize: 'Tamaño de Página',
    attributeName: 'Nombre del Atributo',
    attributeType: 'Tipo de Atributo',
    length: 'Longitud',
    mandatory: 'Obligatorio',
    saveConfig: 'Guardar Configuración',
    createConfig: 'Crear Configuración',
    cancel: 'Cancelar',
    addNewAttribute: 'Añadir nuevo Atributo',
    customerSegment: 'Segmento de Clientes',
    salesChannel: 'Canal de Ventas',
    marketSegment: 'Segmento de Mercado',
    accountType: 'Tipo de Cuenta',
    accountSubType: 'Subtipo de Cuenta',
    creditLimit: 'Límite de Crédito',
    remove: 'Eliminar',
    addCreditProfile: 'Añadir Perfil Crediticio',
    sellingCompany: 'Empresa Vendedora',
    lineOfBusiness: 'Línea de Negocio',
    legalEntity: 'Entidad Legal',
    effectiveDate: 'Fecha Efectiva',
    id: 'Documento de Identidad',
    salutation: 'Saludo',
    position: 'Puesto',
    email: 'Correo Electrónico',
    phone: 'Teléfono',
    type: 'Tipo',
    roles: 'Roles',
    useAsBilling: 'Usar como Facturación',
    useAsSoldTo: 'Usar Como Vendido A',
    useAsPayment: 'Usar como Pago',
    useAsService: 'Usar como Servicio',
    addPhone: 'Añadir teléfono',
    deleteContact: 'Borrar contacto',
    addNewContact: 'Añadir nuevo contacto',
    street: 'Calle',
    extraLine: 'Linea adicional',
    landmark: 'Punto de referencia',
    country: 'País',
    postalCode: 'Código Postal',
    code: 'Código',
    deleteAddress: 'Eliminar Dirección',
    addNewAddress: 'Agregar nueva dirección',
    paymentTerm: 'Términos de Pago',
    paymentMethod: 'Forma de Pago',
    check: 'Cheque',
    creditCard: 'Tarjeta de Crédito',
    billingDom: 'Día del mes de Facturación',
    billingSegment: 'Segmento de Facturación',
    billingFrequency: 'Frecuencia de Facturación',
    invoiceType: 'Tipo de Factura',
    invoiceDelivery: 'Entrega de Facturas',
    paymentProfileId: 'Id del Perfil de Pago',
    creditCardToken: 'Token de la Tarjeta de Crédito',
    creditCardExpiry: 'Vencimiento de la Tarjeta de Crédito',
    deleteCardToken: 'Eliminar Token de Tarjeta',
    creditCardCVV: 'Código de Verificación de la Tarjeta de Crédito',
    currentBalance: 'Saldo Actual',
    currentBillTotal: 'Total actual de factura',
    lastInvoiceDate: 'Fecha de Última Factura',
    lastInvoiceTotal: 'Total de la Última Factura',
    lastInvoiceDueDate: 'Fecha de Vencimiento de la Última Factura',
    lastInvoiceDue: 'Última Factura Vencida',
    lastPaymentDate: 'Fecha del Último Pago',
    lastPaymentAmount: 'Monto del Último Pago',
    crARSinceLastInvoice: 'Cr CxC (Crédito)  desde la última factura',
    drARSinceLastInvoice: 'Db CxC (Débito) desde la última factura',
    createNewOrder: 'Crear nuevo Orden',
    modify: 'Modificar',
    service: 'Servicio',
    subscription: 'Suscripción',
    initialTerm: 'Plazo Inicial',
    initialTermUnit: 'Unidad del Plazo Inicial',
    renewalTerm: 'Período de Renovación',
    renewalTermUnit: 'Unidad del Período de Renovación',
    trialTerm: 'Plazo de prueba',
    trialTermUnit: 'Unidad del Plazo de Prueba',
    placeOrder: 'Realizar Orden',
    downgrade: 'Bajar de Categoría',
    upgrade: 'Subir de Categoría',
    resume: 'Reanudar',
    suspend: 'Suspender',
    renew: 'Renovar',
    create: 'Crear',
    provisioningId: 'Id de Aprovisionamiento',
    bundleId: 'Id del Bundle',
    packageId: 'Id del Paquete',
    subscriptionId: 'Id de la Suscripción',
    assets: 'Activos',
    priceOfferId: 'Id de Oferta de Precio',
    serviceUnitId: 'Id de la Unidad de Servicio',
    serviceType: 'Tipo de Servicio',
    priceOverride: 'Reemplazar Precio',
    priceOffset: 'Compensación de Precio',
    discountPercent: 'Porcentaje de Descuento',
    startDate: 'Fecha de Inicio',
    endDate: 'Fecha de Finalización',
    accountNumber: 'Número de Cuenta',
    balances: 'Saldos',
    currencyId: 'Id de la Divisa ',
    amount: 'Monto',
    pendingBills: 'Facturas Pendientes',
    total: 'Total',
    nonPayingTotal: 'Total No Pagado',
    billTotal: 'Total de la Factura',
    openBills: 'Abrir Cuentas',
    closeBills: 'Cerrar Cuentas',
    billUnitId: 'Id de Unidad de Factura',
    dueDate: 'Fecha de Vencimiento',
    due: 'Pendiente',
    invoiceNumber: 'Número de Factura',
    invoiceStatus: 'Estado de la Factura',
    transactions: 'Transacciones',
    itemId: 'ID del Artículo',
    netAmount: 'Importe Neto',
    source: 'Fuente',
    aRActivity: 'Actividad CxC',
    arTaxRule: 'Regla de Impuestos CxC',
    arCrDrType: 'Tipo de Crédito / Débito CxC',
    invoiceUnitId: 'Id de la Unidad de Factura',
    cRDrType: 'Tipo de Crédito / Débito',
    taxApplication: 'Aplicación de Impuestos',
    customerActivity: 'Actividad del Cliente',
    userId: 'Id del Usuario',
    entityType: 'Tipo de Entidad',
    activityDate: 'Fecha de Actividad',
    view: 'Ver',
    customerHierarchy: 'Jerarquía de Clientes',
    submittedDate: 'Fecha de Envío',
    next: 'Siguiente',
    order: 'Orden',
    purchasePackage: 'Comprar Paquete',
    purchaseBundle: 'Comprar Bundle',
    purchaseAlaCarte: 'Comprar a la carta',
    description: 'Descripción',
    acSubType: 'Subtipo de Cuenta',
    selected: 'Seleccionado',
    name: 'Nombre',
    pricingModel: 'Modelo de precios',
    serviceAddOn: 'Servicio añadido',
    isPartialFulfillmentAllowed: '¿Se permiten cumplimientos parciales?',
    discountOfferId: 'Id de la Oferta de Descuento',
    relativeStart: 'Inicio Relativo',
    relativeStartUnit: 'Unidad de Inicio Relativa',
    relativeStartDuration: 'Duración de Inicio Relativa',
    relativeEndUnit: 'Unidad de Finalización Relativa',
    relativeEndDuration: 'Duración de Finalización Relativa',
    priceOverrride: 'Reemplazar Precio',
    packageName: 'Nombre del Paquete',
    updateOrder: 'Actualizar Orden',
    submitOrder: 'Enviar Orden',
    approveOrder: 'Aprobar Orden',
    serviceUnits: 'Unidades de Servicio',
    subscriptionReason: 'Motivo de Suscripción',
    overrideLines: 'Anular líneas',
    addBundle: 'Agregar Bundle',
    productFamily: 'Familia de Productos',
    productSubType: 'Subtipo de Producto',
    productType: 'Tipo de Producto',
    revenueType: 'Tipo de Ingresos',
    revenueRecognitionType: 'Reconocimiento de Ingresos',
    externalId: 'Id externo',
    glAccount: 'Cuenta de Mayor',
    externalName: 'Nombre Externo',
    parentItemId: 'ID del Artículo Padre',
    isDiscountable: 'Es Descontable',
    isBundled: 'Está Empaquetado',
    editPricingItem: 'Editar Precio del Artículo',
    createNewItem: 'Crear nuevo artículo',
    createNewPackage: 'Crear Nuevo Paquete',
    transactionType: 'Tipo de Transacción',
    index: 'Índice',
    addNewCustomerPricing: 'Agregar nuevos precios al cliente',
    customPricing: 'Personalización de Precios',
    editPricingOffer: 'Editar Oferta de Precios',
    createNewPriceOffer: 'Crear nueva oferta de precios',
    minimumQuantity: 'Cantidad Mínima',
    maximumQuantity: 'Cantidad Máxima',
    validityUnit: 'Unidad de Vigencia',
    validityDuration: 'Duración de la Vigencia',
    dependency: 'Dependencia',
    addNewComponent: 'Añadir nuevo componente',
    addNewDependencyList: 'Agregar nueva lista de dependencias',
    dependencyList: 'Lista de dependencias',
    applyAOneOffPayment: 'Aplicar un Pago Único',
    invoicePaymentType: 'Tipo de Pago de Factura',
    oneOffAmount: 'Monto Único',
    applyTo: 'Aplicar a',
    routingNumber: 'Número de enrutamiento',
    checkNumber: 'Verificar Número ',
    newAddress: 'Nueva Direccion',
    bank: 'Banco',
    BANK: 'BANCO',
    paymentHistory: 'Historial de Pagos',
    paymentsAfterThisDate: 'Pagos Después de ésta fecha',
    remainingAmount: 'Cantidad Restante',
    paymentAmount: 'Monto del Pago',
    paymentDate: 'Fecha de Pago',
    paymentTxnId: 'Id de la Transacción de Pago ',
    autoAllocate: 'Autoasignación',
    manualAllocation: 'Asignación Manual',
    reversePayment: 'Reversión de Pago',
    paymentReversalReason: 'Motivo de Reversión del Pago',
    notes: 'Notas',
    addNewAction: 'Agregar nueva acción',
    offsetDays: 'Días de Compensación',
    addNewScheduleItem: 'Agregar nuevo elemento de programación',
    addNewAgent: 'Agregar nuevo agente',
    accountsCollection: 'Cobro de Cuentas',
    OfInvoicesInCollection: 'De facturas en Cobranza',
    reAssignAgent: 'Reasignar agente',
    agentActivity: 'Actividad del Agente',
    numberOfAccountsInCollection: 'Número de cuentas en Cobranza',
    averageAgeInCollection: 'Tiempo Promedio en Cobranza ',
    numberOfAccountsOutOfCollectionInLast30Days: 'Número de cuentas que han salido de Cobranza en los últimos 30 días',
    ofInvoicesInCollection: '# de facturas en Cobranza',
    invoiceUnitsInCollection: 'Unidades de Factura en Cobranza',
    totalInCollection: 'Total en Cobranza',
    collectionUnitStatus: 'Estado de la Unidad de Cobranza',
    collectionHistory: 'Historial de Cobranza',
    daysInCollection: 'Días en Cobranza',
    collectionHistoryDetail: 'Detalle del Histórico de Cobranza',
    closeDate: 'Fecha de Cierre',
    lastActionDate: 'Fecha de Última Acción',
    collectionActionsForInvoice: 'Acciones de Cobro por Factura',
    reasonCode: 'Código de Motivo',
    taxCode: 'Código de Impuestos',
    addNewReason: 'Agregar Nuevo Motivo',
    applyAdjustment: 'Aplicar Ajuste',
    taxRule: 'Regla de Impuestos',
    CRDRType: 'Tipo Crédito/Débito',
    arType: 'Tipo de CxC',
    settle: 'Asentar',
    settleADispute: 'Resolver una Disputa',
    writeOffAmount: 'Importe de Cancelación',
    writeOffPercent: 'Porcentaje de Cancelación',
    applyDispute: 'Aplicar Disputa',
    percent: 'Porcentaje',
    writeOffs: 'Cancelaciones',
    applyWriteOffs: 'Aplicar Cancelaciones',
    reverse: 'Reversión',
    writeOffReversal: 'Cancelación de Reversión',
    applyWriteOff: 'Aplicar Cancelación',
    unpaidBills: 'Facturas Sin Pagar',
    invoiceDate: 'Fecha de Factura',
    accountWriteoff: 'Cancelación de Cuenta',
    selfCareHubModules: 'Módulos del Centro de Auto-Atención',
    selfCarePermissions: 'Permisos de Auto-Atención',
    customerHubModules: 'Módulos del Centro de Clientes',
    customerManagementPermissions: 'Permisos de Gestión de Clientes',
    orderManagementPermissions: 'Permisos de Gestión de Ordenes',
    activityManagementPermissions: 'Permisos de Gestión de Actividad',
    subscriptionManagementPermissions: 'Permisos de Gestión de Suscripciones',
    pricingHubModules: 'Módulos del Centro de Precios',
    pricingManagementPermissions: 'Permisos de Gestión de Precios',
    bundleManagementPermissions: 'Permisos de Gestión de Bundles',
    billingHubModules: 'Módulos del Centro de Facturación',
    billingModulePermissions: 'Permisos del Módulo de Facturación',
    invoicingModulePermissions: 'Permisos del Módulo de Emisión de Facturas',
    ratingModulePermissions: 'Permisos del Módulo de Tasación',
    arHubModules: 'Módulos del Centro de CxC',
    arOpsPermissions: 'Permisos de Ops de CxC',
    paymentPermissions: 'Permisos de Pago',
    collectionPermissions: 'Permisos de Cobranza',
    opsHubModules: 'Módulos del Centro de Operaciones',
    userManagementPermissions: 'Permisos de Gestión de Usuarios',
    jobsManagementPermissions: 'Permisos de Gestión de Trabajos',
    tenantManagementPermissions: 'Permisos de Gestión de Instancias',
    revenueHubModules: 'Módulos del Centro de Ingresos',
    roleGroupName: 'Módulos del Centro de Ingresos',
    roleGroupStatus: 'Estado del Grupo de Roles',
    roleGroupType: 'Tipo de Grupo de Roles',
    userPassword: 'Contraseña de Usuario',
    userAddress: 'Dirección de Usuario',
    userContact: 'Contacto de Usuario',
    userRoles: 'Roles del Usuario',
    userRoleGroups: 'Grupos de Roles de Usuario',
    userCategory: 'Categoría de Usuario',
    userStatus: 'Estatus de Usuario',
    userType: 'Tipo de Usuario',
    editRole: 'Editar Rol',
    roleName: 'Nombre de Rol',
    roleStatus: 'Estado del Rol',
    roleType: 'Tipo de Rol',
    ACCTNo: 'No. de Cuenta',
    noRecord: 'No se encontraron Registros',
    customAttributes: 'Atributos Personalizados',
    customerManagement: 'Gestión del Cliente',
    creditProfile: 'Pefil Crediticio',
    creditProfileIndex: 'Indice del Perfil Crediticio',
    CHECK: 'CHEQUE',
    CREDIT_CARD: 'TARJETA_DE_CRÉDITO',
    NON_PAYING: 'NO PAGADO',
    field: 'Campo',
    mustBe: 'debe ser',
    VARCHAR: 'VARCHAR',
    BOOLEAN: 'BOOLEANO',
    NUMERIC: 'NUMÉRICO',
    INTEGER: 'ENTERO',
    createAccount: 'Crear Cuenta',
    createNew: 'Crear Nuevo',
    account: 'Cuenta',
    contacts: 'Contactos',
    delete: 'Eliminar',
    addresses: 'Direcciones',
    paymentProfile: 'Perfil de pago',
    billingProfile: 'Perfil de Facturación',
    ocBills: 'Facturas Abiertas / Cerradas',
    invoiceId: 'Id de la Factura',
    messageNoAccountHierarchy: 'Esta cuenta no es parte de una jerarquía',
    detail: 'Detalle',
    modifyOrder: 'Modificar Orden',
    newOrder: 'Nueva Orden',
    renewOrder: 'Renovar Orden',
    cancelOrder: 'Cancelar Orden',
    suspendOrder: 'Suspender Orden',
    resumeOrder: 'Reanudar Orden',
    services: 'Servicios',
    line: 'Línea',
    quantity: 'Cantidad',
    orderId: 'Id de Orden',
    bundleName: 'Nombre del Bundle',
    choosePackage: 'Elegir Paquete',
    company: 'Empresa',
    productLine: 'Línea de Producto',
    // addItem: 'Add Item',
    addItem: 'Agregar Artículo',
    add: 'Agregar',
    endUnit: 'Unidad Final',
    endDuration: 'Duración Final',
    startUnit: 'Unidad Inicial',
    startDuration: 'Duración Inicial',
    edit: 'Editar',
    flatPricing: 'Precios Fijos',
    recurringPricing: 'Precios Recurrentes',
    recurringUnit: 'Unidad Recurrente',
    frequency: 'Frecuencia',
    purchaseProration: 'Compra Prorrateada',
    cancelProration: 'Cancelar Prorrateo',
    upgradeProration: 'Ampliar Prorrateo',
    downgradeProration: 'Degradar Prorrateo',
    price: 'Precio',
    addPackage: 'Añadir Paquete',
    addPriceOffer: 'Añadir Oferta de Precio',
    selectPriceOffers: 'Seleccionar Ofertas de Precio',
    selectBundles: 'Seleccionar Bundle',
    editPackage: 'Editar Paquete',
    editDependency: 'Editar Dependencia',
    addDependency: 'Añadir Dependencia',
    detailType: 'Tipo de Detalle',
    method: 'Método',
    addPaymentMethod: 'Añadir método de pago',
    apply: 'Aplicar',
    invoiceDateOffset: 'Compensación en Fecha de Factura',
    days: 'Días',
    workingDay: 'Días laborables',
    addPaymentTerm: '+ Agregar Plazo de Pago',
    invoiceAmount: 'Monto de la factura',
    dueAmount: 'Monto Adeudado',
    creditCardPayment: 'Pago con Tarjeta de Crédito',
    newCard: 'Nueva Tarjeta',
    last4CC: 'Últimos 4 dígitos de la T.C.',
    cardToken: 'Token de Tarjeta',
    cardExp: 'Expiración de Tarjeta',
    applyAmount: 'Aplicar Importe',
    suspenseReason: 'Razón de Suspensión',
    suspenseStatus: 'Estado de la Suspensión',
    closeSuspense: 'Cierre de Suspensión',
    applySuspense: 'Aplicar Suspensión',
    collections: 'Cobranza',
    collectionActions: 'Acciones de Cobranza',
    collectionSchedule: 'Calendario de Cobros',
    collectionAgents: 'Agentes de Cobranza',
    accounts: 'Cuentas',
    aROpsConfig: 'Configuración de Operaciones de CxC',
    openADispute: 'Abrir una Disputa',
    numberOfTransactions: 'Numero de Transacciones',
    date: 'Fecha',
    settledAmount: 'Monto Liquidado',
    settledDate: 'Fecha de Liquidación',
    recoveredDebt: 'Deuda Recuperada',
    recoveredDate: 'Fecha de Recuperación',
    disputeAmount: 'Cantidad en Disputa',
    disputePercent: 'Porcentaje en Disputa',
    adjustmentDetails: 'Detalles del Ajuste',
    disputeDetail: 'Detalle de Disputa',
    addRole: 'Agregar Rol',
    save: 'Salvar',
    limit: 'Límite',
    permission: 'Permiso',
    read: 'Leer',
    readWrite: 'Leer escribir',
    none: 'Ninguna',
    editRoleGroup: 'Editar Roles de Grupo',
    chooseRoles: 'Elegir Roles',
    addRoleToRoleGroup: 'Agregar rol al Grupo de Roles',
    addRoleGroup: 'Agregar Roles de Grupo',
    createNewRoleGroup: 'Crear Nuevo Grupo de Roles',
    category: 'Categoría',
    createNewUser: 'Crear Nuevo Usuario',
    addUser: 'Agregar Usuario',
    chooseRoleGroup: 'Elegir Grupo de Roles',
    back: 'Atrás',
    jobType: 'Tipo de Trabajo',
    noJobTypes: 'No hay tipos de trabajo',
    submit: 'Enviar',
    pending: 'Pendiente',
    automatic: 'Automático',
    manual: 'Manual',
    createJobSchedule: 'Crear Calendario de Trabajo',
    pause: 'Pausa',
    process: 'Procesar',
    reProcess: 'Reprocesar',
    noJobScheduleOn: 'No hay trabajo programado el',
    jobSchedule: 'Calendario de Trabajo',
    processAction: 'Acción del Proceso',
    retry: 'Reintentar',
    stats: 'Estadísticas',
    scheduleTime: 'Tiempo Programado',
    completed: 'Completado',
    error: 'Error',
    jobStats: 'Estadísticas de Trabajo',
    totalRecord: 'Registro total',
    sucessfulRecords: 'Registros exitosos',
    errorRecords: 'Registros de Error',
    jobErrors: 'Errores de trabajo',
    errorCode: 'Código Error',
    numberOfRecordsError: 'Número de Registros en Error',
    jobErrorDetails: 'Detalles del Error del Trabajo',
    entityId: 'Id de Entidad',
    payload: 'Carga útil',
    manageUserProfile: 'Administrar Perfil de Usuario',
    manageProfile: 'Administrar Perfil',
    profile: 'Perfil',
    resetPassword: 'Restablecer la Contraseña',
    tenant: 'Instancia',
    companyProfile: 'Perfil de la Compañía',
    gatewayMerchantAccounts: 'Gateway de Cuentas Mercantiles',
    platformDefaults: 'Valores Predeterminados de la Plataforma',
    taxGateway: 'Gateway de Impuestos',
    paymentGateway: 'Gateway de Pagos',
    financeGateway: 'Gateway Financiero',
    vaultUri: 'URL de la Bóveda',
    vaultPath: 'Ruta de la Bóveda',
    licenseKey: 'Llave de Licencia CCP',
    number: 'Número',
    enquiryEmail: 'Consulta por Correo Electrónico',
    enquiryPhone: 'Contacto de Atención al Cliente',
    companyTaxId: 'RFC (Id Impuestos) de la Compañía',
    companyTag: 'Etiqueta de la Empresa',
    merchantName: 'Nombre del Comerciante',
    validityInMonths: 'Validez (en meses)',
    merchantAuthType: 'Tipo de Autenticación del  Comerciante',
    clientId: 'Id del Cliente',
    clientProfileId: 'ID de Perfil del Cliente',
    username: 'Nombre de usuario',
    password: 'Contraseña',
    authorizationKey: 'Clave de Autorización',
    merchantId: 'Identificación del Comerciante',
    privateKey: 'Llave Privada',
    publicKey: 'Llave Pública',
    companyId: 'Id de la Compañía',
    refreshToken: 'Renovar Token',
    clientSecret: 'Cliente Secreto',
    accessToken: 'Token de Acceso',
    defaultCurrency: 'Moneda Predeterminada',
    contactRole: 'Contacto del Rol',
    addressRole: 'Dirección del Rol',
    productCompany: 'Empresa de Producto',
    setOfBooks: 'Juego de Libros',
    phoneType: 'Tipo de Telefono',
    shortBillingCycle: 'Ciclo de Facturación Corto',
    paymentNotification: 'Notificacion de Pago',
    useUsageStartTime: 'Usar Hora de Inicio de Uso',
    paymentAutoAllocation: 'Auto Asignación de Pagos',
    taxItemId: 'ID del Elemento Tributario',
    orderStatus: 'Estado de la Orden',
    selfCareUserType: 'Tipo de Usuario de Autoatención',
    selfCareUserCategory: 'Categoría de Usuario de Auto-atención',
    passwordExpiryInMonths: 'Cuenta Contable Predeterminada',
    defaultGLAccount: 'Cuenta GL Predeterminada',
    batchSizeBilling: 'Tamaño de Lote de Facturación',
    batchSizePayment: 'Tamaño de Lote de Pagos',
    batchSizeInvoice: 'Tamaño de Lote de Factura',
    batchSizeCommon: 'Tamaño de Lote Común',
    paymentFailureNotification: 'Notificación de Pago Fallido',
    invoiceDueNotification: 'Notificación de Vencimiento de Factura',
    invoiceReminderThreshold: 'Umbral de Recordatorio de Factura',
    addNewProduct: 'Agregar Nuevo Producto',
    symbol: 'Símbolo',
    roundingMethod: 'Método de Redondeo',
    roundingPrecision: 'Precisión del Redondeo',
    accumulateQuantity: 'Cantidad Acumulada',
    addNewCurrency: 'Agregar Nueva Divisa',
    grantId: 'Id del Subsidio',
    addNewGrant: 'Agregar Nueva Subvención',
    accumulatorId: 'Id del Acumulador',
    addNewAccumulators: 'Agregar Nuevos Acumuladores',
    addTier: 'Agregar Niveles',
    tier: 'Nivel',
    messageDelete: '¿Está seguro de que desea eliminar este artículo?',
    dashboardConfig: 'Configuración del Dashboard',
    dashboardTile: 'Marcador del Dashboard',
    noDashboardTypes: 'Sin tipos de Dashboard',
    addADashboardTile: 'Agregar un marcador al Dashboard',
    savePreference: 'Guardar Preferencia',
    myDashboard: 'Mi Dashboard',
    newCustomer: 'Nuevo Cliente',
    subscriptionChurn: 'Rotación de Suscripciones',
    billedRevenue: 'Ingresos Facturados',
    billedCustomer: 'Cliente Facturado',
    milestoneId: 'Id del Hito',
    accumulators: 'Acumuladores',
    accumulator: 'Acumulador',
    grants: 'Subsidios',
    resourceId: 'Id del Recurso',
    amountUsed: 'Monto Usado',
    chooseCreditProfile: 'Elija el Perfil Crediticio',
    chooseAccount: 'Elegir Cuenta',
    chooseUser: 'Elegir Usuario',
    select: 'Seleccionar',
    unselect: 'Deseleccionar',
    yes: 'Sí',
    no: 'No',
    getToken: 'Obtener Token',
    paymentSuccess: 'Pago Exitoso',
    invoiceDue: 'Vencimiento de la Factura',
    invoiceTotal: 'Total de La Factura',
    paymentTransactionType: 'Tipo de Transacción de Pago',
    transactionId: 'ID de Transacción',
    paymentId: 'Identificación de Pago',
    thresholdAmount: 'Cantidad del Umbal',
    paymentTxtType: 'Tipo de Transacción de Pago',
    billType: 'Tipo de Cuenta',
    billStatus: 'Estado de la  Cuenta',
    minInvoiceThreshold: 'Umbral Mínimo de Factura',
    minimumBillThreshold: 'Umbral Mínimo de Cuenta',
    ageingThreshold: 'Umbral de Antigüedad',
    '015days': '0-15 Días',
    '1530days': '15-30 Días',
    '030days': '0-30 Días',
    '3060days': '30-60 Días',
    '6090days': '60-90 Días',
    over90days: '> 90 Días',
    totalDue: 'Total Vencido',
    trialToPaidConversion: 'Conversión de Prueba A Paga',
    accountsWithFailedPayment: 'Clientes Con Pagos Fallidos',
    subscriptionCancellations: 'Cancelaciones de Suscripciones',
    closureReason: 'Motivo de Cierre',
    orderType: 'Tipo de Orden',
    orderLevel: 'Nivel de Orden',
    orderSubType: 'Subtipo de Orden',
    productGroup: 'Grupo de Productos',
    productSubGroup: 'Subgrupo de Productos',
    itemName: 'Nombre Del Árticulo',
    priceOfferName: 'Nombre de Oferta de Precio',
    jobScheduleDate: 'Fecha de Programación de Trabajo',
    jobScheduleId: 'ID de la Programación del Trabajo',
    jobScheduleStatus: 'Estado de la Programación del Trabajo',
    jobId: 'Identificación del Trabajo',
    jobStatus: 'Estado Del Trabajo',
    successCount: 'Ejecuciones Exitosas',
    failureCount: 'Ejecuciones Fallidas',
    totalCount: 'Ejecuciones Totales',
    referenceId: 'Identificación de Referencia',
    referenceType: 'Tipo de Referencia',
    failureReason: 'Razón de Falla',
    jobDate: 'Fecha de Trabajo',
    expiryDays: 'Días de Vencimiento',
    trialType: 'Tipo de Prueba',
    trialLevel: 'Nivel de Prueba',
    dueThreshold: 'Umbral de Vencimiento',
    arOpsType: 'Tipo de Operaciones CxC',
    taxTreatment: 'Tratamiento Fiscal',
    credit_debit: 'Crédito/Débito',
    arThreshold: 'Umbral CxC',
    arLevel: 'Nivel CxC',
    recordId: 'ID del Registro',
    transactionDate: 'Fecha de Transacción',
    netTotal: 'Total Neto',
    monthYear: 'Mes Año',
    billId: 'ID de Facturación',
    writeOff: 'Cancelación',
    payment: 'Pago',
    adjusted: 'Ajustado',
    viewOrder: 'Ver Orden',
    hideLines: 'Ocultar Líneas',
    signEmbrix: 'Iniciar sesión en su cuenta',
    rememberMe: 'Recuérdame',
    forgotPassword: 'Olvidé la Contraseña',
    login: 'Iniciar sesión',
    embrixDetailLogin: 'Impulsando la Innovación y la Experiencia del Cliente con Embrix Order to Cash',
    copyrightText: '© Copyright (Derechos de Autor) 2024. EMBRIX Todos los Derechos Reservados',
    policyText: 'Términos y condiciones / Política de uso de PI / Política de privacidad',
    termsConditions: 'Términos y condiciones',
    PIUsePolicy: 'Política de uso de Información Personal',
    privacyPolicy: 'Política de Privacidad',
    createNewBundle: 'Crear Nuevo Bundle',
    editBundle: 'Editar Bundle',
    components: 'Componentes',
    choosePriceOffer: 'Elegir Oferta de Precios',
    rateUnit: 'Unidad Tarifaria',
    timeUnit: 'Unidad de Tiempo',
    zoneUnit: 'Unidad de Zona',
    impactUnit: 'Unidad de Impacto',
    holidayName: 'Nombre del Día Festivo',
    tierReference: 'Referecia del Nivel ',
    tierType: 'Tipo de Nivel',
    evaluationStart: 'Inicio de Evaluación',
    evaluationEnd: 'Fin de Evaluación',
    offsetMonths: 'Meses Compensados',
    pricingTerm: 'Plazo de Fijación de Precios',
    volumeTreatment: 'Tratamiento de Volumen',
    recurringFrequency: 'Frecuencia de Recurrencia',
    transactionAttributeBasedPricingModel: 'Modelo de Precios Basado en Atributos de la Transacción',
    recurringTieredPricingModel: 'Modelo de Precios Escalonados Recurrentes',
    tieredPricingModel: 'Modelo de Precios Escalonados',
    complexPricing: 'Precios Complejos (Uso)',
    complexPricingRecurring: 'Precios Complejos (Recurrente)',
    chooseBundle: 'Elegir Paquete',
    chooseGLAccount: 'Elegir Cuenta Libro Mayor',
    chooseItemId: 'Elegir ID de Artículo',
    cardType: 'Tipo de Tarjeta',
    invoiceUnits: 'Unidades de Factura',
    chooseItem: 'Elegir Artículo',
    createNewRole: 'Crear Nuevo Rol',
    jobConfig: 'Configuración de Trabajo',
    schedule: 'Programar',
    taxGatewayAttributes: 'Atributos del Gateway de Impuestos',
    endPointType: 'Tipo de Punto Final',
    endPointURL: 'URL de Punto Final',
    httpBasicAttributes: 'Atributos Básicos de HTTP',
    paymentGatewayAttributes: 'Atributos de la Gateway de Pago',
    jsonWebTokenAttributes: 'Atributos del Token Web Json',
    financeGatewayAttributes: 'Atributos del Gateway Financiero',
    oauthAttributesForm: 'Formulario de Atributos Oauth',
    taxationItemId: 'ID del Elemento Tributario',
    selfcareUserCategory: 'Categoría de Usuario de Auto-atención',
    resources: 'Recursos',
    copyToCreate: 'Copiar para Crear',
    startRange: 'Rango de Inicio',
    endRange: 'Rango Final',
    isQuantityScalable: 'Es Cantidad Escalable',
    unit: 'Unidad',
    expression: 'Expresión',
    addNewRateUnit: 'Agregar Nueva Unidad Tarifaria',
    selectOperator: 'Seleccionar Operador',
    addOperator: 'Agregar Operador',
    addField: 'Agregar Campo',
    selectRecord: 'Seleccionar Registro',
    selectField: 'Seleccionar Campo',
    timeConfig: 'Usar la Hora de Inicio para evaluar la configuración de Tiempo',
    workWeekConfiguration: 'Configuración de la Semana Laboral',
    peakHoursConfiguration: 'Configuración de Horas Pico',
    holidayConfiguration: 'Configuración de Vacaciones o Días Feriados',
    startDayOfWeek: 'Día de Inicio de la Semana',
    endDayOfWeek: 'Día Final de la Semana',
    startHourOfDay: 'Hora de Inicio del Día',
    endHourOfDay: 'Hora Final del Día',
    addNewHoliday: 'Agregar Nuevo Día Feriado',
    customRecords: 'Registro Personalizado',
    recordNumber: 'Número de Registro',
    recordType: 'Tipo de Registro',
    addNewRecord: 'Añadir Nuevo Registro',
    createCustomizedRecords: 'Crear Registros Personalizados',
    // createCustomizedRecords: 'Create customized records',
    import: 'Importar',
    export: 'Exportar',
    exportSpecs: 'Exportar Especificaciones',
    addNewFileMapping: 'Añadir Nuevo Mapeo de Archivos',
    copyToAdd: 'Copiar Para Agregar',
    usageFileMappingDetails: 'Detalles del Mapeo de Archivos de Uso',
    recordIndicator: 'Indicador de Registro',
    fileType: 'Tipo de Archivo',
    fileDelimiter: 'Delimitador de Archivo',
    fileDescription: 'Descripción del Archivo',
    sourceFieldNumber: 'Número de Campo Fuente',
    sourceField: 'Campo Fuente',
    targetField: 'Campo Objetivo',
    fieldDelimiterInFile: 'Delimitador de Campo en Archivo',
    sourceFileRecord: 'Registro de Archivo Fuente',
    targetRecord: 'Registro Objetivo',
    recordDelimiter: 'Delimitador de Registro',
    mapNextField: 'Mapear el Siguiente Campo',
    mapNextRecord: 'Mapear Siguiente Registro',
    quickNotes: 'Notas Rápidas',
    usageStreamsMapping: 'Mapeo de Flujos de Uso',
    addNewStreamMapping: 'Agregar Nuevo Mapeo de Flujo',
    usageStreamsDetails: 'Detalles de los Flujos de Uso',
    ratingType: 'Tipo de Tasación',
    active: 'Activo',
    addAnotherRatingType: 'Agregar otro tipo de Tasación',
    addAnotherStream: 'Agregar Otro Flujo',
    modules: 'Módulos',
    logUsageContainer: 'Contenedor de Bitácora de Uso',
    addAnotherModule: 'Agregar otro módulo',
    stream: 'Flujo',
    createNewDependency: 'CREAR NUEVA DEPENDENCIA',
    nonPayingAccount: 'Cuenta Que No Realiza Pagos',
    parentAccount: 'Cuenta Padre',
    logout: 'Cerrar Sesión',
    addSelections: 'Agregar Selecciones',
    addAnotherRowForComplexPricing: 'Agregar Otra Fila Para Precios Complejos',
    ok: 'Ok',
    addNewTransactionAttributePricing: 'Agregar Nuevo Precio de Atributo de Transacción',
    addNewTier: 'Agregar Nuevo Nivel',
    saving: 'Ahorro',
    reportPermissions: 'Permisos de Reportes',
    dashboardPermissions: 'Permisos del Dashboard',
    recurringData: 'Datos Recurrentes',
    usageData: 'Datos de Uso',
    taxData: 'Datos Fiscales',
    recurringType: 'Tipo Recurrente',
    recurringDuration: 'Duración Recurrente',
    proration: 'Prorrateo',
    scale: 'Escala',
    cycleStart: 'Inicio Del Ciclo',
    cycleEnd: 'Fin de Ciclo',
    appliedStart: 'Inicio Aplicado',
    appliedEnd: 'Fin Aplicado',
    offerType: 'Tipo de Oferta',
    unitPrice: 'Precio Unitario',
    jurisdiction: 'Jurisdicción',
    taxableAmount: 'Base Gravable',
    exemptAmount: 'Monto Exento',
    taxAmount: 'Importe del Impuesto',
    batchid: 'Id Del Lote',
    batchFileName: 'Archivo Batch',
    scaledQuantity: 'Cantidad Escalada',
    grantStartDate: 'Fecha de Inicio de La Subvención',
    grantEndDate: 'Fecha de Finalización de la Subvención',
    balanceUnitIndex: 'Índice de Unidad de Saldo',
    accumulatorStartDate: 'Fecha de Inicio del Acumulador',
    accumulatorEndDate: 'Fecha de Finalización Del Acumulador',
    invoice: 'Factura',
    invoices: 'Facturas',
    recreate: 'Re-crear',
    usageFileMapping: 'Mapeo de Archivos de Uso',
    download: 'Descargar',
    excel: 'Excel',
    report: 'Reporte',
    pdf: 'Pdf',
    addCustomAttributes: 'Agregar Atributos Personalizados',
    addUsageAttributes: 'Agregar Atributos de Uso',
    usageAttributes: 'Atributos de Uso',
    addTransactionAttributes: 'Agregar Atributos de Transacción',
    transactionAttributes: 'Atributos de Transacción',
    addTiers: 'Agregar Niveles',
    AROpsType: 'Tipo de Operaciones CxC',
    AROpsId: 'Identificación de Operaciones CxC',
    AROpsData: 'Datos de Operaciones CxC',
    value: 'Valor',
    tiers: 'Escalones',
    usageAttributeBase: 'Uso Basado En Atributos',
    addNewUsageAttributePricing: 'Agregar Nuevos Precios de Atributos de Uso',
    addAnotherField: 'Agrega Otro Campo',
    customerAttributes: 'Atributos del Cliente',
    fieldDelimiter: 'Delimitador de Campo',
    embrixRecordId: 'Id de Registro Embrix',
    useParentUsagePriceOffer: 'Usar Oferta Principal de Precio por Uso',
    triggerPendingBillNewSubsActivation: 'Dispara Facturación Pendiente al Activar Nueva Subscripción',
    triggerInvoicingOnNewSubsActivation: 'Dispara  Facturación en la Activación de Nuevas Suscripciones',
    triggerPendingBillForClosedSubs: 'Dispara Facturación Pendiente para Suscripciones Cerradas',
    includeSubsOnRegularInvoice: 'Incluir Suscripciones en la Facturación Regular',
    includeSubsOnManualInvoice: 'Incluir Suscripciones en la Facturación Manual',
    allowUsageProcessing: 'Permitir Procesamiento por Uso',
    useShortBillingCycle: 'Usar Ciclo de Facturación Corto',
    taxValidateAddress: 'Validar Dirección Fiscal',
    taxExemptionMidStartCycle: 'Exención de Impuestos a Mitad del Ciclo de Arranque',
    taxExemptionEndCycle: 'Exención de impuestos a Final del Ciclo',
    taxApplicable: 'Impuesto Aplicable',
    useAutoAllocation: 'Usar Asignación Automática',
    quickbooksRefreshToken: 'Actualizar Token Quick Books',
    isCollectionScheduleCached: '¿Se almacena en caché la Programación de Cobranza?',
    isCustomAttributeCached: '¿Se almacenan en caché los Atributos Personalizados?',
    coaExportFileName: 'Nombre de archivo de exportación de Coa',
    renewalReminderThreshold: 'Umbral de Recordatorio de Renovación',
    noOfThreads: 'Número de Subprocesos (Threads)',
    invoiceReadyNotification: 'Notificación de Factura Lista',
    cancelSubscriptionNotification: 'Notificación de Cancelación de Suscripción',
    renewalDueNotification: 'Notificación de Vencimiento de Renovación',
    newSubscriptionNotification: 'Notificación de Suscirpción Nueva',
    selfcareRole: 'Rol de Auto-Atención',
    addNewMapping: 'Añadir Nuevo Mapeo',
    addNewEndPointMapping: 'Agregar Nuevo Mapeo de Punto Final',
    addNewPaymentMethodMapping: 'Añadir  Nuevo Mapeo de Métodos de Pago',
    paymentGatewayURLMapping: 'Mapeo URL del Gateway de Pagos',
    taxGatewayURLMapping: 'Mapeo del URL del Gateway de Impuestos',
    financeGatewayURLMapping: 'Mapeo del URL del Gateway Financiero',
    providerName: 'Nombre del Proveedor',
    requestResponseGatewayMapping: 'Mapeo de Solicitud / Respuesta de Punto final del Gateway de Pagos',
    requestResponseTaxMapping: 'Mapeo de Solicitud/Respuesta del End Point del Gateway de Impuestos',
    requestResponseFinanceMapping: 'Mapeo de Solicitud/Respuesta del End Point del Gateway Financiero',
    // requestResponseFinanceMapping: 'Finance Gateway End-point Request/Response Mapping',
    requestResponseProvisionMapping: 'Mapeo de Solicitud/Respuesta del End Point del Gateway de Aprovisionamiento',
    // requestResponseProvisionMapping: 'Provision Gateway End-point Request/Response Mapping',
    addNewAttributeMapping: 'Agregar Nuevo Mapeo de Atributos',
    gatewayName: 'Nombre del Gateway',
    apiName: 'Nombre del API',
    levelOneFlag: 'Bandera Nivel Uno',
    canonicalAttribute: 'Atributo Canónico',
    sourceAttribute: 'Atributo de la Fuente',
    config: 'Configuración',
    AROpsItemMapping: 'Mapeo Artículo de operaciones de CxC',
    ARItemType: 'Tipo de artículo CxC',
    addNewItemMapping: 'Añadir Mapeo Nuevo Producto',
    ARReasonCodeDescription: 'Descripción del Código de Motivo de CxC',
    addNewReasonCodeMapping: 'Agregar Nuevo Mapeo de Código de Motivo',
    itemType: 'Tipo de Artículo',
    // itemType: 'Item Type',
    usageClass: 'Clase de Uso',
    addNewRateUnitMap: 'Agregar Nuevo Mapa de Unidades de Tarifa',
    AROpsGL: 'Libro Mayor Operaciones CxC',
    selectInvoice: 'Seleccionar Factura',
    chooseAccountId: 'Elegir Id de Cuenta',
    organizationSetup: 'Configuración de la Organización',
    exchangeRateType: 'Tipo de Tasa Cambiaria',
    newCurrencyExchangeConfiguration: 'Nueva Configuración de Intercambio de Divisas',
    sourceCurrency: 'Divisa Fuente',
    targetCurrency: 'Divisa Objetivo',
    exchangeRate: 'Tipo de Cambio',
    addNewExchangeRate: 'Añadir nuevo Tipo de Cambio',
    offsetValue: 'Valor de Compensación',
    milestoneDetails: 'Detalles del Hito',
    percentage: 'Porcentaje',
    manualFlag: 'Bandera de Manual',
    addNewMilestone: 'Agregar Nuevo Hito',
    newMilestoneConfiguration: 'Configuración de Nuevo Hito',
    chooseMiletstone: 'Elegir Hito',
    viewTransactions: 'Ver Transacciones',
    termStart: 'Inicio del Periodo',
    termEnd: 'Fin del Periodo',
    addNewRequestMapping: 'Añadir mapeo de Nueva Solicitud',
    addNewResponseMapping: 'Añadir mapeo de Nueva Respuesta',
    addNewAPIMapping: 'Añadir mapeo de Nueva API',
    manualBilling: 'Facturación Manual',
    billPendingCharges: 'Facturar Cargos Pendientes',
    addNewManualTransaction: 'Agregar Nueva Transacción Manual',
    runManualBill: 'Ejecutar Facturación Manual',
    scheduleWithDailyJobs: 'Programar con Trabajos Diarios',
    grossAmount: 'Cantidad Bruta',
    discountAmount: 'Monto de Descuento',
    totalTransactions: 'Total de Transacciones',
    ratedTransactions: 'Transacciones Tasadas',
    suspendedTransactions: 'Transacciones Suspendidas',
    erroredTransactions: 'Transacciones Erróneas',
    skippedTransaction: 'Transacciones Omitidas',
    pendingTransaction: 'Transacciones Pendientes',
    batchId: 'ID del Lote',
    fileName: 'Nombre del Archivo',
    fileDate: 'Fecha de Archivo',
    quantityUnit: 'Unidad Cuantitativa',
    usageId: 'Id de Uso',
    grossValue: 'Valor Bruto',
    netValue: 'Valor Neto',
    totalErrorTransactions: 'Total de Transacciones Erróneas',
    totalSuspendedTransactions: 'Total de Transacciones Suspendidas',
    remainingRevenue: 'Ingresos Restantes',
    totalRevenue: 'Total Ingresos',
    recognizedRevenue: 'Ingresos Reconocidos',
    billEndDate: 'Fecha de Finalización de la Facturación',
    trialEndDate: 'Fecha de Finalización de la Facturación',
    addNextLevel: 'Añadir Siguiente Nivel',
    alignPendingBillToBillFrequency: 'Alinear Factura Pendiente con Frecuencia de Facturación',
    taxJurisdictionLevel: 'Nivel de Jurisdicción Fiscal',
    jurisdictionLevel: 'Nivel Jurisdiccional',
    billMonth: 'Mes - Factura',
    totalTaxableAmount: 'Monto Total Gravable',
    totalExemptAmount: 'Monto Total Exento',
    crdrNoteId: 'ID de Nota de Crédito/Débito',
    objectType: 'Tipo de Objeto',
    termEndDate: 'Fecha de Finalización del Plazo',
    recognitionRevenue: 'Reconocimiento de Ingresos',
    totalFileRecordCount: 'Recuento Total de Registros de Archivos',
    totalFileRecordLoaded: 'Total de Registros de Archivos Cargados',
    totalFileRecordErrored: 'Total de Registros de Archivos con Errores',
    ratingStatusCount: 'Recuento de Estado de Tasación',
    ratingErrorCount: 'Recuento de Errores de Tasación',
    taxDescription: 'Descripción de Impuestos',
    taxCategory: 'Categoría de Impuestos',
    addRevenueSplitConfiguration: 'Agregar Configuración de División de Ingresos',
    addAdditionalSplitRow: 'Agregar Fila Dividida Adicional',
    delayedBilling: 'Facturación Retrasada',
    delayInterval: 'Intervalo de Retardo',
    addDelayedBilling: 'Agregar Facturación Retrasada',
    refund: 'Reembolso',
    settlement: 'Asentamiento',
    disputed: 'Disputado',
    invoiceTransferred: 'Factura Transferida',
    chargeBack: 'Devolución',
    gracePeriod: 'Periodo de Gracia',
    currencyExchangeEdit: 'Editar Cambio de Divisas',
    dashboardMonths: 'Meses del Dashboard',
    additionalTax: 'Impuesto Adicional',
    selectAccount: 'Seleccionar Cuenta',
    selectPurchaseOptions: 'Seleccionar Opciones de Compra',
    setOrderData: 'Establecer Datos de Orden',
    manageCreateOrder: 'Crear y Gestionar Orden',
    revenueTracking: 'Seguimiento de Ingresos',
    defaultCountry: 'País Predeterminado',
    modifyRemoveAsset: 'Quiere Modificar / Rliminar Activos Existentes',
    reclassifyTrueUpRevenue: 'Reclasificar Ingreso Compensable (True Up)',
    overrideMail: 'Anular Correo',
    sendInvoice: 'Enviar Factura',
    support29_30_31DayBilling: 'Soporte de Facturación de Día 29_30_31',
    moveDayForwardFor29_30_31DayBilling: 'Avanzar Día para Facturación de día 29_30_31',
    prorateShortOrLongCycle: 'Ciclo Prorrateado Corto o Largo',
    invoiceEndDateInclusive: 'Fecha de Finalización de la Factura Incluida',
    correspondencePermissions: 'Permisos de Correspondencia',
    revenueManagementPermissions: 'Permisos de Gestión de Ingresos',
    optIn: 'Adhesión',
    optOut: 'Exclusión',
    subscriptionStatus: 'Estatus de Suscripción',
    PONumber: 'Número de Código Postal',
    externalPurchaseOrder: 'Orden de Compra Externa',
    regenerateInvoice: 'Regenrar Factura',
    PO: 'Código Postal',
    customMessage: 'Mensaje personalizado',
    approveInvoice: 'Aprobar',
    addNewExternalPO: 'Agregar Nuevo Código Postal Externo',
    trialStatus: 'Estatus de Prueba',
    accountStatement: 'Estado de Cuenta',
    documentType: 'Tipo de Documento',
    subTotal: 'Subtotal',
    taxes: 'Impuestos',
    folioDate: 'Fecha del Folio',
    folioId: 'Id del Folio',
    discountOfferName: 'Nombre de la Oferta de Descuento',
    billStartDate: 'Fecha de Inicio de Factura',
    selectUpgradeOptions: 'Seleccionar Opciones de Actualización',
    upgradeOrder: 'Orden para Ascender de Categoría',
    downgradeOrder: 'Orden de Reducción de Categoría',
    upgradePackage: 'Actualizar Paquete',
    upgradeBundle: 'Actualizar Bundle',
    allocate: 'Asignar',
    paymentFinanceSync: 'Sincronización Financiera de Pagos',
    realTimeFinanceSync: 'Sincronización Financiera en Tiempo Real',
    downgradePackage: 'Degradar Paquete',
    downgradeBundle: 'Degradar Bundle',
    selectDowngradeOptions: 'Seleccionar Opciones de Degradación',
    addDatesToInvoiceDescription: 'Agregar Fechas a la Descripción de la Factura',
    before: 'Antes',
    after: 'Después',
    addNewLocation: 'Agregar Nueva Ubicación',
    location: 'Ubicación',
    shortName: 'Nombre Corto',
    primaryIndustry: 'Industria Principal',
    hqCountry: 'País de la Oficina Central',
    enterprise: 'Empresa',
    autoCreateLegalEntities: 'Creación Automática de Entidades Legales',
    autoCreateBusinessUnits: 'Creación Automática de Unidades de Negocio',
    companyLocations: 'Ubicaciones de la Empresa',
    addNewDivisions: 'Agregar Nuevas Divisiones',
    companyDivisions: 'Divisiones de la Empresa',
    fetch: 'Búsqueda',
    ACTIVE: 'ACTIVO',
    legalEntities: 'Entidades Legales',
    autoReverseWriteoffOnPayment: 'Cancelación del Pago Autoreversible',
    collectDebtFirst: 'Cobrar Deuda Primero',
    useProviderForARTax: 'Usar Proveedor para Impuestos CxC',
    withTaxARRule: 'Con Regla Fiscal CxC',
    multiCurrency: 'Multi Moneda',
    crossCountryPurchase: 'Compra Transnacional',
    crossCountryPayment: 'Pago Transnacional',
    trialExpiryReminderThreshold: 'Umbral de Recordatorio de Vencimiento de Prueba',
    defaultLanguage: 'Idioma predeterminado',
    futureOrderDateSystemTime: 'Hora del Sistema en Fecha de Orden Futura',
    recurringPeriodInInvoice: 'Periodo Recurrente en Factura',
    ccListForInvoiceDelivery: 'Lista CC Para Entrega de Facturas',
    invoiceApprovalEmailId: 'ID de Correo Electrónico de Aprobación de la Factura',
    invoiceApproval: 'Aprobación de la Factura',
    generateInvoicePdf: 'Generar Factura Pdf',
    inCompleteOrders: 'Órdenes Incompletas',
    openingBalance: 'Saldo de Apertura',
    closingBalance: 'Saldo Final',
    adjustmentType: 'Tipo de Ajuste',
    disputeType: 'Tipo de Disputa',
    writeOffType: 'Tipo de Cancelación',
    division: 'División',
    registrationId: 'Identificación de Registro',
    reportingId: 'ID de Reporte',
    taxId: 'RFC',
    alternateId: 'ID Alternativo',
    addNewLegalEntity: 'Agregar Nueva Entidad Legal',
    financeSyncBatchSize: 'Tamaño del Lote de la Sincronización Financiera',
    financeSyncBatchMode: 'Modo por Lotes de Sincronización Financiera',
    addNewBusinessUnits: 'Agregar Nuevas Unidades de Negocio',
    businessUnits: 'Unidades de Negocios',
    GLSegments: 'Segmentos de Libro Mayor',
    GLAccountRanges: 'Rangos de Cuentas de Libro Mayor',
    startingNumber: 'Número Inicial',
    addNewGLSubAccountRange: 'Agregar Nuevo Rango de Subcuenta de Libro Mayor',
    addNewGLAccountRange: 'Agregar Nuevo Rango de Cuenta de Libro Mayor',
    addNewSegmentValues: 'Agregar Nuevos Valores de Segmento',
    addNewSegment: 'Agregar Nuevo Segmento',
    businessUnit: 'Unidad de Negocio',
    addNewDepartment: 'Agregar Nuevo Departamento',
    departments: 'Departamentos',
    department: 'Departamento',
    costCenter: 'Centro de Costos',
    costType: 'Tipo de Costo',
    addNewCostCenter: 'Agregar Nuevo Centro de Costos',
    addNewCalendar: 'Agregar Nuevo Calendario',
    calendar: 'Calendario',
    adjustingPeriodCount: 'Ajuste del Recuento De Períodos',
    addNextYearPeriods: 'Agregar Períodos Del Próximo Año',
    calendarPeriods: 'Periodos de Calendario',
    calendarYear: 'Año Calendario (aaaa)',
    quarter: 'Trimestre',
    GLSegmentValues: 'Valores De Segmento Del Libro Mayor',
    leadingZeroes: 'Ceros a la Izquierda',
    segmentValues: 'Valores de Segmento',
    GLSubAccountRanges: 'Rangos de Subcuentas de Libro Mayor',
    GLSetup: 'Configuración Libro Mayor',
    prefix: 'Prefijo',
    delimiter: 'Delimitador',
    subAccountRanges: 'Rangos de Subcuenta',
    createNewDiscountOffer: 'Crear Nueva Oferta de Descuento',
    discountingModel: 'Modelo de Descuento',
    recurringDiscounting: 'Descuentos Recurrentes',
    flatDiscounting: 'Descuentos Fijos',
    complexDiscounting: 'Descuentos Complejos',
    complexDiscountingRecurring: 'Descuentos Complejos (Recurrentes)',
    recurringTieredDiscountingModel: 'Modelo de Descuento Escalonado Recurrente',
    customDiscounting: 'Descuentos Personalizados',
    editDiscountingOffer: 'Editar Oferta de Descuento',
    autoSendInvoice: 'Envío Automático de Factura',
    noteType: 'Tipo de Notas',
    creditNotes: 'Notas de Crédito',
    submitOrderAdvance:
      'Enviar Orden por Anticipado  (ejecutará la orden por adelantado  con fecha futura  el día de hoy)',
    pricingType: 'Tipo de Precio',
    discountingType: 'Tipo de Descuento',
    salesAgentId: 'ID de Agente de Ventas',
    PODescription: 'Descripción de Orden de Compra',
    addPOItem: 'Agregar Artículo a Orden de Compra',
    partialFulfillment: 'Cumplimiento Parcial',
    partialProvisioningEnabled: 'Aprovisionamiento Parcial Activado',
    taxPercent: 'Porcentaje de Impuesto',
    addNewTaxCode: 'Agregar Nuevo Código de Impuestos',
    jurisdictionList: 'Lista de Jurisdicciones',
    excludeList: 'Excluir lista de Jurisdicciones',
    minimumThreshold: 'Umbral Mínimo',
    maximumThreshold: 'Umbral Máximo',
    transactionName: 'Nombre de la Transacción',
    serviceName: 'Nombre del Servicio',
    serviceId: 'ID del Servicio',
    additionalTaxType: 'Tipo de Impuesto Adicional',
    additionalTaxDescription: 'Descripción Adicional de Impuestos',
    additionalTaxRate: 'Tasa de Impuestos Adicional',
    viewTaxCode: 'Ver Tasa de Impuestos',
    uom: 'Unidad de Medida',
    taxationModulePermissions: 'Permisos del Módulo de Impuestos',
    usageProcessBatchSize: 'Tamaño del Lote del Proceso de Uso',
    mediaFile: 'Archivo Multimedia',
    advertisement: 'Anuncio',
    keyLinksToShare: 'Enlaces Clave para Compartir',
    shortTitle: 'Título Corto',
    loadVideo: 'Cargar Video',
    loadImages: 'Cargar Imágenes',
    addAnotherImage: 'Agregar Otra Imagen',
    advHeader: 'Encabezado de Anuncio',
    advShortDescription: 'Descripción Corta Anuncio',
    linkText: 'Texto del Enlace',
    URL: 'URL',
    addAnotherAdv: 'Agregar otro Anuncio',
    offerName: 'Nombre de la Oferta',
    addNewRegionMapping: 'Agregar Nuevo Mapeo de Región',
    regionId: 'ID de Región',
    longDistanceType: 'Tipo de Larga Distancia',
    addNewZeroPriceUsage: 'Agregar Nuevo Precio Cero de Uso',
    usageType: 'Tipo de Uso',
    callingNumberPrefix: 'Prefijo del Número de Llamada',
    addSpecialPricing: 'Agregar Precio Especial',
    chooseDisscountOffer: 'Elegir Oferta de Descuento',
    selectFile: 'Seleccionar Archivo',
    upload: 'Cargar',
    dropingFile: 'Soltar el archivo aquí ...',
    uploadFileType: 'CARGAR FOTO O VIDEO',
    pendingBillTrigger: 'Activador de Facturación Pendiente',
    advanceBilling: 'Facturacón Anticipada',
    addInAdvanceBilling: 'Agregar Facturación Anticipada',
    daysInAdvance: 'Dias de Anticipación',
    recurringFeeAdvanceDom: 'Día del Mes para Tarifa Recurrente Anticipada',
    URLPath: 'Ruta URL',
    operatorId: 'ID Operador',
    operatorName: 'Nombre del Operador',
    addNewOperatorMap: 'Agregar Nuevo Mapeo de Operador',
    trunkGroup: 'Grupo de Troncales',
    site: 'Sitio',
    trafficType: 'Tipo de Tráfico',
    trunkType: 'Tipo de Troncal',
    interConnectIndicator: 'Inidicador de Interconexión',
    usageIndicator: 'Indicador de Uso',
    carrierId: 'Id del Carrier',
    interConnectType: 'Tipo de Interconexión',
    addNewTrunkRules: 'Agregar nuevas Reglas de Troncales',
    paymentFileType: 'Tipo de Archivo de Pago',
    uploadAdditionalFiles: 'Cargar Archivos Adicionales',
    uploadFile: 'Cargar Archivo',
    fileDateTime: 'Archivo Fecha/Hora',
    totalRecords: 'Registros Totales',
    importedRecords: 'Registros Importados',
    pendingRecords: 'Registros Pendientes',
    processedRecords: 'Registros Procesados',
    suspendedRecords: 'Registros Suspendidos',
    paymentType: 'Tipo de Pago',
    recordDate: 'Fecha del Registro',
    paymentLimit: 'Límite de Pago',
    reference1: 'Referencia 1',
    reference2: 'Referencia 2',
    box: 'Caja',
    authorizeCode: 'Código de Autorización',
    identification: 'Identificación',
    suspenseAccountFlag: 'Bandera de Cuenta en Suspenso',
    processedDate: 'Fecha de Procesamiento',
    recordTime: 'Registro de Tiempo',
    chargeSign: 'Signo del Cargo',
    credit: 'Crédito',
    balance: 'Saldo',
    conceptBank: 'Concepto Bancario',
    participantName: 'Nombre del Participante',
    beneficiaryName: 'Nombre del Beneficiario',
    beneficiary: 'Beneficiario',
    payingAccount: 'Cuenta de Pago',
    payingName: 'Nombre del Pago',
    refundCode: 'Código de Reembolso',
    refundReason: 'Motivo del Reembolso',
    beneficiaryRfc: 'RFC del Beneficiario',
    originatorRfc: 'RFC del Emisor',
    trackingKey: 'Llave de Seguimiento',
    bill: 'Factura',
    operationDate: 'Fecha de Operación',
    reference: 'Referencia',
    transactionCode: 'Código de Transacción',
    branchOffice: 'Sucursal',
    deposit: 'Depósito',
    withdrawal: 'Retiro',
    movement: 'Movimiento',
    detailedDescription: 'Descripción Detallada',
    processAll: 'PROCESA-TODO',
    addNewOrderSequence: 'Agregar Nueva Secuencia de Orden',
    task: 'Tarea',
    addNewOrderType: 'Agrega Nuevo Tipo de Orden',
    apiCategory: 'Categoría API',
    onErrorResubmit: 'En Caso de Error Reenvíar',
    validateOnly: 'Solo Validar',
    addNewOrderProvisioning: 'Agregar Nueva Orden de Aprovisionamiento',
    numberOfDays: 'Número de Dias',
    surchargeAmount: 'Cantidad de Recargo',
    addPaymentSurcharge: 'Agregar Pago de Recargo',
    concept: 'Concepto',
    charge: 'Cargo',
    orderManagementTaskSequence: 'Secuencia de Tarea de Gestión de Ordenes',
    provisioningTaskSequence: 'Secuencia de Tarea de Aprovisionamiento',
    addPendingBillTrigger: 'Agregar Activador  de Facturación Pendiente',
    dom: 'Día del Mes',
    usageFlag: 'Bandera de Uso',
    chooseOperatorConfig: 'Elija la Configuración del Operador',
    pacEnabled: 'Pac Habilitado',
    createOrganization: 'Crear Organización',
    ssoEnabled: 'SSO Habilitado',
    batchPaymentFileUpload: 'Carga de Archivo de Pago Batch',
    splitTaxCalculationByItem: 'Cálculo de Impuesto Dividido por Artículo',
    minimumCreditNoteThreshold: 'Umbral Mínimo de Nota de Crédito',
    minimumDebitNoteThreshold: 'Umbral Mínimo de Nota de Débito',
    pricingSync: 'Sincronización de Precios',
    billableServiceEnabled: 'Servicio Facturable Habilitado',
    chooseInvoice: 'Elija Factura',
    companyLogo: 'Logo de la Compañía',
    autoOverrideGrantAccumulator: 'Acumulador de Subvenciones de Anulación Automática',
    writeOffDetail: 'Cancelar Detalle',
    monthOffset: 'Compensación de Mes',
    '800Flag': 'Bandera 800',
    provisionGatewayAttributes: 'Atributos del Gateway de Aprovisionamiento',
    all: 'TODO',
    enterpriseDivisions: 'Divisiones Empresariales',
    legalentities: 'Personas Jurídicas',
    legalEntityBusinessUnits: 'Persona Jurídica - Unidades de Negocio',
    customReasonCode: 'Código de Motivo Personalizado',
    users: 'Usuarios',
    createNewUserGroups: 'Crear Nuevos Grupos de Usuarios',
    addNewUserGroups: 'Agregar Nuevos Grupos de Usuarios',
    groupId: 'ID del Grupo',
    groupName: 'Nombre del Grupo',
    addUserToGroup: 'Agregar Usuario al Grupo',
    selectUser: 'Seleccionar Usuario',
    merchant: 'Comerciante',
    storeAsPrimary: 'Almacenar como Primario',
    mcmAccumulatorList: 'Lista de Acumuladores de Validez Especial',
    createNewGroupsReason: 'Crear Nuevos Motivos de Grupo',
    addNewReasonGroups: 'Agregar Nuevos Motivos de Grupo',
    addReasonToGroup: 'Agregar Motivo al Grupo',
    showLines: 'Mostrar líneas',
    relativeEnd: 'Final Relativo',
    reasons: 'Motivos',
    addNewThreshold: 'Agregar Nueva Asignación de Umbral',
    arAction: 'Acción CxC',
    arOpsLevel: 'Nivel de Operaciones de CxC',
    maxThreshold: 'Umbral Máximo',
    miximumThreshold: 'Umbral Mínimo',
    chooseReasonCode: 'Elegir el Código de Motivo',
    createNewApproval: 'Crear Nueva Aprobación',
    userGroupId: 'ID del Grupo de Usuarios',
    userGroupName: 'Nombre del Grupo de Usuarios',
    addNewApproval: 'Agregar Nueva Aprobación',
    addAnotherApprovalLevel: 'Agregar Otro Nivel de Aprobación',
    chooseUserGroup: 'Elegir Grupo de Usuarios',
    customReason: 'Razón Personalizada',
    addNewUnit: 'Agregar Nueva Unidad',
    unitId: 'ID Unidad',
    grantType: 'Tipo de Subvención',
    referenceParent: 'Referencia Padre',
    comments: 'Comentarios',
    approve: 'Aprobado',
    reject: 'Rechazar',
    creditNoteData: 'Fecha de Aprobación de las Notas de Crédito',
    dateRequested: 'Fecha Solicitada',
    dateProcessed: 'Fecha de Procesamiento',
    provisioningIdRequired: 'ID de Aprovisionamiento Obligatorio',
    billableService: 'Servicio Facturable',
    editUser: 'Editar Usuario',
    applyInAdvance: 'Aplicar por Anticipado',
    serviceTypeProvisioningAttributeMapping: 'Mapeo de Atributos de Aprovisionamiento de Tipo de Servicio',
    addAnotherServiceMapping: 'Agregar otro Mapeo de Aprovisionamiento de Servicios',
    provisioningAttributeType: 'Tipo de Atributo de Aprovisionamiento',
    attributeTag: 'Etiqueta de Atributo',
    flexAttribute: 'Atributo Flexible',
    mandatoryFlag: 'Bandera Obligatoria',
    equipments: 'Equipos',
    locations: 'Ubicaciones',
    unitOfMeasure: 'Unidad de Medida',
    assetTag: 'Etiqueta del Activo',
    deliveryDate: 'Fecha de Entrega',
    primaryCode: 'Código Principal',
    inventoryItemId: 'Id. de Artículo de Inventario',
    commercialClasification: 'Clasificación Comercial',
    parentServiceName: 'Nombre del Servicio Principal',
    loadType: 'Tipo de Carga',
    pointName: 'Nombre del Punto',
    pointId: 'Id del Punto',
    ownerName: 'Nombre del Propietario',
    deliveryType: 'Tipo de Entrega',
    ring: 'Anillo',
    floor: 'Calle',
    colony: 'Colonia',
    zip: 'Código Postal',
    latitude: 'Latitud',
    longitude: 'Longitud',
    queueName: 'Nombre del Queue',
    addAnotherAttributeToMap: 'Agregar otro Atributo al Mapa',
    cost: 'Costo',
    viewProvisioningData: 'Ver Datos de Aprovisionamiento',
    serviceProvisioningData: 'Datos de Aprovisionamiento de Servicio',
    provisioningType: 'Tipo de Aprovisionamiento',
    tag1: 'Etiqueta1',
    tag2: 'Etiqueta2',
    tag3: 'Etiqueta3',
    glAccountType: 'Tipo de Cuenta GL',
    glAccountSubType: 'Subtipo de Cuenta GL',
    addNewGLAccountToCOA: 'Agregar Nueva Cuenta de GL al Catálogo de Cuentas',
    coaType: 'Tipo de Catálogo de Cuentas',
    addPolicy: 'Agregar Política',
    drItemId: 'Artículo de Débito',
    drGlAccount: 'Cuenta GL de Débito',
    drAccountType: 'Tipo de Cuenta de Débito',
    crItemId: 'Artículo de Crédito',
    crGlAccount: 'Cuenta GL de Crédito',
    crAccountType: 'Tipo de Cuenta de Crédito',
    addNewGLAccount: 'Agregar Nueva Cuenta de GL',
    noOfCalls: '# De llamadas',
    totalMinutes: 'Minutos Totales',
    amountUsd: 'Monto USD',
    amountMxn: 'Monto MXN',
    callType: 'Tipo de Llamada',
    ivaTaxItemId: 'ID de Artículo Grabado con IVA',
    iepsTaxItemId: 'ID de Artículo Grabado con IEPS',
    totalSeconds: 'Segundos Totales',
    paymentFile: 'Archivo de Pago',
    ipAddress: 'Dirección IP',
    volumeUpload: 'Carga de Volumen',
    volumeDownload: 'Descarga de Volumen',
    countLimit: "Top 'n' por Conteo",
    amountLimit: "Top 'n' por Importe",
    count: 'Conteo',
    lineDayOfMonth: 'Línea Día del Mes',
    originalNetworkId: 'ID de Red Original',
    costMxn: 'Costo MXN',
    costUsd: 'Costo USD',
    barChartDashboardType: 'Tipo de Dashboard de Gráfico de Barras',
    xaxisParameter: 'Parámetro del Eje de las X',
    yaxisParameter: 'Parámetro del Eje de las Y',
    noOfRecordsLimit: 'No de Límite de Registros',
    serviceProductCode: 'Código de Producto de Servicio',
    chargeType: 'Tipo de Cargo',
    isParent: 'Es Padre',
    addNewServices: 'Agregar Nuevos Servicios',
    componentServiceId: 'ID de Servicio del Componente',
    componentQuantity: 'Cantidad de Componentes',
    componentServiceName: 'Nombre del Servicio Componente',
    viewLocations: 'Ver Ubicaciones',
    locationId: 'ID de Ubicación',
    instanceId: 'ID de Instancia',
    statusId: 'ID de Status',
    productDescription: 'Descripción del Producto',
    serviceDiscount: 'Descuento del Servicio',
    medium: 'Medio',
    stateService: 'Estado de Servicio',
    releaseDate: 'Fecha de Liberación',
    dischargeDate: 'Fecha de Alta',
    administrativeState: 'Estado Administrativo',
    operationalState: 'Estado Operativo',
    cancelledDate: 'Fecha de Cancelación',
    cpId: 'ID de CP',
    zipCode: 'Código Postal',
    plusCode: 'Código Plus',
    creationDate: 'Fecha de Creación',
    fullName: 'Nombre completo',
    ownerId: 'ID del Propietario',
    validationStatus: 'Estado de Validación',
    cp: 'CP',
    floorSite: 'Floor Site',
    ringName: 'Nombre del Anillo',
    infrastructureEquipments: 'Equipos de Infraestructura',
    totalPrice: 'Precio Total',
    orderData: 'Datos de la Orden',
    capacity: 'Capacidad',
    commitment: 'Compromiso',
    tariff: 'Tarifa',
    rental: 'Alquiler',
    directCommitment: 'Compromiso Directo',
    directTariff: 'Tarifa Directa',
    directCost: 'Costo Directo',
    commitmentLeft: 'Compromiso Dejado',
    overageQuantity: 'Cantidad Excedente',
    overageCost: 'Costo Excedente',
    totalCost: 'Costo Total',
    saveRevenueSplit: 'Guardar División de Ingresos',
    splitInMultipleAccounts: 'Dividir en Varias Cuentas',
    addBillingAccounts: 'Agregar Cuentas de Facturación',
    viewBillingAccounts: 'Ver Cuentas de Facturación',
    componentServices: 'Servicios que lo Componen',
    locationName: 'Nombre de la Ubicación',
    dataType: 'Tipo de Datos',
    dataUnit: 'Unidad de Datos',
    dataValue: 'Valor de los Datos',
    discount: 'Descuento',
    splitService: 'Servicio Dividido',
    processSplit: 'Procesar la División',
    addNewSplitLine: 'Agregar Nueva Línea de División',
    splitConfig: 'Configuración dividida',
    paying: 'Pagando?',
    selectParent: 'Seleccionar Cuenta Padre',
    selectChild: 'Seleccionar Cunta Hijo',
    particalMerge: 'Fusión Parcial',
    moveProvisioning: 'Mover Aprovisionamiento',
    desperse: 'Dispersar',
    merge: 'Fusionar',
    addMoreAccounts: 'Agregar más Cuentas',
    clientAccountMovementMerge: 'Movimiento de Cuenta de Cliente - Fusión',
    parentServiceId: 'Id Servicio Padre',
    circuitId: 'Id del Circuito',
    clientCircuitId: 'Id del Circuito del Cliente',
    externalServiceId: 'Id del Servicio Externo',
    commodityPrice: 'Precio de los Productos Básicos',
    externalServiceIdOp: 'Op Id del Servicio Externo',
    includedLicenses: 'Licencias Incluidas',
    priceWithTax: 'Precio con Impuesto',
    alternateDescription: 'Descripción Alterna',
    invoiceLineAmount: 'Importe de la Línea de Factura',
    percentAdjustment: 'Ajuste Porcentual',
    amountAdjustment: 'Ajuste en Monto',
    splitOpportunityEnabled: 'División de Oportunidad Habilitada',
    sendAllDataToProvisioning: 'Enviar Todos los Datos a Aprovisionamiento',
    authorizationEnabled: 'Autorización Habilitada',
    invoiceLineDetails: 'Detalles de la Línea de Factura',
    batchFileTypeConfiguration: 'Configuración de Tipo de Archivo Batch',
    addNewFileTypeMapping: 'Agregar nuevo mapeo de Tipo de Archivo',
    billingAccountMovementMerge: 'Movimiento de Cuenta de Facturación - Fusión',
    paymentItemMap: 'Mapeo de Elemento de Pago',
    billableServiceSelect: 'Servicio Facturable',
    level: 'Nivel',
    subscriptionData: 'Datos de Suscripción',
    addNewUsageType: 'Agregar Nuevo Tipo de Uso',
    addNewServiceType: 'Agregar Nuevo Tipo de Servicio',
    serviceUsageType: 'Tipo de Uso del Servicio',
    orderProration: 'Prorrateo de la Orden',
    alignToCycle: 'Alinear Ciclo',
    waiveCancel: 'Renunciar a Cancelar',
    orderResonCode: 'Códigos de Motivo de la Orden',
    addNewReasonCode: 'Agregar Nuevo Código de Motivo',
    paymentFileName: 'Nombre del Archivo de Pago',
    contactNumber: 'Número de Contacto',
    rawUsageFiles: 'Archivos de Uso Crudos',
    fileId: 'Id del Archivo',
    platform: 'Plataforma',
    dateTime: 'Fecha/Hora',
    totalInputRecords: 'Registros de Entrada Totales',
    totalImportedRecords: 'Total de Registros Importados',
    totalErrorRecords: 'Total de Registros de Error',
    switchName: 'Cambiar Nombre',
    withinOrganization: 'Dentro de la Organización',
    capacityMerge: 'Fusión de Capacidad',
    selectCapacityMergeServiceForParent: 'Seleccione el Servicio de Fusión de Capacidad para la Cuenta Padre',
    addMoreAccountToMerge: 'Agregar más Cuentas para Fusionar',
    selectCapacityMergeBillableService: 'Seleccione el Servicio Facturable para la Fusión de Capacidades',
    prorateOnPartialSuspend: 'Prorratear en Suspensión Parcial',
    prorateOnPartialCancel: 'Prorratear en Cancelación Parcial',
    destination: 'Destino',
    direction: 'Dirección',
    ratingDescr: 'Descripción de la Tasación',
    accountingPeriod: 'Período Contable (MES-aaaa)',
    drCurrency: 'Débito Divisa',
    crCurrency: 'Crédito Divisa',
    drAmount: 'Débito Monto',
    crAmount: 'Crédito Monto',
    objectId: 'Id Objeto',
    journalId: 'Id Journal',
    startDateWithRank: 'Fecha de Inicio (>=)',
    endDateWithRank: 'Fecha de Finalización (<)',
    amountToReverse: 'Cantidad a Revertir',
    reversalReason: 'Motivo de la Reversión',
    allocateAmount: 'Asignar Importe',
    allocatePayment: 'Asignar Pago',
    paymentAllocation: 'Asignación de Pagos',
    batchName: 'Nombre del Batch',
    accountingDate: 'Fecha Contable',
    extractDate: 'Fecha de Extracción',
    period: 'Período',
    totalCreditAmount: 'Monto Total Acreditado',
    totalDebitAmount: 'Monto Total Debitado',
    ledgerId: 'Id del Libro Mayor',
    extractFile: 'Archivo de Extracción',
    actualFlag: 'Bandera Actual',
    ERPExtractsDetails: 'Detalles de Extractos del ERP',
    segment1: 'Seg 1',
    segment2: 'Seg 2',
    segment3: 'Seg 3',
    segment4: 'Seg 4',
    segment5: 'Seg 5',
    segment6: 'Seg 6',
    debit: 'Débito',
    glDate: 'Fecha GL',
    userGroup: 'Grupo de Usuarios',
    defaultSubscription: 'Suscripción Predeterminada',
    correspondenceTemplateConfiguration: 'Configuración de la Plantilla de Correspondencia',
    addNewCorrespondence: 'Agregar Nueva Correspondencia',
    viewDetails: 'Ver Detalles',
    planId: 'Id del Plan',
    planType: 'Tipo de Plan',
    serialId: 'Id de Serie',
    voiceLdiLdnPlans: 'Planes de Voz LDI/LDN',
    npaId: 'Id NPA',
    region: 'Región',
    countryCode: 'Código de País',
    addNewVoicePlan: 'Agregar Nuevo Plan de Voz',
    addNewRow: 'Agregar Nuevo Renglón',
    minimum: 'Mínimo',
    maximum: 'Máximo',
    batchFolioStamping: 'Estampado de Folios Batch',
    immediateNotesGeneration: 'Generación Inmediata de Notas',
    isChild: 'Es Hija',
    xchangeCurrency: 'Moneda de Cambio',
    xchangeRate: 'Tipo de Cambio',
    payemntThreshold: 'Umbral de Pago',
    xchangeSplit: 'División Diferencia Cambiaria (Cliente MCM)',
    splitType: 'Tipo de División',
    addNewXchangeRate: 'Agregar Nuevo Tipo de Cambio',
    contractedExchangeRates: 'Tipo de Cambio Contratado',
    accountCurrency: 'Moneda de la Cuenta',
    reversedAmount: 'Monto Revertido',
    chooseOperator: 'Elegir Operador',
    fetchParent: 'Buscar Padre',
    addProvisioning: 'Agregar Aprovisionamiento',
    addPricing: 'Agregar Precio',
    incommingOutgoing: 'Entrante/Saliente',
    tierMin: 'Nivel Mínimo',
    tierMax: 'Nivel Máximo',
    contractCapacity: 'Capacidad Contratada',
    commitmentBase: 'Compromiso Base',
    rentalAmount: 'Monto de Renta',
    viewPricing: 'Ver Precios',
    pricingDetails: 'Detalles de Precios',
    tierMaximum: 'Nivel Máximo',
    tierMinimum: 'Nive Mínimo',
    usageTypePricing: 'Precio por Tipo de Uso',
    npaPricing: 'Precio NPA',
    tierPricing: 'Precio Escalonado',
    limitPricing: 'Límite de Precios',
    includedDID: 'DID Incluido',
    includedQuantity: 'Cantidad Incluida',
    overagePrice: 'Precio por Excedente',
    priceOverCommitment: 'Precio por encima del Compromiso',
    viewMore: 'Ver Más',
    advanceFlag: 'Bandera Anticipada',
    partMonthWaive: 'Renunciar a Parte del Mes',
    calendarMonth: 'Mes Calendario',
    align2Cycle: 'Alienar al Ciclo',
    pacCustomizedPdfLayout: 'Diseño de PDF del PAC Personalizado',
    viewLov: 'Ver LOV',
    request: 'Solicitud',
    response: 'Respuesta',
    externalRefId: 'Id de Referencia Externa',
    customPaymentMethod: 'Método de Pago ©',
    separator: 'Separador',
    seedNumber: 'Número inicial',
    minimumLength: 'Longitud Mínima',
    listOfValues: 'Lista de Valores',
    taxRegimeId: 'Id del Régimen de Impuestos',
    externalUid: 'Id Externo (PAC UUID)',
    supportContactNumber: 'Número de Contacto de Soporte',
    supportEmail: 'Email de Soporte',
    tagLine: 'Eslógan',
    logoFile: 'Archivo de Logotipo',
    sequenceName: 'Nombre de la Secuencia',
    sequenceSeed: 'Número inicial de la Secuencia',
    sequenceInUse: 'Secuencia en Uso',
    address: 'Dirección',
    landMark: 'Punto de Referencia',
    selectAccountToSplit: 'Seleccione la Cuenta a Dispersar',
    undoPreviousMerge: 'Deshacer la Fusión Previa',
    toAccount: 'A la Cuenta',
    fromAccount: 'De la Cuenta',
    moveAllServices: 'Mover Todos los Servicios',
    viewBillables: 'Ver Facturables',
    accountSplit: 'Dispersión de Cuenta',
    split: 'Dispersión',
    capacitySplit: 'División de Capacidad',
    addMoreAccountToSplit: 'Agregar más Cuentas a Dispersar',
    triggerInvoicingWithManualBilling: 'Activar Facturación con Facturación Manual',
    triggerInvoicingWithPendingBill: 'Activar Facturación con Facturación Pendiente',
    childAccount: 'Cuenta Hijo',
    nonPaying: 'No Pago',
    provisioningLag: 'Retraso en Aprovisionamiento',
    disperse: 'Dispersar',
    emails: 'Email(‘s)',
    addAEmail: 'Agregar un email',
    crmId: 'Id CRM',
    billingAccountMovementDisperse: 'Movimiento de Cuenta de Facturación - Dispersar',
    activityId: 'Id de Actividad',
    selectAccountToUndo: 'Seleccionar Cuenta para Deshacer',
    undoPreviousDisperse: 'Deshacer la Dispersión Previa',
    sendEmail: 'Enviar Email',
    createSuspense: 'Crear Suspenso',
    reversalDate: 'Fecha de Reversión',
    subAccount: 'SubCuenta',
    interCompany: 'Inter Compañía',
    glOffset: 'Compensación GL',
    amountRecognized: 'Monto Reconocido',
    revenueActivityType: 'Tipo de Actividad de Ingresos',
    billed: 'Facturado',
    arOpsId: 'Id Ops CxC',
    itemSplitId: 'Id de división de artículo',
    extractBatchId: 'Id Extracto Batch',
    startDateWithRankMoreThan: 'Fecha de Inicio (>)',
    endDateWithRankLessThan: 'Fecha de Finalización (<=)',
    journals: 'Journals',
    paymentRefId: 'ID de Referencia de Pago',
    paymentSuspenseId: 'Id Pago en Suspenso',
    thresholdTolerance: 'Umbral de Tolerancia',
    exchangeDelta: 'Diferencia Cambiaria',
    paymentCurrency: 'Divisa de Pago',
    sourceCurrencyAmount: 'Importe de la Moneda de Origen',
    paymentSuspenseAccount: 'Cuenta de Suspensión de Pago',
    autoClientPaymentAllocation: 'Asignación Automática de Pagos de Clientes',
    paymentSuspenseAllocation: 'Asignación de Pagos en Suspenso',
    processSuspense: 'Proceso de Suspensión',
    reGenerate: 'Re-Generar',
    addNewInstallmentPlan: 'Agregar Nuevo Plan de Pago a Plazos',
    daysOffset: 'Días de Compensación',
    fixedInstallments: 'Plazos Fijos',
    installments: 'Pago a Plazos',
    invoicePaymentInstallments: 'Plazos de Pago de Facturas',
    installmentAmt: 'Monto a Plazos',
    paymentAmt: 'Importe de Pago',
    monthsOffset: 'Meses de Compensación',
    endingNumber: 'Número Final',
    notesAccount: 'Cuenta de Notas',
    notesName: 'Nombre de Notas',
    ratingDescription: 'Descripción de la Tasación',
    sourceSystem: 'Sistema Fuente',
    ratedUsageTransactions: 'Transacciones de Uso Tasadas',
    selectProvisioning: 'Seleccionar Aprovisionamiento',
    selectAll: 'Seleccionar Todo',
    selectSpecific: 'Selección Específica',
    selectRange: 'Seleccionar Rango',
    addRangeSelected: 'Agregar Rango Seleccionado',
    incommingRoute: 'Ruta Entrante',
    networkId: 'Id de Red',
    ratedQuantity: 'Cantidad Tarificada',
    perUsageQuantity: 'Por Cantidad de Uso',
    perUsageAmount: 'Por Monto de Uso',
    origNetworkId: 'Id de Red de Origen',
    ratingBatchId: 'Id del Batch de Tasación',
    invoiceCancellationReason: 'Motivo de Cancelación de Factura',
    billUnitStatus: 'Estado de la Unidad de Billing',
    invoiceUnitStatus: 'Estado de la Unidad de Facturación',
    invoiceCancellation: 'Cancelación de Factura',
    fullCredit: 'Crédito Total',
    invoiceExhibits: 'Exhibiciones en Facturas',
    addInstallments: 'Agregar Cuotas',
    gatewayType: 'Tipo de Gateway',
    approvalRequired: 'Aprobación Requerida',
    approvalCorrespondence: 'Correspondencia de Aprobación',
    rejectionCorrespondence: 'Correspondencia de Rechazo',
    useAccumulatorBalance: 'Usar Saldo Acumulador',
    impactType: 'Tipo de Impacto',
    cancelPayment: 'Cancelar el Pago',
    remainingGrant: 'Subvención Restante',
    grantName: 'Nombre de la Subvención',
    scaleUnit: 'Unidad de Escala',
    alpha2: 'Alfa-2',
    alpha3: 'Alfa-3',
    isdcode: 'Código ISD',
    continent: 'Continente',
    region1: 'Región-1',
    region2: 'Región-2',
    addNewCountryCodeMapping: 'Agregar Nueva Asignación de Código de País',
    idOperador: 'Id Operador',
    ido: 'Ido',
    ida: 'Ida',
    estatus: 'Estatus',
    claveCensal: 'Clave Censal',
    poblacion: 'Poblacion',
    municipio: 'Municipio',
    estado: 'Estado',
    central: 'Central',
    presuscripcion: 'Presuscripcion',
    asl: 'Asl',
    nir: 'Nir',
    serie: 'Serie',
    numeracionInicial: 'Numeracion Inicial',
    numeracionFinal: 'Numeracion Final',
    tipoRed: 'Tipo Red',
    modalidad: 'Modalidad',
    razonSocial: 'Razon Social',
    nombreCorto: 'Nombre Corto',
    oficioReserva: 'Oficio Reserva',
    fechaReserva: 'Fecha Reserva',
    oficioAsignacion: 'Oficio Asignacion',
    fechaAsignacion: 'Fecha Asignacion',
    fechaConsolidacion: 'Fecha Consolidacion',
    fechaMigracion: 'Fecha Migracion',
    puntoEntrega: 'Punto Entrega',
    ocupacion: 'Ocupacion',
    nirAnterior: 'Nir Anterior',
    transactionDescription: 'Descripción de la Transacción',
    billableServiceName: 'Nombre del Servicio Facturable',
    billableServiceId: 'ID de Servicio Facturable',
    paymentSource: 'Fuente de Pago',
    paymentReferenceId: 'ID de Referencia de Pago',
    paymentDescription: 'Descripción de Pago',
    itemServices: 'Servicios del Artículo',
    releaseStartDate: 'Fecha de Inicio de la Liberación',
    releaseEndtDate: 'Fecha de Finalización de la Liberación',
    deliveryStartDate: 'Fecha de Inicio de Entrega',
    deliveryEndDate: 'Fecha de Finalización de la Entrega',
    operativeStatus: 'Estado Operativo',
    adminStatus: 'Estado Administrativo',
    cancellationDate: 'Fecha de Cancelación',
    deliveryEndtDate: 'Fecha de Finalización de la Entrega',
    groupByType: 'Agrupar por Tipo',
    '90120days': '90-120 Días',
    '120150days': '120-150 Días',
    '150180days': '150-180 Días',
    '180270days': '180-270 Días',
    '270290days': '270-290 Días',
    '290365days': '290-365 Días',
    '365OverDays': '>365 Días',
    invoiceDueDate: 'Fecha de Vencimiento de la Factura',
    suspenseFlag: 'Bandera de Suspenso',
    dueStartDate: 'Fecha de Inicio de Vencimiento',
    dueEndDate: 'Fecha de Finalización de Vencimiento',
    invoiceSummaryTotal: 'Resumen de Factura Total',
    addOnTaxAmount: 'Agregar Monto de Impuesto',
    addOnTaxRate: 'Agregar Tasa de Impuesto',
    mainTaxRate: 'Tasa de Impuesto Principal',
    mainTaxAmount: 'Importe de Impuesto Principal',
    orderStartDate: 'Fecha de Inicio de la Orden',
    orderEndtDate: 'Fecha de Finalización de la Orden',
    cancellationStartDate: 'Fecha de Inicio de Cancelación',
    cancellationEndtDate: 'Fecha de Fin de Cancelación',
    accounttype: 'Tipo de Cuenta',
    delivered: 'Entregado',
    orderDate: 'Fecha de Orden',
    glSegment1: 'Segmento 1 GL',
    glSegment2: 'Segmento 2 GL',
    glSegment3: 'Segmento 3 GL',
    glSegment4: 'Segmento 4 GL',
    glSegment5: 'Segmento 5 GL',
    glSegment6: 'Segmento 6 GL',
    offsetAccount: 'Cuenta de Compensación',
    groupBy: 'Agrupar Por',
    writeOffSummaryTotal: 'Resumen Total de Cancelaciones',
    groupByNote: 'Agrupar por Nota',
    summaryTotal: 'Resumen Total',
    details: 'Detalles',
    invoiceDetails: 'Detalles de la Factura',
    taxableRate: 'Tasa Gravable',
    invoiceLines: 'Líneas de Factura',
    taxLines: 'Líneas de Impuestos',
    cutOffDom: 'Día de Mes del Corte',
    cancelAndSubstitute: 'Cancelar y Sustituir',
    exhibitsInvoiced: 'Exhibiciones Facturadas',
    projectEnabled: 'Proyecto Habilitado',
    reprocessInvoiceReason: 'Motivo del Reprocesamiento de la Factura',
    extractBatch: 'Extraer Batch',
    extractStatus: 'Extraer Estado',
    objectDate: 'Fecha del Objeto',
    totalSummary: 'Resumen Total',
    addonTaxAmount: 'Importe del Impuesto Adicional',
    journalTotal: 'Total del Journal',
    accountingLogTotal: 'Registro Contable Total',
    batchAmount: 'Importe del Batch',
    objectStartDate: 'Fecha de Inicio del Objeto',
    objectEndDate: 'Fecha de Finalización del Objeto',
    extractStartDate: 'Extraer Fecha de Inicio',
    extractEndDate: 'Extraer Fecha de Finalización',
    percentileValue: 'Valor Percentil',
    extractBatchStatus: 'Extraer Estado del Batch',
    exhibitsInvoices: 'Facturas de Exhibiciones',
    invoiceSubstitution: 'Sustitución de Factura',
    addNewLine: 'Agregar Nueva Línea',
    showChildServices: 'Mostrar Servicios Hijos /Incluidos',
    cutOffDOM: 'Día de Mes del Corte',
    inAdvanceFlag: 'Bandera Por Anticipado ',
    uploadVolume: 'Cargar Volumen',
    downloadVolume: 'Descargar Volumen',
    createNewInvoice: 'Crear Nueva Factura',
    userNumber: 'Número de Usuario',
    groupNumber: 'Número de Grupo',
    callingNumber: 'Número que Llama',
    callingPresentationIndicator: 'Indicador de Presentación de Llamada',
    calledNumber: 'Número Llamado',
    startTime: 'Hora de Inicio',
    userTimeZone: 'Zona Horaria del Usuario',
    answerIndicator: 'Indicador de Respuesta',
    answerTime: 'Tiempo de Respuesta',
    releaseTime: 'Tiempo de Liberación',
    terminationCause: 'Causa de Terminación',
    carrierIdentificationCode: 'Código de Identificación del Carrier',
    dailedDigits: 'Dígitos Marcados',
    callCategory: 'Categoría de Llamada',
    networkCallType: 'Tipo de Llamada de Red',
    networkTranslatedNumber: 'Número Traducido de la Red',
    networkTranslatedGroup: 'Grupo Traducido de Red',
    releasingParty: 'Parte Liberadora',
    route: 'Ruta',
    networkCallId: 'ID de Llamada de Red',
    codec: 'Códec',
    accessDeviceAddress: 'Dirección del Dispositivo de Acceso',
    group: 'Grupo',
    originalCalledNumber: 'Número Originalmente Llamado',
    originalCalledPresentationIndicator: 'Indicador de Presentación Originalmente Llamado',
    originalCalledReason: 'Razón Original de la Llamada',
    redirectingNumber: 'Número de Redireccionamiento',
    redirectingPresentationIndicator: 'Indicador de Presentación de Redireccionamiento',
    redirectingReason: 'Razón de Redireccionamiento',
    chargeIndicator: 'Indicador de Carga',
    typeOfNetwork: 'Tipo de Red',
    voicePortalInvocationTime: 'Tiempo de Invocación del Portal de Voz',
    localCallId: 'Id de Llamada Local',
    extAnswerDateTime: 'Respuesta Ext Fecha Hora',
    sreserved: 'SReservado',
    suserid: 'ID de Susuario',
    sotherPartyName: 'Nombre de la Otra Parte',
    extDurationMinutes: 'Minutos de Duración Ext',
    extDurationSeconds: 'Segundos de Duración Ext',
    client: 'Cliente',
    aPartyNumber: 'Número A Party',
    bPartyNumber: 'Número B Party',
    durationMinutesStart: 'Duración Minutos Inicio',
    durationMinutesEnd: 'Duración Minutos Fin',
    copyChangeToAllRecurring: 'Copiar Cambio a Todos los Recurrentes',
    copyChangeToAll: 'Copiar el Cambio en Todo',
    viewHistory: 'Ver Historial',
    historyDetails: 'Detalles de la Historia',
    originOperator: 'Operador de Origen',
    destinationOperator: 'Operador de Destino',
    filePath: 'Ruta de Archivo',
    numberOfRecords: '# Registros',
    billingStartDate: 'Fecha de Inicio de Facturación',
    billingEndDate: 'Fecha de Finalización de Facturación',
    numCalls: '# Llamadas',
    seconds: 'Segundos',
    billedRate: 'Tarifa Facturada',
    origin: 'Origen',
    operatorDisputeFile: 'Expediente de Disputa del Operador',
    viewRecords: 'Ver Registros',
    viewFiles: 'Ver Archivos',
    billingDate: 'Fecha de Facturación',
    cutoffDate: 'Fecha de Corte',
    uploadDisputeFile: 'Cargar Archivo de Disputa',
    fileCategory: 'Categoría de Archivo',
    operatorInvoiceFile: 'Archivo de Factura del Operador',
    uploadInvoiceFile: 'Cargar Archivo de Factura',
    vat: 'IVA',
    aPartyNumberStartRange: 'Rango de Inicio de Número A Party',
    aPartyNumberEndRange: 'Rango Final de Número A Party',
    bPartyNumberStartRange: 'Rango de Inicio de Número B Party',
    bPartyNumberEndRange: 'Rango Final de Número B Party',
    incomingRoute: 'Ruta Entrante',
    outgoingRoute: 'Ruta Saliente',
    sourceIpAddress: 'Dirección IP Origen',
    destinationIpAddress: 'Dirección IP de Destino',
    idd: 'IDD',
    IDO: 'IDO',
    callStatus: 'Estado de la Llamada',
    terminatingAccess: 'Terminación de Acceso',
    extIddAbc: 'Ext IDD ABC',
    extIddBcd: 'Ext IDD BCD',
    messageDeliveryStatus: 'Estado de Entrega de Mensajes',
    submitDate: 'Fecha de Envío',
    addressSrcDigits: 'Dígitos Dirección Origen',
    srcTon: 'Ton Destino',
    srcApi: 'API Destino',
    addressDestDigits: 'Digitos Dirección Destino',
    destTon: 'Ton Destino',
    destApi: 'API Destino',
    scheduleDeliveryTime: 'Programar Tiempo de Entrega',
    validityPeriod: 'Período de Validez',
    protocolId: 'Id del Protocolo',
    priority: 'Prioridad',
    registerDelivery: 'Registrar Entrega',
    replacePresent: 'Reemplazar Presente',
    dataCoding: 'Codificación de Datos',
    messageLength: 'Longitud del Mensaje',
    first20Chars: 'Primeros 20 Caracteres',
    optTag: 'Opt Tag',
    optLength: 'Opt Length',
    optVal: 'Opt Val',
    host: 'Host',
    esmClass: 'Esm Class',
    gsmsiei: 'Gsmsiei',
    gsmudh: 'Gsmudh',
    extIdCinta: 'Ext Id Cinta',
    messageId: 'Id del Mensaje',
    sequenceNumber: 'Número Secuencial',
    commandId: 'Id del Comando',
    commandLength: 'Longitud del Comando',
    startDateTime: 'Fecha y Hora de Inicio',
    processDate: 'Fecha de Procesamiento',
    operatorRecordLogFile: 'Archivo Histórico de Registros del Operador',
    operator: 'Operador',
    duration: 'Duración',
    uploadRecordLogFile: 'Cargar Archivo Histórico de Registros',
    monthEndCloseThreshold: 'Umbral de Cierre de Fin de Mes',
    defaultCalendar: 'Calendario Predeterminado',
    poId: 'ID de Orden de Compra',
    month: 'Mes',
    moveDate: 'Mover Fecha',
    addExceptions: 'Agregar Excepciones',
    exceptions: 'Excepciones',
    includeIva: 'Incluir IVA',
    includeIeps: 'Incluir IEPS',
    complement: 'Complemento',
    chooseProductCodes: 'Elegir Códigos de Producto',
    createdStart: 'Inicio de Creación  (>=)',
    createdEnd: 'Fin de Creación (<)',
    createdDate: 'Fecha de Creación',
    uomName: 'Nombre Unidad de Medida',
    requestResponseProjectMapping: 'Mapeo del End-Point de Solicitud/Respuesta del Gateway de Proyectos',
    requestResponseCustomerSupportMapping:
      'Mapeo del End-Point de Solicitud/Respuesta del Gateway de Soporte a Clientes',
    requestResponseDocumentMapping: 'Mapeo del End-Point de Solicitud/Respuesta del Gateway de Documentos',
    requestResponseOperationMapping: 'Mapeo del End-Point de Solicitud/Respuesta del Gateway de Operaciones',
    requestResponseCRMMapping: 'Mapeo del End-Point de Solicitud/Respuesta del Gateway de CRM',
    projectGatewayURLMapping: 'Mapeo de la URL del Gateway de Proyectos',
    customerSupportGatewayURLMapping: 'Mapeo de la URL del Gateway de Soporte a Clientes',
    documentGatewayURLMapping: 'Mapeo de la URL del Gateway de Documentos',
    operationGatewayURLMapping: 'Mapeo de la URL del Gateway de Operaciones',
    crmGatewayURLMapping: 'Mapeo de la URL del Gateway de CRM',
    effectiveStartDate: 'Fecha efectiva de Inicio (>=)',
    effectiveEndDate: 'Fecha efectiva de Finalización (<)',
    defaultCostCenter: 'Centro de Costos Predeterminado',
    foreignTaxId: 'Id Fiscal Extranjero',
    failureCode: 'Código de Falla',
    disconnectReason: 'Motivo de Desconexión',
    commercial: 'Comercial',
    callDuration: 'Duración de la Llamada',
    extIdoBcd: 'Ext. IDO BCD',
    stateProvince: 'Estado/Municipio',
    tenantCalls: 'Llamadas de la Instancia',
    tenantDuration: 'Duración de la Instancia',
    tenantRate: 'Tarifa de la Instancia',
    opInvoiceFile: 'Archivo de Factura de Operadores',
    tenantDisputeFile: 'Archivo de Disputas de Instancias',
    regId: 'ID de registro',
    numRegCalls: 'Número de Llamadas Registradas',
    numRegSeconds: 'Número de Segundos Registradas',
    recogRate: 'Tasa de Reconocimiento',
    disputeId: 'Id de la Disputa',
    disputeFileName: 'Nombre del Archivo de la Disputa',
    nim: 'NIM',
    anumber: 'A Number',
    bnumber: 'B Number',
    reconcile: 'Conciliar',
    returnPDFBase64: 'Devolver PDF Base64',
    max: 'Max',
    min: 'Min',
    avg: 'Promedio',
    saveSplit: 'Guardar División',
    commercialName: 'Nombre Comercial',
    defaultValue: 'Valor Predeterminado',
    cutOffDate: 'Fecha de Corte',
    partMonthWaiveOffFlag: 'Bandera de Renuncia Parcial del Mes',
    trafficFlag: 'Bandera de Tráfico',
    opportunity: 'Oportunidad',
    contractTerm: 'Duración del Contrato',
    dispersed: 'Dispersado',
    merged: 'Fusionado',
    undoProcessSplit: 'Deshacer Proceso de División',
    billOfLading: 'Carta Porte',
    expeditionCode: 'Código de Expedición',
    close: 'Cerrado',
    gross: 'Bruto',
    groupByAccount: 'Agrupar por Cuenta',
    location1: 'Ubicación 1',
    location2: 'Ubicación 2',
    taxVersion: 'Versión Fiscal',
    propagateToBillingAccounts: 'Propagar a Cuenta de Facturación',
    stamping: 'Estampado',
    stampingRecord: 'Registro de Estampado',
    modifiedDate: 'Fecha de Modificación',
    data: 'Datos',
    viewUploadFileStatus: 'Ver el Estátus de la Carga de Archivos',
    pendingCount: 'Conteo de Pendientes',
    errorCount: 'Conteo de Errores',
    uploadFileStatus: 'Estatus de Carga de Archivos',
    opportunityId: 'Id de la Oportunidad',
    opportunityName: 'Nombre de la Oportunidad',
    addReplacement: 'Agregar Reemplazo',
    moveIntoHierarchy: 'Mover a la Jerarquía',
    collectionAgentId: 'Id del Agente de Cobranza',
    pauseBilling: 'Pausar la Facturación',
    pauseInvoicing: 'Pausar la emisión de Facturas',
    chooseCollectionAgent: 'Elegir Agente de Cobranza',
    collectionUserId: 'Id del Usuario de Cobranza',
    tAndE: 'Tiempo & Gastos',
    employee: 'Empleado',
    addNewClient: 'Agregar Nuevo Cliente',
    addNewEmployee: 'Agregar Nuevo Empleado',
    jobTitle: 'Puesto',
    clientName: 'Nombre del Cliente',
    clientJobTitle: 'Puesto del Cliente',
    firstPayrollDate: 'Primer Fecha de Nómina',
    firstPayrollAmount: 'Primer Monto de Nómina',
    secondPayrollDate: 'Segunda Fecha de Nómina',
    secondSalaryAmount: 'Segundo Monto de Nómina',
    numDaysWorked: 'Número de Días Laborados',
    viewUploadFileDetails: 'Ver Detalles de Carga de Archivos',
    uploadEmployeePayroll: 'Cargar Nómina de Empleados',
    clientOnboarding: 'Incorporación de Clientes',
    addNewJobTitle: 'Agregar Nuevo Puesto Laboral',
    pricingUnit: 'Precio Unitario',
    workDays: 'Días de Trabajo',
    uploadClientContractPricing: 'Cargar Precios Contratados por el Cliente',
    employeeName: 'Nombre del Empleado',
    year: 'Años',
    employeeId: 'Id del Empleado',
    grossSalaryMxn: 'Sueldo Bruto MXN',
    actualCostMxn: 'Costo Real MXN (Sueldo Bruto* 1.2865)',
    revenueUSD: 'Ingresos USD',
    revenueMxn: 'Ingresos MXN',
    actIncomeMxn: 'Ganancias Reales MXN (Ingreso MXN - Costo Real MXN)',
    salaryToBilling: 'Salario a Facturar',
    customDescription: 'Descripción Personalizada',
    selective: 'Selectivo',
    nonUsage: 'No Uso',
    usage: 'Uso',
    lines: 'Líneas',
    addNewTransaction: 'Agregar Nueva Transacción',
    pendingManualInvoices: 'Facturas Manuales Pendientes',
    newManualInvoice: 'Nueva Factura Manual',
    stampingDate: 'Fecha de Timbrado',
    seg3Name: 'Nombre Seg3',
    accountActions: 'Acciones de Cuenta',
    pduType: 'Tipo PDU',
    aspName: 'Nombre ASP',
    messageStatus: 'Estado del Mensaje',
    messageType: 'Tipo de Mensaje',
    downloadFile: 'Descargar Archivo',
    reconcileBatch: 'Conciliar Batch',
    matchStatus: 'Estado de Coincidencia',
    fullyRecognized: 'Totalmente Reconocido',
    viewRevRec: 'Ver Reconocimiento de Ingresos',
    revenueRecognitionJournals: 'Journals de Rec de Ingresos',
    addNewRevenueJournals: 'Agregar Nuevo Journal de Ingresos',
    createOption: 'Crear Opción',
    gatewayMerchant: 'Gateway del Comerciante',
    reratePendingTransactionsOnly: 'Retarificar Solo Transacciones Pendientes',
    IEPS: 'IEPS',
    IVA: 'IVA',
    validity: 'Validez',
    reratingBatch: 'Batch Re-Tarificación',
    backoutOnly: 'Solo Devoluciones',
    usageOnly: 'Solo Uso',
    primaryTaxType: 'Tipo de Impuesto Primario',
    addOnTaxType: 'Agregar Tipo de Impuesto',
    taxRate: 'Tasa de Impuesto',
    notesOffset: 'Compensación de Notas',
    collectionAgentAssigned: 'Agente de Cobranza Asignado',
    reratingBatches: 'Batches de Re-Tarificación',
    startDateMoreThanAndEqual: 'Fecha de Inicio (> =)',
    endDateLessThan: 'Fecha de Finalización (<)',
    createNewBatch: 'Crear Nuevo Batch',
    selectAccountsForRerating: 'Seleccionar Cuentas para Re-Tarificar',
    selectIfYouNeedToCreateChildAccount: 'Seleccionar si usted necesita crear una Cuenta Hijo',
    allowInFlightOrders: 'Permitir  Órdenes en Vuelo',
    alignModifyPricingEffectiveDate: 'Alinear Modificar Precios Fecha de Vigencia',
    exchangeRateDeltaItemId: 'Id. de Elemento de Diferencia Cambiaria',
    realizedGainItemId: 'Id. de Elemento de Ganancias Realizadas',
    migrationBatchSize: 'Tamaño del Batch de Migración',
    noOfBatchProcessThreads: 'No. de Hilos/Subprocesos en el Proceso Batch',
    realizedLossItemId: 'Id. de Elemento de Pérdidas Realizadas',
    queuePollDisabled: 'Encuesta de Cola deshabilitada',
    selfcareUserType: 'Tipo de Usuario de Autoatención',
    provisioningEnabled: 'Aprovisionamiento Habilitado',
    advanceBillingInNoOfDays: 'Facturación Anticipada en Número de Días',
    exchangeThreshold: 'Umbral Cambiario',
    trackSubscriptionCount: 'Seguimiento del Número de Suscripciones',
    fixedTaxRuleForAROps: 'Regla de Impuesto Fijo para Ops de CxC',
    timeAndBillingDeployment: 'Implementación de Tiempo y Facturación',
    userInMultipleGroups: 'Usuario en Varios Grupos',
    scheduleDate: 'Fecha de Programación',
    savedManualBills: 'Facturas Manuales Guardadas',
    searchSavedManualBills: 'Buscar Facturas  Manuales Guardadas',
    errorReason: 'Razón del Error',
    scheduleStartDate: 'Fecha de Inicio de Programación (>=)',
    scheduleEndDate: 'Fecha de Fin de Programación (<)',
    selectOptions: 'Seleccione Opciones',
    sourceAmount: 'Cantidad de Origen',
    savedPendingMergeRequest: 'Solicitud de Fusión Pendiente Guardada',
    savedPendingDisperseRequest: 'Solicitud de Dispersión Pendiente Guardada',
    totalOriginMessage: 'Mensaje de Origen Total',
    totalDestinationMessage: 'Mensaje de Destino Total',
    batchNumber: 'Número de Batch',
    differenceInCalls: 'Diferencia en Llamadas',
    endTime: 'Hora de Finalización',
    aropsSupportTicket: 'Ticket de Soporte de Ops de CxC',
    ticketNumber: 'Numero de Ticket',
    substituteInvoice: 'Factura Sustituta',
    substitutedInvoiceStatus: 'Estado de Factura Sustituida',
    notesType: 'Tipo de Notas',
    notesId: 'Identificación de Notas',
    notesSummary: 'Resumen de Notas',
    supportTicket: 'Ticket de Soporte',
    notesSummaryLines: 'Líneas de Resumen de Notas',
    unAssignedCutOff: 'Corte No Asignado',
    unbilledOnly: 'Solo No Facturado',
    rerateReasonCode: 'Código de motivo de recalificación',
    calendarPeriod: 'Período del Calendario',
    skipStartMonth: 'Omitir Mes de Inicio',
    skipNoOfMonths: 'Saltar Número de Meses',
    groupApproval: 'Aprobación de Grupo',
    mediationHubModules: 'Módulos del Centro de Mediación',
    mediationManagementPermissions: 'Permisos de Gestión de Mediación',
    invoiceCurrency: 'Moneda de la Factura',
    invoiceCurrencyAmount: 'Monto de la Moneda de la Factura',
    exchangeRatedUsed: 'Tarifa Cambiaria Usada',
    usagePlatforms: 'Plataformas de Uso',
    addNewPlatforms: 'Agregar Nuevas Plataformas',
    interfaceType: 'Tipo de Interfaz',
    fileUploadDate: 'Fecha de Carga del Archivo',
    fileUploadErrors: 'Errores de Carga de Archivos',
    errorMessage: 'Mensaje de Error',
    yearMonth: 'Año-Mes (YYYY-MM)',
    countOfFiles: 'Conteo de Archivos',
    startYearMonth: 'Año Mes de Inicio (YYYY-MM)',
    endYearMonth: 'Año Mes de Finalización (YYYY-MM)',
    suspended: 'Suspendido',
    billEndFromDate: 'Fin de Facturación Desde la Fecha',
    billEndToDate: 'Fin de Facturación Hasta la Fecha',
    detailRecords: 'Registros de Detalle',
    cPartyNumber: 'Número C Party',
    recordCount: 'Conteo de Registros',
    moveOrder: 'Orden de Movimiento',
    suspendedFailedBatches: 'Batches Suspendidos/Fallidos',
    CDR: 'CDR',
    fetchFinalizedInvNotePymt: 'Buscar el Pago de la Nota de Factura Finalizada',
    batchidFileName: 'Id Del Lote (Nombre del Archivo)',
    billingFrequencyUnit: 'Unidad de Frecuencia de Facturación',
    chooseServiceName: 'Elija el Nombre del Servicio',
    warning: 'Advertencia',
    newFrequencyEffectiveDate: 'Fecha de Vigencia de la Nueva Frecuencia',
    frequencyChangeAutoApplyDate: 'Fecha de Aplicación Automática del Cambio de Frecuencia',
    invoicingReport: 'Informe de Facturación',
    alignSubscriptionEndDate: 'Alinear la Fecha de Finalización de la Suscripción',
    propagateAddressChangeToBillingAccounts: 'Propagar el Cambio de  Dirección a las  Cuentas de Facturación',
    filterZeroAmount: 'Filtrar Montos en Cero',
    systemParametersPermissions: 'Permisos de Parámetros del Sistema',
    batchFile: 'Archivo Batch',
    notesReference: 'Notas de Referencia',
    displayExchangeRateInHomeCurrency: 'Mostrar Tipo de Cambio en Moneda Local',
    viewAllocation: 'Ver Asignación/Aplicación',
    allocationDate: 'Fecha de Asignación',
    allocatedAmount: 'Importe Sin Asignar',
    setAsideRecords: 'Registros Desestimados',
    failedRecords: 'Registros Fallidos',
    resetJobSchedule: 'Restablecer Programa de Trabajo',
    advancedSearch: 'Búsqueda Avanzada',
    satCode: 'Código del SAT',
    invoiceExhibitsSkipStartMonth: 'Exhibiciones de Factura a Omitir Mes de Inicio',
    numberofMonthstoSkipInvoiceExhibits: 'Número de Meses a Omitir en Exhibiciones de Factura',
    accountNo: 'Número de Cuenta',
    commName: 'Nombre Comercial',
    substitutionSatCode: 'Código SAT de Sustitución',
    cheque: 'Cheque',
    pendingDepositAccount: 'Id de Cuenta de Depósitos Pendientes',
    paymentDetails: 'Detalles del Pago',
    groupCompany: 'Compañía del Grupo',
    paymentSuspenseActivity: 'Actividad del Pago en Suspenso',
    paymentSuspenseReferenceActivity: 'Actividad de Referencia de Pagos en Suspenso',
    paymentSuspenseAmount: 'Importe del Pago en Suspenso',
    paymentSuspenseCurrency: 'Moneda del Pago en Suspenso',
    paymentSuspenseDate: 'Fecha del Pago en Suspenso',
    unallocatedAmount: 'Monto Sin Asignar',
    allocatedAmountInPaymentSuspenseCurrency: 'Monto Aplicado </br> (en Moneda del Pago en Suspenso))',
    invoiceDueInAccountCurrency: 'Adeudo de la Factura </br> (En Moneda de la Cuenta)',
    invoiceDueInPaymentCurrency: 'Adeudo de la Factura </br> (En Moneda del Pago)',
    viewAcitvity: 'Ver Actividad',
    paymentActivity: 'Actividad de Pago',
    paymentReferenceActivity: 'Actividad de Referencia de Pago',
    allocatedAmountInPaymentCurrency: 'Importe Asignado </br> (En Moneda del Pago)',
    percentWithNumberRange: 'Porcentaje (1..100)',
    autoAllocateFullDebtOnly: 'Asignación Automática de Deuda Completa Únicamente',
    allocatedAmountInAccountCurrency: 'Importe Asignado </br> (En Moneda de la Cuenta)',
    rerate: 'Retarificar',
    refresh: 'Refrescar',
    commitmentDollarValue: 'Valor del Compromiso $',
    commitmentTerm: 'Plazo del Compromiso',
    internationalDIDCountry: 'País del DID Internacional',
    network: 'Red',
    salesExecutive: 'Ejecutivo de Ventas',
    collectionAgent: 'Agente de Cobranza',
    bulkOperations: 'Operaciones Masivas',
    outboundSequence: 'Secuencia de Salida',
    inboundSequence: 'Secuencia de Entrada',
    viewFolio: 'Ver Folio',
    never: 'Nunca',
    exchangeRateForReporting: 'Tipo de Cambio para Reportes',
    currencyForReporting: 'Moneda para Reportes',
    invoiceDueMoreThan: 'Vencimiento de la Factura (>=)',
    invoiceDueLessThan: 'Vencimiento de la Factura (<)',
    invoiceTotalLessThan: 'Total de La Factura (<)',
    invoiceTotalMoreThan: 'Total de La Factura (>=)',
    landmarkLabel: 'Etiqueta Punto de Referencia',
    extralineLabel: 'Etiqueta Línea Adicional',
    unlimitedValidityEndDate: 'Fecha de Finalización de Validez Ilimitada',
    clientAccountId: 'Id de Cuenta de Cliente',
    billedAmount: 'Cantidad Facturada',
    billDue: 'Factura Vencida',
    exchangeCurrency: 'Cambio de Divisas',
    dueDateMoreThan: 'Fecha de Vencimiento (>=)',
    dueDateLessThan: 'Fecha de Vencimiento (<)',
    billEndMoreThan: 'Fin de la Factura (>=)',
    billEndLessThan: 'Fin de la Factura (<)',
    creditNoteId: 'Id de la Nota de Crédito',
    creditNoteStatus: 'Estado de la Nota de Crédito',
    paymentXchangeThreshold: 'Umbral de Tipo de Cambio para Pagos',
    tooltipLabelPaymentThreshold:
      'Configuración a nivel de sistema - Si el pago es un pago insuficiente dentro de este valor, la factura se pagará completamente y el déficit se contabilizará contra la cuenta de Pérdida Realizada.',
    tooltipLabelPaymentXchangeThreshold:
      'Configuración a nivel de sistema - Si hay una conversión de moneda involucrada con el pago y después de la conversión el pago es insuficiente para una factura pero dentro de este valor, la factura se pagará completamente y el déficit se contabilizará contra la cuenta de Pérdida Realizada. Si no es específico, se usará el umbral de pago.',
    payemntThresholdMoreThan: 'Umbral de Pago (>=)',
    numberOfDaysSinceDueDate: 'Número de días desde la Fecha de Vencimiento',
    salesFunction: 'Función de Ventas',
    paymentFolio: 'Folio de Pago',
    paymentFilesConfiguration: 'Configuración de Archivos de Pago',
    paymentFileDate: 'Fecha del Archivo de Pago',
    npaCountry: 'País NPA',
    totalFunctionalCurrency: 'Total </br> (Moneda Funcional)',
    functionalCurrency: 'Moneda Funcional',
    invoiceSummary: 'Resumen de Factura',
    ieps: 'IEPS',
    iva: 'IVA',
    billableEmployee: 'Empleado Facturable',
    remainingAmountThresholdMoreThanZero: 'Umbral para Monto Restante (>0)',
    totalFunctionalCurrencyExport: 'País NPA',
    provisioningOnlyFlag: 'Bandera para Indicar Solo Aprovisionamiento',
    viewInvoices: 'Ver Facturas',
    viewActions: 'Ver Acciones',
    billStart: 'Inicio de Factura',
    billEnd: 'Fin de la Factura',
    billingPeriodStart: 'Inicio del Período de Facturación',
    billingPeriodEnd: 'Fin del Período de Facturación',
    numberOfDaysSinceDueDateMoreThan: 'Número de días desde la Fecha de Vencimiento (>=)',
    numberOfDaysSinceDueDateLessThan: 'Número de días desde la Fecha de Vencimiento (<)',
    collectionEntryDateMoreThan: 'Fecha de Entrada de la Cobranza (>=)',
    collectionEntryDateLessThan: 'Fecha de Entrada de la Cobranza (<)',
    accountCategory: 'Categoría de Cuenta',
    collectionEntryDate: 'Fecha de Entrada de la Cobranza',
    folioStatus: 'Estado del Folio',
    roundingPrecisionForExchangeRate: 'Precisión de Redondeo para el Tipo de Cambio',
    folioUUID: 'Folio UUID',
    commitmentStart: 'Inicio del Compromiso',
    commitmentEnd: 'Fin del Compromiso',
    commitmentAmount: 'Importe del Compromiso',
    commitmentTrackerId: 'ID del Rastreador de Compromisos',
    commitmentTrackerBalance: 'Saldo del Rastreador de Compromisos',
    currentExchangeRate: 'Tipo de Cambio Actual',
    realizedLossGain: 'Pérdida/Ganancia Realizada',
    paymentDateExchangeRate: 'Tipo de Cambio de la Fecha de Pago ',
    unitPriceRoundingPrecision: 'Precisión de Redondeo de Precio Unitario',
    unrealizedLossGain: 'Pérdida/Ganancia No Realizada',
    unrealizedLossGainInSystemCurrency: 'Pérdida/Ganancia No Realizada </br> (en Moneda del Sistema)',
    unrealizedLossGainInSystemCurrencyExport: 'Pérdida/Ganancia No Realizada (en Moneda del Sistema)',
    appliedExchangeRate: 'Tipo de Cambio Aplicado',
    useExchangeRate: 'Usar Tipo de Cambio',
    numberOfDaysSincePaymentDate: 'Número de días desde la Fecha de Pago',
    numberOfDaysSincePaymentDateMoreThan: 'Número de días desde la Fecha de Pago (>=)',
    numberOfDaysSincePaymentDateLessThan: 'Número de días desde la Fecha de Pago (<)',
    payemntThresholdLessThan: 'Umbral de Pago (<)',
    remainingAmountThresholdMoreThan: 'Umbral para Monto Restante (>=)',
    remainingAmountThresholdLessThan: 'Umbral para Monto Restante (<)',
    unallocatedAmountThresholdMoreThan: 'Umbral de Monto no Aplicado  (>=)',
    unallocatedAmountThresholdLessThan: 'Umbral de Monto no Aplicado  (<)',
    paymentStatus: 'Estado del Pago',
    viewProvisioningAttributes: 'Ver Atributos de Aprovisionamiento',
    provisioningAttributes: 'Atributos de Aprovisionamiento',
    fileNameBatchId: 'Nombre de Archivo (Id Batch)',
    sourcePlatform: 'Plataforma de Origen',
    processedCount: 'Conteo Procesados',
    suspendedCount: 'Conteo Suspendidos',
    ignoredCount: 'Conteo Ignorados',
    stage: 'Etapa',
    allocatedAmountInAccountCurrencyExport: 'Monto Asignado (en la Moneda de la Cuenta)',
    allocatedAmountInPaymentSuspenseCurrencyExport: 'Monto Aplicado (en Moneda del Pago en Suspenso)',
    prorateBilledOnSuspend: 'Prorrateado Facturado en Suspenso',
    cancelTypeId: 'Id de Tipo de Cancelación',
    cancelType: 'Tipo de Cancelación',
    personalEV: 'Personal EV',
    salesAgentName: 'Nombre del Agente de Ventas',
    cancelDate: 'Fecha de Cancelación',
    serviceInstanceId: 'Id de Instancia de Servicio',
    cancelReasonId: 'Id Razón de Cancelación',
    cancelReason: 'Razón de Cancelación',
    proratedAmount: 'Monto Prorrateado',
    cancelDateMoreThan: 'Fecha de Cancelación (>=)',
    cancelDateLessThan: 'Fecha de Cancelación (<)',
    selectCloseSuspenseReason: 'Elegir el Motivo de Cierre de Suspenso',
    modifySuspense: 'Modificar Suspenso',
    closeSuspenseReason: 'Razón de Cirerre de Suspenso',
    careClassification: 'Clasificación de Atención',
    giro: 'Giro',
    salesAgent: 'Agente de Ventas',
    tipoMercado: 'Tipo Mercado',
    serviceInstance: 'Instancia de Servicio',
    invoiceTotalInUSD: 'Total de la Factura en USD',
    companyName: 'Nombre de la Compañía',
    segmentId: 'Id del Segmento',
    functionEV: 'Función EV',
    familyName: 'Nombre de la Familia',
    familyId: 'Id de la Familia',
    subFamily1Name: 'Nombre de la Sub-Familia 1',
    subFamily1Id: 'Id de la Sub-Familia 1',
    subFamily2Name: 'Nombre de la Sub-Familia 2',
    subFamily2Id: 'Id de la Sub-Familia 2',
    planStatus: 'Estatus del Plan',
    folioDateMoreThan: 'Fecha del Folio (>=)',
    exportConfig: 'Exportar Configuración',
    sourceFields: 'Campos de Origen',
    targetFields: 'Campos de Destino',
    crmClientId: 'Id del Cliente en el CRM',
    salesConsultant: 'Consultor de Ventas',
    paymentReceiptsAccount: 'Cuenta de Recibos de Pago',
    revenueApportionPaymentToTax: 'Pago de Prorrateo de Ingresos a Impuestos',
    referenceBatchId: 'Id de Batch de Referencia',
    rfc: 'RFC',
    customAccountType: 'Tipo de Cuenta Personalizada',
    outputConfig: 'Configuración de Salida',
    orderSource: 'Fuente de la Orden',
    originalInvoiceRef: 'Ref de la Factura Original',
    remainingCommitment: 'Compromiso Restante',
    creditNotesId: 'Id Notas de Crédito',
    startMonth: 'Mes de Inicio',
    monthOne: 'Mes Uno',
    monthTwo: 'Mes Dos',
    monthThree: 'Mes Tres',
    ratingProvisioningId: 'Tarificación de Id de Aprovisionamiento',
    sServiceProvider: 'S_SERVICEPROVIDER',
    sUserNumber: 'S_USERNUMBER',
    sGroupNumber: 'S_GROUPNUMBER',
    sDirection: 'S_DIRECTION',
    sCallingNumber: 'S_CALLINGNUMBER',
    sCalledNumber: 'S_CALLEDNUMBER',
    sStartTime: 'S_STARTTIME',
    sAnswerIndicator: 'S_ANSWERINDICATOR',
    sAnswerTime: 'S_ANSWERTIME',
    sReleaseTime: 'S_RELEASETIME',
    sTerminationCause: 'S_TERMINATIONCAUSE',
    sDailedDigits: 'S_DIALEDDIGITS',
    sCallCategory: 'S_CALLCATEGORY',
    sNetworkCallType: 'S_NETWORKCALLTYPE',
    sNetworkTranslatedNumber: 'S_NETWORKTRANSLATEDNUMBER',
    sReleasingParty: 'S_RELEASINGPARTY',
    sRoute: 'S_ROUTE',
    sNetworkCallId: 'S_NETWORKCALLID',
    sCodec: 'S_CODEC',
    sAccessDeviceAddress: 'S_ACCESSDEVICEADDRESS',
    sGroup: 'S_GROUP',
    sDepartment: 'S_DEPARTMENT',
    sAuthorizationCode: 'S_AUTHORIZATIONCODE',
    sOriginalCalledNumber: 'S_ORIGINALCALLEDNUMBER',
    sOriginalCalledPresentationIndicator: 'S_ORIGINALCALLEDPRESENT_INDIC',
    sOriginalCalledReason: 'S_ORIGINALCALLEDREASON',
    sRedirectingNumber: 'S_REDIRECTINGNUMBER',
    sRedirectingPresentationIndicator: 'S_REDIRECTINGPRESENT_INDIC',
    sRedirectingReason: 'S_REDIRECTINGREASON',
    sChargeIndicator: 'S_CHARGEINDICATOR',
    sVoicePortalInvocationTime: 'S_VOICEPORTALCALLING_INVOCTIME',
    dExtAnswerDateTime: 'D_EXT_ANSWER_DATE_TIME',
    nExtDurationMinutes: 'N_EXT_DURATION_MINUTES',
    nExtDurationSeconds: 'N_EXT_DURATION_SECONDS',
    sReserved: 'S_EXT_RESERVED',
    sUserid: 'S_USERID',
    sOtherPartyName: 'S_OTHERPARTYNAME',
    partySipCallIdZero: 'Party Sip Call Id Zero',
    partySipCallIdOne: 'Party Sip Call Id One',
    serviceStatus: 'Esatus del Servicio',
    nextCutoffDate: 'Siguiente Fecha de Corte',
    omsInternalId: 'OMS Id Interno',
    completedDate: 'Fecha Completada',
    incrementedAmount: 'Monto Incrementado',
    finalAmount: 'Monto Final',
    oldAmount: 'Monto Anterior',
    completedStartDate: 'Fecha de Inicio Completada',
    completedEndDate: 'Fecha de Finalización Completada',
    C601: 'C601',
    C602: 'C602',
    C003: 'C003',
    C006: 'C006',
    C101: 'C101',
    C102: 'C102',
    C103: 'C103',
    R0010501: 'R001-0501',
    R0010601: 'R001-0601',
    R0010602: 'R001-0602',
    R0010603: 'R001-0603',
    R0010604: 'R001-0604',
    R0010605: 'R001-0605',
    R0010606: 'R001-0606',
    R0010607: 'R001-0607',
    R0010608: 'R001-0608',
    R0010609: 'R001-0609',
    R0010610: 'R001-0610',
    R0010611: 'R001-0611',
    R0010612: 'R001-0612',
    R0010613: 'R001-0613',
    R0050101: 'R005-0101',
    C002: 'C002',
    C205: 'C205',
    C309: 'C309',
    R0050201: 'R005-0201',
    R0050301: 'R005-0301',
    R0050302: 'R005-0302',
    R0050303: 'R005-0303',
    R0050304: 'R005-0304',
    R0050401: 'R005-0401',
    R0050501: 'R005-0501',
    R0050502: 'R005-0502',
    R0050503: 'R005-0503',
    R0040101: 'R004-0101',
    R0040501: 'R004-0501',
    R0040502: 'R004-0502',
    R0040503: 'R004-0503',
    R0050601: 'R005-0601',
    R0050602: 'R005-0602',
    C104: 'C104',
    reserved: 'Reservado',
    text: 'Texto',
    countLessThan: 'Conteo <',
    totalTime: 'Tiempo Total',
    historicalFolioLink: 'Link a Folios Históricos',
    push: 'Enviar',
    defaulTrunkAccount: 'Cuenta Troncal Predeterminada',
    creditNoteReadyNotification: 'Notificación de Nota de Crédito Lista',
    addConfig: 'Agregar Configuración',
    addAttributes: 'Agregar Atributos',
    apiType: 'Tipo de API',
    provisioningName: 'Nombre de Aprovisionamiento',
    chooseProvisioningName: 'Elija el Nombre de Aprovisionamiento',
    attributes: 'Atributos',
    alternateName: 'Nombre Alternativo',
    patternName: 'Nombre del Patrón',
    patternType: 'Tipo de Patrón',
    scheduleStart: 'Inicio del Programa',
    scheduleEnd: 'Fin del Programa',
    everyNDay: 'Cada "n" Día(s)',
    everyWeekDay: 'Cada Día de la Semana',
    everyWeekEnd: 'Cada Fin de Semana',
    scheduleDaily: 'Horario Diario',
    scheduleWeekly: 'Programar Semanalmente',
    scheduleMonthly: 'Programar Mensualmente',
    scheduleYearly: 'Programar Anualmente',
    everyMonday: 'Cada Lunes',
    everyTuesday: 'Cada Martes',
    everyWednesday: 'Cada Miércoles',
    everyThursday: 'Cada Jueves',
    everyFriday: 'Cada Viernes',
    everySaturday: 'Cada Sábado',
    everySunday: 'Cada Domingo',
    everyNWeek: 'Cada "n" Semana(s)',
    dayOfMonth: 'Dia del Mes',
    everyNMonth: 'Cada "n" Mes(es)',
    monthOfYear: 'Mes del Año',
    everyNYear: 'Cada "n" Año(s)',
    dayS: 'Día(s)',
    every: 'Cada',
    weekS: 'Semana(s)',
    day: 'Día',
    ofEvery: 'De Cada',
    monthS: 'Mes(s)',
    yearS: 'Año(s)',
    profilePicture: 'Foto de Perfil',
    isProvisionable: 'Es Provisionable',
    statusChangeEffectiveDate: 'Fecha de Vigencia del Cambio de Estado',
    changeTheDateForBackdatedOrFutureDatedStatusChange:
      'Cambiar la Fecha para Cambio de Estado Retroactivo o con Fecha Futura',
    tieredDiscountingModel: 'Modelo de Descuento por Niveles',
    provisioning: 'Aprovisionamiento',
    separateStampingAPI: 'API de Estampado Independiente',
    connectionName: 'Nombre de la Conexión',
    flexattr1: 'Flexattr 1',
    flexattr2: 'Flexattr 2',
    flexattr3: 'Flexattr 3',
    flexattr4: 'Flexattr 4',
    flexattr5: 'Flexattr 5',
    flexattr6: 'Flexattr 6',
    flexattr7: 'Flexattr 7',
    flexattr8: 'Flexattr 8',
    flexattr9: 'Flexattr 9',
    flexattr10: 'Flexattr 10',
    testUsageTool: 'Herramienta de Prueba de Uso',
    testUsage: 'Prueba de Uso',
    result: 'Resultado',
    folioType: 'Tipo de Folio',
    embrixTargetRecord: 'Registro de Objetivos de Embrix',
    provisioningNames: 'Nombres de Aprovisionamiento',
    addProvisioningName: 'Agregar Nombre de Aprovisionamiento',
    priceDiscountOverride: 'Anulación de Precio/Descuento',
    provisioningOrderId: 'ID de Orden de Aprovisionamiento',
    provisioningData: 'Datos de Aprovisionamiento',
    setOrderProvisioningData: 'Establecer Datos de Orden/Aprovisionamiento',
    quote: 'Cotización',
    submittedStartDate: 'Fecha de Inicio Enviada (>=)',
    submittedEndDate: 'Fecha de Finalización Enviada (<)',
    quoteDetails: 'Detalles de Cotización',
    priceOffer: 'Oferta de Precio',
    bundle: 'Bundle',
    package: 'Paquete',
    transaction: 'Transacción',
    discountOffer: 'Oferta de Descuento',
    serviceSummary: 'Resumen del Servicio',
    lineSummary: 'Resumen de Línea',
    currentCharges: 'Cargos Actuales',
    newQuote: 'Nueva Cotización',
    createNewQuote: 'Crear Nueva Cotización',
    setQuoteProvisioningData: 'Establecer Datos de Cotización/Aprovisionamiento',
    manageCreateQuote: 'Administrar y Crear Cotización',
    flatRate: 'Tarifa Plana',
    accumulatorAmount: 'Monto del Acumulador',
    evaluationComponent: 'Componente de Evaluación',
    comparisonOperator: 'Comparación de Operador',
    createNewDiscountTrigger: 'Crear Nuevo Activador de Descuento',
    editDiscountTrigger: 'Editar Activador de Descuento',
    usageField: 'Campo de Uso',
    discountTriggerName: 'Nombre del Activador de Descuento',
    showTasks: 'Mostrar Tareas',
    orderTask: 'Tareas de Orden',
    omsTasks: 'Tareas de OMS',
    provisioningTasks: 'Tareas de Aprovisionamiento',
    outbound: 'Saliente',
    inbound: 'Entrante',
    taskName: 'Nombre de la Tarea',
    quoteManagementPermissions: 'Permisos de Gestión de Cotizaciones',
    getQuote: 'Obtener Cotización',
    choosePriceOffers: 'Elegir Ofertas de Precio',
    convertToOrder: 'Convertir a Orden',
    chooseExistingAccount: 'Elija una Cuenta Existente',
    createNewAccount: 'Crear una Nueva Cuenta',
    convert: 'Convertir',
    unitPriceOrDiscount: 'Precio Unitario/Descuento',
    parentTaskId: 'ID de Tarea Principal',
    includeChildTask: 'Incluir Tarea Secundaria',
    idHistory: 'Historial de Id',
    ticketId: 'Id de Ticket',
    userEmail: 'Correo Electrónico del Usuario',
    userFirstname: 'Nombre del Usuario',
    userLastName: 'Apellido del Usuario',
    createTask: 'Crear Tarea',
    createNewTask: 'Crear Nueva Tarea',
    createChildTask: 'Crear Tarea Secundaria',
    viewChildTasks: 'Ver Tareas Secundarias',
    taskDetails: 'Detalles de la Tarea',
    childTasks: 'Tareas Secundarias',
    childTaskDetails: 'Detalles de la Tarea Secundaria',
    taskHistory: 'Historial de Tareas',
    parent: 'Principal/Padre',
    createNewChildTask: 'Crear Nueva Tarea Secundaria',
    createNonPaying: 'Crear No-Pago',
    auditHistoryDetails: 'Detalles del Histórico de Auditorías',
    auditHistory: 'Historial de Auditoría',
    multiSubscriptionEnabled: 'Suscripción Múltiple Habilitada',
    subscriptionTopUpOfferId: 'ID de Oferta de Suscripción por Recargas',
    sysAdminAccount: 'Cuenta de Administrador del Sistema',
    autoTaskSync: 'Sincronización Automática de Tareas a Externo',
    quotePricingLevel: 'Nivel de Precios en la Cotización ',
    exchangeRateForProvisioning: 'Tipo de Cambio para Aprovisionamiento',
    addSubscription: 'Agregar Suscripción',
    subscriptionName: 'Nombre de Suscripción',
    selectASubscriptionToProceed: 'Seleccione una Suscripción para Continuar',
    singleSubscription: 'Suscripción Única',
    subscriptionTerms: 'Términos de Suscripción',
    overrideOptions: 'Opciones de Anulación',
    purchaseOptions: 'Opciones de Compra',
    addDiscounts: 'Agregar Descuentos',
    topupDetails: 'Detalles de Recarga',
    validityTerm: 'Plazo de Validez',
    termX: 'Término (X)',
    subscriptionOrAddonPurchase: 'Suscripción o Compra de Complementos',
    createCancel: 'Crear/Cancelar',
    provisionDataSetAttributes: 'Atributos del Conjunto de Datos de Aprovisionamiento',
    addDataSet: 'Agregar Conjunto de Datos',
    addStep: 'Agregar Paso',
    addAttribute: 'Agregar Atributo',
    dataSetType: 'Tipo de Conjunto de Datos',
    poAmount: 'Importe de la Orden de Compra',
    emailId: 'Id del Correo',
    billing: 'Facturación',
    invoicing: 'Facturación',
    lastAccountingDate: 'Última Fecha Contable',
    nextAccountingDate: 'Próxima Fecha Contable',
    futureAccountingDate: 'Fecha de Contabilidad Futura',
    lastBillDate: 'Fecha de la Última Factura',
    lastBillId: 'Último Id de Factura',
    nextBillDate: 'Próxima Fecha de Factura',
    nextBillId: 'Próximo Id de Factura',
    futureCycleOpen: 'Ciclo Futuro Abierto',
    futureBillDate: 'Fecha de Facturación Futura',
    futureBillId: 'ID de Factura Futura',
    otherData: 'Otros Datos',
    manageAndOverride: 'Administrar y Anular',
    provisioningDataAndCreateOrder: 'Aprovisionamiento de Datos y Creación de Órdenes',
    moveOutOfHierarchy: 'Salir de la Jerarquía',
    limitBreachActions: 'Limitar acciones de incumplimiento',
    addAction: 'Agregar Acción',
    uploadBulkAdjustmentFile: 'Cargar Archivo de Ajustes Masivos',
    trialToPaidNow: 'Prueba para Pagar Ahora',
    modifyTopupOfferConfig: 'Modificar la Configuración de la Oferta de Recarga',
    newTopupOfferConfig: 'Nueva Configuración de Oferta de Recarga',
    topupOfferId: 'ID de la Oferta de Recarga',
    topupOfferName: 'Nombre de la Oferta de Recarga',
    paymentOfferId: 'ID de la Oferta de Pago',
    paymentOfferName: 'Nombre de la Oferta de Pago',
    burnRateOfferId: 'ID de la Oferta de Tasa de Consumo',
    burnRateOfferName: 'Nombre de la Oferta de Tasa de Consumo',
    CHANGE: 'CAMBIAR',
    CANCEL: 'CANCELAR',
    NEW: 'NUEVO',
    addNew: 'Agregar Nuevo',
    suspendResume: 'Suspender/Reanudar',
    uploadStartDate: 'Cargar Fecha de Inicio (>=)',
    uploadEndDate: 'Cargar Fecha de Finalización (<)',
    uploadDate: 'Fecha de Carga',
    downloadTemplate: 'Descargar Plantilla',
    undo: 'Deshacer',
    orderTasks: 'Ordenar Tareas',
    discountAmountOverride: 'Anulación del Importe del Descuento',
    discountPercentOverride: 'Anulación del Porcentaje de Descuento',
    streamMapping: 'Mapeo del Flujo',
    phones: 'Teléfonos',
    quickCreateAccount: 'Creación Rápida de Cuenta ',
    quickCreate: 'Creación Rápida',
    taxCodeTaxes: 'Códigos de Impuestos',
    addTaxes: 'Agregar Impuestos',
    taxBase: 'Base Impositiva',
    toAddCurrency: 'Para agregar recursos monetarios, haga clic',
    here: 'aquí',
    messageQuickTaxes:
      'La secuencia de las filas es importante. Si cambia la secuencia de cómo se deben aplicar los impuestos, asegúrese entonces de que la expresión no tenga el nombre de una fila que será aplicada más adelante en la secuencia.',
    apiMapping: 'Mapeo de API',
    addMerchants: 'Agregar Comerciantes',
    sendNotificationOnCollectionEntry: 'Enviar Notificación al Ingresar a Cobranza',
    webApiType: 'Tipo de API Web',
    provisionGatewayURLMapping: 'Aprovisionar mapeo de la URL del Gateway',
    zoneUnitEdit: 'Editar Unidad de Zona',
    zoneUnitAdd: 'Agregar Unidad de Zona',
    zoneType: 'Tipo de Zona',
    originField: 'Campo de Origen',
    originValue: 'Valor de Origen',
    distanceValue: 'Valor de Distancia',
    tenantMasterData: 'Datos Maestros de la Instancia',
    tenantProfile: 'Perfil de Instancia',
    tenantSupport: 'Soporte a Instancia',
    addNewZone: 'Agregar Nueva Zona',
    destinationField: 'Campo de Destino',
    destinationValue: 'Valor de Destino',
    pendingBillLines: 'Líneas de Factura Pendientes',
    lineAmount: 'Monto de la Línea',
    orderProvisioningIds: 'IDs de Aprovisionamiento de Órden',
    serviceIdentifiers: 'Identificadores de Servicio',
    orderAction: 'Acción de la Órden',
    taxType: 'Tipo de Impuesto',
    receiver: 'Receptor',
    templateType: 'Tipo de Plantilla',
    addAlaCarte: 'Añadir a la Carta',
    alaCarte: 'A la Carta',
    discountOffers: 'Ofertas de Descuento',
    priceOffers: 'Ofertas de Precios',
    collectionProfile: 'Perfil de Cobranza ',
    chooseCollectionProfile: 'Elija el Perfil de Cobranza',
    mandateSatCodeForAdjustments: 'Código SAT Mandatorio para Ajuste',
    addNewSchedule: 'Agregar nuevo Cronograma',
    provisioningSequence: 'Secuencia de Aprovisionamiento',
    prerequisiteInboundSequence: 'Prerequisito Secuencia Entrante',
    newPrerequisiteInboundSequence: 'Nueva Secuencia de Entrada de Prerrequisito',
    synchronousResponse: 'Respuesta Sincrónica',
    addNewSequence: 'Agregar Nueva Secuencia',
    servicesTypeRemove: 'Eliminar Tipo de Servicios',
    totalInvoice: 'Total de la Factura',
    totalAmount: 'Cantidad Total',
    totalPaid: 'Total Pagado',
    adjustmentRequestDetails: 'Detalles de la Solicitud de Ajuste',
    productCode: 'Código de Producto',
    installmentDetails: 'Detalles de la Parcialidad',
    invoicesInInstallment: 'Facturas a Plazos',
    installmentNo: 'No. Cuota',
    amountPaid: 'Cantidad Pagado',
    noOfInstallments: 'No de Parcialidades',
    noOfInstallmentsPaid: 'No de Parcialidades Pagadas',
    addMultipleInvoicePaymentInstallment: 'Agregar Múltiples Facturas a Pago en Parcialidades',
    numberOfInstallments: 'Número de Cuotas',
    monthsSOffset: 'Meses de Compensación',
    noOfInvoices: 'No de Facturas',
    installmentPlan: 'PLAN DE PARCIALIDADES ',
    customerName: 'Nombre del Cliente',
    installmentId: 'ID de Pago a Plazos',
    signature: 'Firma',
    addInstallmentData: 'Agregar Datos de Parcialidades',
    requestId: 'ID de Solicitud',
    lineReference: 'Referencia de Línea',
    lowerLimit: 'Límite Inferior',
    upperLimit: 'Limite Superior',
    productCodes: 'Códigos de Producto',
    uploadCertificate: 'Cargar Certificado',
    sharingType: 'Tipo de Intercambio',
    shareImpactOffer: 'Compartir Impacto de Oferta',
    createNewGroup: 'Crear Nuevo Grupo',
    groupDetails: 'Detalles del Grupo',
    ownerOffer: 'Oferta del Propietario',
    addSharedDiscounts: 'Agregar Descuentos Compartidos',
    disputesRequestDetails: 'Detalles de la Solicitud de Disputas',
    writeOffRequestDetails: 'Detalles de la Solicitud de Cancelación',
    splitTaxCalculationByServiceType: 'Cálculo de la División de Impuestos por Tipo de Servicio',
    actions: 'Acciones',
    agents: 'Agentes',
    firstInvoicePaymentActivation: 'Activación del Pago de la Primera Factura',
    writeoffId: 'ID de Cancelación',
    arActivityDetails: 'Detalles de la Actividad de CxC',
    arActivityRequestDetails: 'Detalles de la Solicitud de Actividad de CxC',
    apiProtocol: 'Protocolo API',
    newRegulatoryProductCodeConfig: 'Nueva Configuracion de Codigos de Producto',
    modifyRegulatoryProductCodeConfig: 'Modificacion de Configuracion de Codigos de Producto',
    payloadTemplate: 'Formato de Archivo de Carga(PAYLOAD)',
    embrixSmsGateway: 'Embrix Sms Gateway',
    sendProvisioningData: 'Enviar Datos de Aprovisionamiento',
    enterpriseProvisioningQueue: 'Cola de Aprovisionamiento Empresarial',
    oldProvisioningSystemQueue: 'Cola del Sistema de Aprovisionamiento Antiguo',
    payloadMapOnly: 'Solo Mapa de Carga',
    revenueRecognitionInSystemCurrency: 'Reconocimiento de Ingresos en Moneda del Sistema',
    itemizeARRevenueExtract: 'Itemización de Extracto de Ingresos de AR',
    staticValue: 'Valor IP Estática',
    tableMode: 'Modo Tabla',
    gridMode: 'Modo Cuadrícula',
    closed: 'Cerrado',
    billingOnly: 'Solo Facturación',
    provisioningOnly: 'Solo Aprovisionamiento',
    billOrderCharges: 'Cobrar Cargos del Pedido',
    allowPartialProvisioning: 'Permitir Aprovisionamiento Parcial',
    billForOrder: 'Facturar Por Pedido',
    executeOnError: 'Ejecutar En Caso de Error',
    errorApiName: 'Nombre de API de Error',
    customerId: 'ID de Cliente',
    assigned: 'Asignado',
    arReasonCodeConfig: 'Configuración de Código de Motivo de AR',
    partyId: 'Party Id',
    nextActionDate: 'Fecha de la Proxima Accion',
    nextAction: 'Proxima Accion',
    templates: 'Plantillas',
    agreement: 'Acuerdo',
    additionalAddressInformation: 'Información Adicional',
    municipality: 'Municipio',
    locality: 'Localidad',
    neighbourhood: 'Barrio',
    address1: 'Direcciones1',
    address2: 'Direcciones2',
    address3: 'Direcciones3',
    district: 'Distrito',
    paymentAllocationRules: 'Reglas de Asignación de Pago',
    displayActiveOnly: 'Mostrar Solo Activos',
    invoiceSummaryType: 'Tipo de Resumen de Factura',
    paymentAllocationRulesDetails: 'Detalles de Reglas de Asignación de Pago',
    summaryType: 'Tipo de Resumen',
    addSupplementaryFile: 'Agregar Archivo Suplementario',
    supplementaryFiles: 'Archivos Suplementarios',
    addResources: 'Agregar Recursos',
    addActions: 'Agregar Acciones',
    smsTemplate: 'Plantilla de SMS',
    uploadEmailTemplate: 'Subir Plantilla de Correo Electrónico',
    myActivity: 'Mi Actividad',
    isBundle: 'Es Bundle',
    isPackage: 'Es Paquete',
    currencyCode: 'Codigo de Moneda',
    PRICE_OFFER: 'Oferta de Precio',
    DISCOUNT_OFFER: 'Oferta de Descuento',
    INACTIVE: 'Inactivo',
    CLOSEDAndCANCELLED: 'Cerrado/Cancelado',
    subscriptionView: 'Vista de Suscripcion',
    treeView: 'Vista de Árbol',
    priceUnit: 'Unidad de Precio',
    discountUnit: 'Unidad de Descuento',
    'ala-Carte': 'A la Carta',
    currentAccount: 'Cuenta Actual',
    unallocatedCredits: 'Créditos No Asignados',
    identity: 'Identidad',
    identityDocument: 'Documento de Identificacion',
    taxByLineItem: 'Impuesto Por Artículo',
    defaultTaxRuleForAROps: 'Regla de Impuesto Predeterminada Para Operaciones de AR',
    lastArAction: 'Última Acción AR',
    taskSequence: 'Secuencia de Tarea',
    serviceProvisioningSequence: 'Secuencia de Aprovisionamiento de Servicio',
    listStatusActiveProvisioningTasks: 'COMPLETADO/OMITIDO/NO_REQUERIDO',
    PENDING: 'PENDIENTE',
    FAILED: 'FALLIDO',
    'PENDING/PARTIAL': 'PENDIENTE/PARCIAL',
    'FAILED/CANCELLED': 'FALLIDO/CANCELADO',
    arRequestLog: 'Registro de Solicitudes de AR',
    arOpsUnits: 'Unidades de Operaciones AR',
    accountingCode: 'Centro de Costo',
    itemAsTransactionDescription: 'Artículo Como Descripción de Transacción',
    emailSubject: 'Asunto de Correo Electrónico',
    batchFinancialExtract: 'Extracto Financiero en Lote',
    taxExemptionOnTaxableAmount: 'Exención de Impuestos Sobre Impuesto de Monto',
    glOffsetAccount: 'Cuenta Contrapartida',
    offsetAccountingCode: 'Centro de Costo Contrapartida',
    searchFilters: 'Filtros de Busqueda',
    finalizedInvoice: 'Factura Finalizada',
    invoicesWithInstallment: 'Facturas Con Cuotas',
    openInvoices: 'Facturas Abiertas',
    fileUploadStats: 'Estadísticas de Subida de Archivo',
    invoiceDateWithRank: 'Fecha de Factura (>=)',
    useGLCombination: 'Usar Combinación de GL',
    paymentSourceId: 'ID de Fuente de Pago',
    certificateId: 'Id de Certificado',
    erpExtractGracePeriod: 'Período de Gracia de Extracto ERP',
    invoiceEnd: 'Fin de la Factura',
    invoiceStart: 'Inicio a Facturar',
    invoiceIssued: 'Meses Facturados',
    invoicePending: 'Periodo Contrato (vida Util)',
    item: 'Artículo',
    offerStart: 'Inicio de la Oferta',
    offerEnd: 'Fin de la Oferta',
    serviceEffectiveDate: 'Fecha de Servicio Efectiva',
    invoicePaid: 'Pagó Factura',
    isIPTVService: 'Tiene IPTV',
    priceUnitStatus: 'Estatus de Precio de Unidad',
    contractApprovedDate: 'Fecha de Aprobación del Contrato',
    contractDate: 'Fecha del Contrato',
    offerId: 'ID de Oferta',
    provisioningApprovedDate: 'Fecha de Aprobación de Aprovisionamiento',
    provisioningStartDate: 'Fecha de Inicio de Aprovisionamiento',
    provisioningConfirmationDate: 'Fecha de Confirmación de Aprovisionamiento',
    installmentPayments: 'Pagos a Plazos',
    payments: 'Pagos',
    dataProcessDate: 'Fecha de Procesamiento de Datos',
    sendInvoicePdfAndXml: 'Enviar Factura en PDF y XML',
    searchPackageBundleToIncludeTax: 'Buscar Paquete/Conjunto Para Incluir Impuestos',
    activity: 'Actividad',
    ERPExtractActivity: 'Actividad de Extracto ERP',
    groupByPaymentSource: 'Agrupar Por Fuente de Pago',
    selectGLAccount: 'Seleccionar Cuenta GL',
    matchBillingEntries: 'Coincidir Entradas de Facturación',
    TEExpenseType: 'T&E: Tipo de Gasto',
    fixedExpenseTypes: 'Tipos de Gastos Fijos',
    variableExpenseTypes: 'Tipos de Gastos Variables',
    expenseData: 'Datos de Gastos',
    expensePeriodUnit: 'Unidad de Período de Gasto',
    TEData: 'Datos T&E',
    addTEExpenseData: 'Agregar Datos T&E',
    PLReport: 'Informe de Pérdidas y Ganancias',
    PLYearlyReport: 'Informe Anual de Pérdidas y Ganancias',
    createCustomerAttributes: 'Crear atributos del cliente',
    referenceOrder: 'Orden de Referencia',
    selectOrder: 'Seleccionar Orden',
    orderProvisioningData: 'Datos de Aprovisionamiento de Orden',
    createOrder: 'Crear Orden',
    accountOrderWrapperAPI: 'API de Envoltura de Orden de Cuenta',
    options: 'Opciones',
    or: 'O',
    actionsList: 'Lista de Acciones',
    alignInstallmentWithBdom: 'Alinear Cuota Con BDOM',
    subject: 'Asunto',
    emailContent: 'Contenido de Correo Electrónico',
    filterInstallmentInvoicesForAR: 'Filtrar Facturas de Cuotas Para AR',
    notesOnDispute: 'Notas Sobre Disputa',
    isGrant: 'Es Subsidio',
    revenueData: 'Datos de Ingresos',
    installmentInvoice: 'Factura de Cuotas',
    invoiceNotes: 'Notas de Factura',
    revenueStartDate: 'Fecha de Inicio de Ingresos',
    revenueEndDate: 'Fecha de Fin de Ingresos',
    collectionUnitId: 'Id de Unidad de Colección',
    bonusPeriodUnit: 'Unidad del Periodo del Bono',
    addEmployeeBonus: 'Agregar Bono a Empleado',
    moveProvisioningOrder: 'Reubicación',
    PLQuaterlyReport: 'Reporte Trimestral de G&P',
    expectedBilledRevenue: 'Ingresos Facturados Esperados',
    revenueReceived: 'Ingresos Recibidos',
    totalPayroll: 'Total de planilla',
    payrollTaxes: 'Impuestos de Planilla',
    legalGrossYearEndBonus: 'Bono Legal de Final de Año',
    actualCost: 'Costo Real',
    grossProfitAverage: 'Promedio de Ganancia Bruta',
    grossMarginAverage: 'Promedio de Margen Bruto',
    fixedExpenses: 'Gastos Fijos',
    totalFixedExpenses: 'Total de Gastos Fijos',
    variableExpenses: 'Gastos Variables',
    totalVariableExpenses: 'Total de Gastos Variables',
    overheadExpenses: 'Gastos Administrativos',
    totalRevenueUSD: 'Ingresos Totales en USD$',
    grossProfitUSD: 'Ganancia Bruta en USD$',
    totalExpensesUSD: 'Gastos Totales en USD$',
    netIncomeMXN: 'Ingreso Neto en MXN',
    netIncomeUSD: 'Ingreso Neto en USD$',
    waitTimeForNextSequenceInSeconds: 'Tiempo de Espera Para la Prox Secuencia (en Segundos)',
    provisioningLagInSeconds: 'Retraso en el Aprovisionamiento (en Segundos)',
    addFiles: 'Agregar Archivos',
    templateName: 'Nombre de Plantilla',
    interCompanyCreditNoteItem: 'Item Para Nota de Crédito de Intercompañia',
    interCompanyInvoiceCreditReason: 'Razón de Crédito de Factura de Intarcompañia',
    chequeNumber: 'Número de cheque',
    accumulatorBasedTaxThresholds: 'Umbrales Para Impuestos Basados en Ccumuladores',
    accumulatorMinium: 'Umbral Mínimo',
    accumulatorMaximum: 'Umbral Máximo',
    subscriptionCategory: 'Categoría de la Subscripción',
    exceptionStartTime: 'Fecha Inicio de la Excepción (HH:MM:SS)',
    exceptionEndTime: 'Fecha Fin de la Excepción (HH:MM:SS)',
    percentageThreshold: 'Percentage Threshold',
    valueThreshold: 'Value Threshold',
    avoidZeroAmountTaxTransaction: 'Avoid Zero Amount Tax Transaction',
    statusMessage: 'Status Message',
    billTimeTaxation: 'Bill Time-Taxation',
    periodStart: 'Periodo Desde (YYYY-MM)',
    periodEnd: 'Periodo Hasta (YYYY-MM)',
    totalKwh: 'Kwh Total',
    contractAccountId: 'ID Contrato',
    firstNamePrepaid: 'Nombre',
    lastNamePrepaid: 'Apellido',
    phoneNumberPrepaid: 'Telefono',
    totalAmountPrepaid: 'Monto Total',
    percentageVariation: 'Porcentaje Variacion',
    meterId: 'ID Medidor',
    prev6MonthAvg: 'kWh Prom. 6 Meses Anteriores',
    kwhPeriod: 'kWh Periodo',
    kwh: 'Kwh',
    topupDate: 'Fecha y Hora',
    idEnergyTopup: 'Id',
    subscriptionIdEnergyTopup: 'Id Suscripción',
    userIdEnergyTopup: 'Id Usuario',
    paymentSourceEnergyTopup: 'Medio de Pago',
    approximateKwh: 'KWH Aproximado',
    startDateWithDetails: 'Fecha de Inicio (YYY-MM-DD)',
    endDateWithDetails: 'Fecha de Finalización (YYY-MM-DD)',
    paymentRefIdEnergyTopup: 'Id Referencia de Pago',
    topupAmount: 'Topup Amount',
    validityTermUnit: 'Validity Term Unit',
    applyPayment: 'Apply Payment',
    sourceItemId: 'Source Item Id',
    invoicesCount: 'Invoices Count',
    internetService: 'Internet Service',
    iptvService: 'Iptv Service',
    voipService: 'Voip Service',
    phoneNumber: 'Telefono',
    invoiceLineView: 'Invoice Line View',
    waitTime: 'Wait Time',
    onManualProvisioningOnly: 'On Manual ProvisioningOnly',
    retryCount: 'Retry Count',
    restartAPIOnError: 'Restart API On Error',
    errorStatus: 'Error Status',
    manualProvisioningApi: 'Manual Provisioning Api',
    rateWhenInactive: 'Rate When Inactive',
    restartApiOnError: 'Restart Api On Error',
    retriedCount: 'Retried Count',
    retryCountOnError: 'Retry Count On Error',
    retryCountOnTimeOut: 'Retry Count On Time Out',
    arRequestId: 'AR Request Id',
    paymentMethods: 'Payment Methods',
    skipAuthorization: 'Skip Authorization',
    minimumThresholdTrigger: 'Minimum Threshold Trigger',
    maximumCap: 'Maximum Cap',
    key: 'Key',
    addTo: 'Add To',
    apiKeyAttributesForm: 'Api Key Attributes Form',
    exemptOnTaxAmount: 'Exempt On Tax Amount',
    taxPercentOffset: 'Tax Percent Offset',
  },

  message: {
    deleteAddress: '¿Está seguro de querer eliminar esta dirección?',
    mandatory: 'Faltan datos de entrada obligatorios o no son válidos',
    selectAccount: 'Primero debe seleccionar la cuenta',
    noRecord: '¡No se ha encontrado ningún registro!',
    noRole: '¡No se ha encontrado ningún rol!',
    noRoleGroup: '¡No se ha encontrado ningún grupo de roles!',
    phonesNotEmpty: 'Los Teléfonos no pueden estar vacios',
    typePhoneRemaining: 'No queda ningún tipo único de teléfono',
    deleteItem: '¿Está seguro de que desea eliminar este artículo?',
    noModules: 'Sin Tipos de Módulo',
    noAccountSubscription: 'No se encontró ninguna suscripción de cuenta para',
    messageRecordsConfig: 'Los números de Registro personalizados deben estar entre 100K y 200K',
    messageCountDateResetPassword: 'días para restablecer la contraseña',
    messageDashboardType: '¡El tipo de Dashboard  no puede duplicarse!',
    usageMappingNoteStep1:
      'Debe configurar sus registros personalizados y atributos personalizados para sus registros personalizados previo al Mapeo de Archivos de Uso.',
    usageMappingNoteStep2:
      'Se requiere un Registro de Archivo de Origen de Encabezado, un Registro de Archivo de Origen Detallado y un Registro de Archivo de Origen de Tráiler.',
    usageMappingNoteStep3:
      'El diseño permite múltiples sub-registros bajo un registro detallado, incluyendo uno o más registros personalizados.',
    usageMappingNoteStep4:
      'Puede especificar un delimitador de nivel de archivo o un delimitador de nivel de registro diferente utilizado en el archivo fuente.',
    usageMappingNoteStep5:
      'Por razones de auditabilidad, si tiene múltiples subregistros bajo un registro Detalle, entonces el remolque debería idealmente tener el número de registros y el número de filas a conciliar.',
    usageMappingNoteStep6: 'Asegúrese de terminar de mapear todos los registros antes de estar funcionalmente listo.',
    processingStreamsNoteStep1:
      'Determine qué tipo de clasificación de uso necesitaría aprobar. Las opciones disponibles son:',
    processingStreamsNoteStep1a: '▪ PRETASADO (calificado externamente y cargado en Embrix)',
    processingStreamsNoteStep1b: '▪ PREPAGADO (calificación de prepago en Embrix)',
    processingStreamsNoteStep1c: '▪ POST PAGADO (Tasación de pospago en Embrix)',
    processingStreamsNoteStep2: 'Para cada tipo de Tasación, configure sus  flujos y módulos para cada  flujo.',
    processingStreamsNoteStep3:
      'El flujo de preprocesamiento debe ser el primer flujo para cualquier tipo de Tasación.',
    processingStreamsNoteStep4:
      'El flujo posterior al procesamiento debe ser el último flujo para cualquier tipo de Tasación.',
    processingStreamsNoteStep5:
      'Tiene la opción de eliminar una configuración para un tipo de Tasación específico o un flujo en una Tasación específica o un módulo en un tipo de Tasación específico. Alternativamente, puede alternarlos como Activo = Falso (por ejemplo, si solo necesita una tasación de uso pospago, puede mantener o eliminar la configuración predeterminada para el tipo de tasación de prepago y precalificada)',
    processingStreamsNoteStep6:
      'Se pueden configurar / modificar otros flujos por requerimiento. Por ejemplo, si no tiene ingresos compartidos, puede eliminar el flujo de ingresos compartidos o marcarlo como inactivo.',
    processingStreamsNoteStep7:
      'Para cada flujo tiene la flexibilidad de editar / modificar la secuencia de módulos en ese flujo.',
    processingStreamsNoteStep8:
      'Del mismo modo, tiene la flexibilidad de editar / modificar la secuencia de transmisiones para cualquier tipo de tasación específica.',
    processingStreamsNoteStep9:
      'Nuestras secuencias de módulos de preprocesamiento y posprocesamiento requieren que el conjunto de módulos provisto esté allí. El módulo en una secuencia se puede alternar como Activo o Inactivo (en lugar de eliminar el módulo).',
    processingStreamsNoteStep10:
      'Eliminar un módulo, flujo o tipo de Tasación vs. marcarlos como Inactivos no tiene mucha diferencia desde el punto de vista de la escalabilidad.',
    processingStreamsNoteStep11:
      'La hoja de ruta futura permitiría agregar flujos personalizados y módulos personalizados.',
    validateEmail: 'Correo electrónico inválido.',
    validateInvoiceNumber: 'Número de factura no puede ser Nulo.',
    manualBillingStep1:
      'Si no se proporciona la fecha de inicio de la factura y la fecha de finalización de la factura, se predeterminarán a la fecha actual.',
    manualBillingStep2:
      'Puede proporcionar la fecha de la transacción como fecha común para todas las transacciones. Puede omitir proporcionar las fechas de inicio y fin en cada nivel de línea.',
    manualBillingStep3:
      'La descripción se ajusta por defecto a la descripción del artículo seleccionado. Puede elegir la descripción personalizada para cada línea.',
    manualBillingStep4:
      'El cálculo automático a nivel de línea es para su comodidad. Puede optar por ingresar el Monto Neto.',
    manualBillingStep5: 'El Id. del Artículo y el Nombre del Servicio Facturable se excluyen mutuamente.',
    messageUpDownOrder:
      'La Orden para Subir de Categoría / Bajar de Categoría  no es compatible con Órdenes a la Carta',
    messageNoUpgrade: 'No hay opción de actualizaciones configurada',
    messageNoDowngrade: 'No se han configurado opciones de degradación',
    messageTrialOrder: 'Elija Paquete, Bundle u Ofertas de Precio',
    message2Selection: '¡Se requieren al menos 2 selecciones!',
    message1Selection: '¡Se requieren al menos 1 selecciones!',
    messageProcess: '¿Está seguro de querer procesar todos los archivos?',
    noteThreshold1:
      'Configurar el Umbral Mínimo y Máximo para una Operación Específica de CxC, Nivel de Operaciones de CxC, Código de Motivo y Moneda.',
    noteThreshold2:
      'Alternativamente, establezca los umbrales como genéricos. Cuando no se proporciona un valor de atributo, esa configuración se aplica a todos los valores posibles para ese atributo.',
    noteThreshold3:
      'En términos de aplicación, buscará la entrada de coincidencia exacta para determinar los umbrales. Si no se encuentra, buscará la ausencia (implica TODOS los Valores).',
    noteThreshold4:
      'El Umbral Mínimo se utilizará para determinar si se debe generar una Nota de Crédito / Débito si los valores <= Umbral Mínimo.',
    noteThreshold5:
      'El Umbral Máximo limitaría al usuario a realizar la Operación de CxC para un valor > al Valor del Umbral Máximo  configurado.',
    messageDuplicateAttribute: '¡El atributo Flex no puede duplicarse!',
    messageLocation: 'La división de Ubicación para el 100% no está completa',
    messageAccountExist: 'El Id de la Cuenta ya existe',
    mergeCompleted: 'Todas las opciones de fusión fueron completadas?',
    dragDropTitle: 'Arrastra y Suelta tus Archivos o <span class="filepond--label-action">Navega</span>',
    messageGroupIdExist: 'El ID del Grupo ya existe',
    messageErrorSpaceOperator: 'Favor de agregar espacios antes y después  de los caracteres: "(", ")"',
    messageErrorSpaceOperator2: 'Favor de agregar espacios después del carácter "("',
    messageErrorSpaceOperator3: 'Favor de agregar espacios antes del carácter ")"',
    userTypeCannotBeEmpty: 'El Tipo de Usuario no puede estar vacío',
    userPasswordCannotBeEmpty: 'La Contraseña de Usuario no puede estar vacía',
    userIdCannotBeEmpty: 'El ID de Usuario no puede estar vacío',
    messagePendingOrder: 'La Orden tiene Equipos, la aprobación del Proyecto sigue pendiente',
    messageApprovalPendingOrder: 'Aprobación del Proyecto Pendiente',
    messageUndoProcessSplit: '¿Está Cancelada la Orden en Aprovisionamiento?',
    quickNotesBillableService: `<div class="note-content">
    <div class="title-order-note">Cargos recurrentes: (para Servicios Renta)</div>
    <div class="pl-3">
      <b>&#9679; &nbsp;&nbsp; Tipo Recurrente (Adelante/Mora) - </b>
      Si es hacia Adelante, el cliente verá la transacción el 1ro del mes. Si es en Mora, el cliente verá la transacción en el DOM de corte. La transacción se facturará en la Fecha de Corte del Mes.
    </div>
    <div class="pl-3">
      <b>&#9679; &nbsp;&nbsp; Mes Calendario (VERDADERO/FALSO): </b>
      El valor predeterminado es VERDADERO. Cuando el Mes Calendario es VERDADERO, Embrix cargará la transacción para el mes actual.
    </div>
    <div class="pl-3">
      <b>&#9679; &nbsp;&nbsp; Fecha de Corte DOM: </b>
      Día del Mes en que se facturará el cargo.
    </div>
    <div class="pl-3">
      <b>&#9679; &nbsp;&nbsp; Bandera Por Adelantado: (VERDADERO/FALSO): </b>
      Si es Verdadero, la factura del cliente se facturará  el Día del Mes indicado como Fecha de Corte pero considerando el período del  próximo mes.
    </div>
    <div class="pl-3">
      <b
        >&#9679; &nbsp;&nbsp; Frecuencia de Recurrencia y Unidad de Recurrencia:
      </b>
      'n' Meses/Trimestres/Años.
    </div>
    <div class="pl-3">
      <b>&#9679; &nbsp;&nbsp; Exención Parcial del Mes: (VERDADERO/FALSO) </b>
      Si es Verdadero, no se cobrará la primera parte del mes.
    </div>
    <div class="title-order-note mt-3">Exhibiciones de Facturas (Venta)</div>
    <div class="pl-3">
      Si el atributo de exhibiciones de factura se establece en un valor > 1, entonces al Servicio de Venta se le tratatará en Embrix como una Renta.
    </div>
    <div class="title-order-note mt-3">Ejemplos :</div>
    <div class="pl-3">
      <b>1. &nbsp;&nbsp;</b>
      Mes Calendario= Verdadero, Día de Corte = 5 y Tipo Recurrente = Adelante.  Se facturará  el 5 de Noviembre el período comprendido entre el  1 de noviembre al 30 de Noviembre.
    </div>
    <div class="pl-3">
      <b>2. &nbsp;&nbsp;</b>
      Mes Calendario= Falso, Corte = 5 y Tipo Recurrente = Adelante. Se  facturará el 5 de noviembre el Período que correspondería del 5 de Noviembre al 4 de Diciembre.
    </div>
    <div class="pl-3">
      <b>3. &nbsp;&nbsp;</b>
      Mes Calendario= Verdadero, Día de Corte  = 25 y Tipo Recurrente = Mora.  Se  facturará el 25 de Noviembre el período comprendido del 1o  de Noviembre al 30 de Noviembre.
    </div>
    <div class="pl-3">
      <b>4. &nbsp;&nbsp;</b>
      Mes Calendario = Falso, Día de Corte  = 25 y Tipo Recurrente = Mora. Será facturado el 25 de Noviembre para el período del 25 de Octubre al 24 de Noviembre.
    </div>
    <div class="pl-3">
      <b>5. &nbsp;&nbsp;</b>
      Mes Calendario Verdadero/Falso, Fecha de Corte = 17, Tipo Recurrente = Adelante, "Por Adelantado" = Verdadero.  Será  facturado el 17 de Octubre para el período que comprende del 1o  de Noviembre al 30 de Noviembre.
    </div>
  </div>
  `,
    quickNotesSplitOrder: `<div class="note-content pl-3 pr-3">
  <div>
    <b>1. &nbsp; Antes de dividir una NUEVA oportunidad, </b>
    vaya al Centro de Clientes -> Gestión de Clientes -> Seleccione la Cuenta del Cliente en función de la "Organización" y actualice la cuenta del cliente y los datos de la cuenta (en el Panel de Navegación) para los siguientes elementos de datos:
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Agregar Contactos
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Agregar Dirección
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Cambiar Perfil de Pago 'Términos de pago'
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Establecer Atributos Personalizados
  </div>
  <div class="mt-3">
    <b>2. &nbsp; </b>
    Cuando la Cuenta del Cliente esté completamente configurada y usted realice un 'Proceso de División ' al crear o agregar nuevas Cuentas de Facturación.
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Los datos del Cliente serán heredados automáticamente del Cliente a las Cuentas de Facturación.
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Esto evitaría tener que volver atrás y cambiarlo más tarde.
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    También el usuario tiene la opción de actualizar/restablecer/anular los datos a nivel de la cuenta de facturación en caso de ser necesario.
  </div>
  <div class="mt-3">
    <b>3. &nbsp; Para realizar un 'Proceso de División ' para una NUEVA oportunidad.</b>
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    De inicio actualice la Cuenta del Cliente para heredar primero los elementos de datos del Cliente a las Cuentas de Facturación.
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Mueva el control deslizante "Procesar División " en la parte superior a SÍ.
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Configure el contador para ingresar el 'Número de Cuentas de Facturación' que desee crear.
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Presione el botón 'CREAR' Esto creará las nuevas Cuentas de Facturación.
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Realice el Proceso de División. Las nuevas Cuentas de Facturación estarán disponibles para ser asociadas a los servicios.
  </div>
  <div class="mt-3">
    <b>4. &nbsp; Para realizar un 'Proceso de División ' para cualquier oportunidad posterior.</b>
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Mueva el control deslizante "Proceso de División " en la parte superior a SÍ.
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Ver las Cuentas de Facturación existentes en el menú desplegable.
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Mueva el control deslizante "Agregar Cuentas de Facturación adicionales" a SÍ, en caso que desee asociar los nuevos servicios con las nuevas Cuentas de Facturación.
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Configure el contador para el 'Número de Cuentas de Facturación' que desee crear. Si configura el contador en 1, se creará una Cuenta de Facturación más para el Cliente.
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Presione el botón 'CREAR', esto creará las nuevas cuentas de facturación.
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Realizar el Proceso de División. Las nuevas Cuentas de Facturación estarán disponibles para asociarse con los servicios.
  </div>
  <div class="mt-3">
    <b>5. </b>
    &nbsp; El usuario puede elegir
    <b> Guardar División (Save Split)’ </b>
    para guardar la Solicitud de División incompleta y luego regresar para completarla más tarde.
  </div>
</div>`,
    errorTotalAmountSplit: 'El monto total de cada cuenta debe ser igual al monto total del servicio facturable.',
    modifyBillingProfile:
      'Las Modificaciones en los Días del Mes de Facturación o  Cambios en la Frecuencia serán efectivos a partir del próximo ciclo de facturación. ¿Desea Continuar?',
    quickNotesConfigAllPages: `  <div class="note-content">
      <b>
      Puede haber tareas relacionadas que deben realizarse. Asegúrese de realizar las tareas para administrar las dependencias:
      </b>
      <div class="pl-3">
        <b>1. Códigos de Motivo: </b>
        Asegúrese de haber configurado todos sus Códigos de Motivo personalizados. Si no, haga clic
        <a
          href=${`${window.location.origin}${RouterNames.arOperationsConfigReasonCode.path}`}
          target="_blank"
          class="text-link"
        >
          aquí.
        </a>
      </div>
      <div class="pl-3">
        <b>2. Usuarios: </b>
        Asegúrese de haber agregado todos sus Usuarios. Si no, haga clic
        <a
          href=${`${window.location.origin}${RouterNames.userManagementUser.path}`}
          target="_blank"
          class="text-link"
        >
          aquí.
        </a>
      </div>
      <div class="pl-3">
        <b>3. Grupos de Usuarios: </b>
        Asegúrese de haber creado/actualizado todos sus Grupos de Usuarios. Si no, haga clic
        <a
          href=${`${window.location.origin}${RouterNames.userManagementUserGroups.path}`}
          target="_blank"
          class="text-link"
        >
          aquí.
        </a>
      </div>
      <div class="pl-3">
        <b>4. Motivos de Grupo: </b>
        Asegúrese de haber configurado los Motivos de Grupo para los códigos de motivo. Si no, haga clic
        <a
          href=${`${window.location.origin}${RouterNames.arOperationsConfigGetReasonGroup.path}`}
          target="_blank"
          class="text-link"
        >
          aquí.
        </a>
      </div>
      <div class="pl-3">
        <b>5. Aprobaciones: </b>
        Asegúrese de haber configurado los niveles de aprobación para sus operaciones. Si no, haz clic
        <a
          href=${`${window.location.origin}${RouterNames.userManagementApproval.path}`}
          target="_blank"
          class="text-link"
        >
          aquí.
        </a>
      </div>
    </div>`,
    messgaeErrorReasonCode: 'Proporcione el Código de Motivo o el Código de Motivo Personalizado',
    messageCopyChangeToAllRecurring:
      '¿Está usted seguro? Copiar los cambios a todo lo recurrente, copiará estos cambios en todos los Servicios de Renta  y todos los Servicios de Cargo Único con un número de exhibiciones en las  facturas > 1',
    manualInvoiceMessage: 'Ha seleccionado crear una factura para una cuenta de facturación diferente. ¿Está seguro?',
    messageNoteSavedPendingAccountMovement: 'Por favor ingrese una nota antes de guardar.',
    messageErrorSplitOrder: 'La cuenta de facturación no está seleccionada para todos los Servicios Facturables',
    messageValidTotalAmount: 'El monto total debe ser igual a',
    messageValidTotalQuantity: 'La cantidad total debe ser igual a',
    messageInvoiceIdAleadyExist: 'El ID de la Factura ya existe',
    warningMessageSelectServiceName:
      '¿Es una factura manual por cambio retrospectivo de frecuencia en facturas (del pasado)?',
    messageCopyChangeToAllRecurringExcludeUsage:
      '¿Está Seguro? Copiar el Cambio a Todos copiará este cambio a todos los servicios facturables excepto los de cutoffDom=USAGE',
    validUploadMessage:
      'El archivo que se está cargando no es un archivo plano. Los tipos de archivo aceptados son .csv y .txt',
    messageReporcess: 'Reprocesar a este nivel reprocesará todos los registros fallidos/suspendidos para este batch.',
    messageReporcessDetails:
      'Reprocesar a este nivel reprocesará únicamente los registros fallidos/suspendidos con este código de error.',
    messageNoteAccountStatement:
      'Para la Cuenta del CLIENTE, el saldo inicial y final siempre es mostrado como 0 ya que noes aplicable a la Cuenta del CLENTE.',
    messageValidationUsageCutoffDom:
      'Some Billable Services have cutoff = USAGE so Rental would be billed along with Traffic. Do you want to continue ?',
    messagePaymentGoneSuspense: 'El Pago ha ido a Suspenso!',
    messageValidNewLine: 'El Texto con caracteres de Salto de Línea  no es compatible con el PAC',
    messgaeNotePaymentSuspense: `<div>
    <h6 class="text-center pb-3">
      <b>Códigos de Pagos en Suspenso</b>
    </h6>
    <div class="pb-2">
      <b>&#9679; CUENTA_CERRADA: </b>
      El pago fue recibido para una Cuenta Cerrada.
    </div>
    <div class="pb-2">
      <b>&#9679; CUENTA_NO_ENCONTRADA: </b>
      La cuenta de referencia no existe en la Plataforma de Facturación.
    </div>
    <div class="pb-2">
      <b>&#9679; CUENTA_INVÁLIDA: </b>
      La cuenta de referencia no es válida. Es posible que esto no aplique en su implementación.
    </div>
    <div class="pb-2">
      <b>&#9679; FACTURA_NO_ENCONTRADA: </b>
      No hay facturas asociadas para la cuenta referenciada.
    </div>
    <div class="pb-2">
      <b>&#9679; NO_ES_UNA_CUENTA_DE_FACTURACIÓN: </b>
      La cuenta no es una Cuenta de Facturación. Es posible que esto no se aplique en su implementación.
    </div>
    <div class="pb-2">
      <b>&#9679; SOBRE_PAGO: </b>
      El pago recibido es un sobrepago. Si existe la política para aplicar automáticamente a la DEUDA NETA de la cuenta, entonces se aplicará el pago. De lo contrario, permanecerá en suspenso.
    </div>
    <div class="pb-2">
      <b>&#9679; CUENTA_ANTERIOR: </b>
      El pago recibido es anterior a la fecha de creación  de la cuenta.
    </div>
    <div class="pb-2">
      <b>&#9679; FACTURA_PREVIA: </b>
      El pago recibido es anterior a la fecha de la factura.
    </div>
    <div class="pb-2">
      <b>&#9679; REGISTRO_DE_ESTADO: </b>
      Registro de Estado de Cuenta recibido en un archivo bancario que no necesita ser procesado.
    </div>
    <div class="pb-2">
      <b>&#9679; PAGO_INFERIOR: </b>
      El pago es un pago insuficiente. Si la política es no aplicar pagos insuficientes automáticamente, se pasará a Pagos en Suspenso.
    </div>
  </div>`,
    messgaeNoteReprocessing: `<div>
  <h6 class="text-center pb-3">
    <b>Códigos de Suspensión de Uso</b>
  </h6>
  <div class="pb-2">
    <b>&#9679; NO_ACTIVE_SERVICE_FOR_THE_PROVISIONING_ID: </b>
    El ID de aprovisionamiento en el CDR no está asociado con ningún servicio activo. Es probable que la fecha de cancelación del ID de aprovisionamiento no esté alineado o que el ID de aprovisionamiento se haya cancelado de alguna otra cuenta, pero no se haya reasignado a ninguna cuenta de facturación. Vuelva a verificar la fecha de cancelación del ID de aprovisionamiento o asegúrese de que el ID de aprovisionamiento esté asociado con la cuenta de facturación correcta antes de volver a procesar los CDR suspendidos con este código de error.
  </div>
  <div class="pb-2">
    <b>&#9679; NO_SERVICE_FOR_THE_PROVISIONING_ID: </b>
    El ID de  aprovisionamiento en el CDR no se encuentra en la plataforma de facturación. El sistema de aprovisionamiento no ha asignado la ID a un servicio o los servicios de la Orden no califican para Uso. Asegúrese de que el pedido y el ID de aprovisionamiento estén habilitados antes de volver a procesar los CDR suspendidos con este código de error.
  </div>
  <div class="pb-2">
    <b>&#9679; NO_MATCHING_PRICING_FOR_USAGE_RECORD: </b>
    No se encontró ningún precio para el tipo de uso asociado con el CDR. Asegúrese de que el sistema de Ordenes proporcione el precio correspondiente al tipo de uso. Asegúrese de que el precio esté  configurado  antes de volver a procesar los CDR con este código de suspensión.
  </div>
  <div class="pb-2">
    <b>&#9679; NO_MATCHING_OPERATOR_PRICING_FOR_USAGE_RECORD: </b>
    El registro CDR está asociado con el operador, pero no se encuentra ningún precio del operador. Asegúrese de que los precios del operador estén habilitados antes de volver a procesar los CDR con este código de suspensión.
  </div>
  <div class="pb-2">
    <b>&#9679; NO_MATCHING_BILLABLE_SVC_PRICING_FOR_USAGE_RECORD: </b>
    Al Servicio Facturable le falta el precio del CDR. Asegúrese que el sistema de Pedidos proporcione el precio adecuado para el tipo de uso. Asegúrese de que el precio se configure  desde el sistema de Ordenes antes de volver a procesar los CDR con este código de suspensión.
  </div>
</div>`,
    messageCloseSuspense: '¿Está seguro de querer cerrar el Pago en Suspenso ?',
    messgaeNoteInvoiceAction: `<div>
    <div class="pb-2">
      <b>1. REENVIAR_AL_PROVEEDOR: </b>
      Esto permite regenerar un Extracto del proveedor de timbrado y enviarlo al PAC. Esta acción solo se permite cuando el estado del folio es "ESTAMPADO_SOLICITADO, SE_SOLICITA_ESTAMPADO_DE_CANCELACIÓN" y el total de la factura es > 0.
    </div>
    <div class="pb-2">
      <b>2. REGENERAR_FACTURA:</b>
      Esto permite regenerar la factura. Esta acción solo se permite cuando el estado no es "APROBACIÓN_PENDIENTE" y el total de la factura es > 0.
    </div>
    <div class="pb-2">
      <b>3. APROBAR: </b>
      Esto es para la aprobación de la factura. Esta acción solo se permite cuando el estado es "APROBACIÓN_PENDIENTE" y el total de la factura > 0.
    </div>
    <div class="pb-2">
      <b>4. DESAPROBAR </b>
      Esto es para la desaprobación de la factura. Esta acción solo se permite cuando el estado es "APROBACIÓN_PENDIENTE" y el total de la factura > 0.
    </div>
    <div class="pb-2">
      <b>5. ENVIAR_POR_CORREO ELECTRÓNICO_FACTURA: </b>
      Esto permite enviar la factura por correo electrónico. Esta acción solo se permite cuando el estado no es "APROBACIÓN_PENDIENTE" y el total de la factura es > 0.
    </div>
    <div class="pb-2">
      <b>6. DESHACER_FACTURA_MANUAL </b>
      Esto es para deshacer la factura manual. Esta acción solo se permite cuando el estado no es "APROBACIÓN_PENDIENTE, CLOSED, COLLECTION, SENT", el total de la factura > 0 y el tipo de factura es FACTURA_MANUAL.
    </div>
    <div class="pb-2">
      <b>7. RESINCRONIZAR_FACTURA </b>
      Esto es para resincronizar la factura. Esta acción solo se permite cuando el estado es "SINCRONIZACIÓN_FALLIDA".
    </div>
    <div class="pb-2">
      <b>8. EXTENDER_FECHA_DE_VENCIMIENTO </b>
      Esto es para extender la fecha de vencimiento de la factura. Esta acción solo se permite cuando vence > 0.
    </div>
    <div class="pb-2">
      <b>9. DESHACER_CANCELACIÓN_DE_FACTURA </b>
      Esto es para deshacer la cancelación de la factura. Esta acción solo se permite cuando el estado del folio es CANCELACIÓN_STAMPING_REQUESTED y el total de la factura es > 0.
    </div>
  </div>`,
    messgaeNoteCreditNoteAction: `<div>
  <div class="pb-2">
    <b>1. APROBAR: </b>
    Esto es para la aprobación de la factura. Esta acción solo se permite cuando el estado es "APROBACIÓN_PENDIENTE".
  </div>
  <div class="pb-2">
    <b>2. DESAPROBAR: </b>
    Esto es para la desaprobación de la factura. Esta acción solo se permite cuando el estado es "APROBACIÓN_PENDIENTE".
  </div>
  <div class="pb-2">
    <b>3. REENVIAR_AL_PROVEEDOR: </b>
    Esto permite regenerar un extracto del proveedor de Timbrado y enviarlo al PAC. Esta acción solo se permite cuando el estado del folio es "ESTAMPADO_SOLICITADO, SE_SOLICITA_ESTAMPADO_DE_CANCELACIÓN".
  </div>
</div>`,
    messgaeNotePaymentAction: `<div>
    <div class="pb-2">
      <b>1. REENVIAR_AL_PROVEEDOR: </b>
      Esto permite regenerar un extracto del proveedor de Timbrado y enviarlo al PAC . Esta acción solo se permite cuando el estado del folio es "ESTAMPADO_SOLICITADO, SE_SOLICITA_ESTAMPADO_DE_CANCELACIÓN".
    </div>
    <div class="pb-2">
      <b>2. APLICAR_PAGO: </b>
      Esto es para aplicar automáticamente el pago. Esta acción solo se permite cuando el estado es "ABIERTO, REVERTIDO ", el Monto restante > 0 y todas las filas seleccionadas deben ser iguales en Fecha de pago, Moneda, Monto Restante.
    </div>
    <div class="pb-2">
      <b>3. APLICACIÓN_AUTOMÁTICA :</b>
      Esto es para aplicar automáticamente el pago. Esta acción solo se permite cuando el estado es "ABIERTO, REVERTIDO " y el Monto Restante > 0.
    </div>
    <div class="pb-2">
      <b>3. PAGO_REVERTIDO: </b>
      Esto es para revertir el pago. Esta acción solo se permite cuando el estado del folio es "ESTAMPADO_SOLICITADO, SE_SOLICITA_ESTAMPADO_DE_CANCELACIÓN", el estado no es "REVERTIDO , REVERTIDO_A_SUSPENSO " y el valor total de Monto Restante y Monto no debe ser igual a 0.
    </div>
    <div class="pb-2">
      <b>4. EMAIL_FOLIO: </b>
      Esto permite enviar por correo electrónico el FOLIO. Esta acción solo se permite cuando el estado del folio es "STAMPED" y el pago tiene un archivo PDF para enviar.
    </div>
</div>`,
    quickNotesReprocess:
      'Reprocesar todo reprocesará todos los registros suspendidos/fallidos. Si se selecciona el código de error, se volverán a procesar todos los registros con ese código de error; de lo contrario, se volverán a procesar todos los registros en estado suspendido/fallido (independientemente del código de error).',
    messageChooseADay: '¡Debes Elegir un Día de la Semana!',
    messageRemoveLinePurchaseAlaCarte: '¿Quiere Cancelar Todas las Líneas de este Servicio?',
    messageNotesUsageTool: `<div>
    <div class="pb-2">
      <b class="mr-2">1. </b>
      El Id de Aprovisionamiento es el identificador del servicio a tarificar.
    </div>
    <div class="pb-2">
      <b class="mr-2">2. </b>
      El Id de Aprovisionamiento es el identificador del servicio a tarificar.
      Para el servicio de VOZ o TELEFONÍA la fecha de inicio es obligatoria junto con la fecha de finalización o la cantidad (uno de estos parámetros es obligatorio). La fecha de inicio y finalización siempre deben estar en el pasado en comparación con la fecha actual del sistema.
    </div>
    <div class="pb-2">
      <b class="mr-2">3. </b>
      La "Unidad" es la escala para la cantidad ingresada. Se utiliza si no se especifica la fecha de finalización para determinar la duración que se agregará a la fecha de inicio (para el servicio de VOZ y TELEFONÍA). Para otros servicios es sólo informativo.
    </div>
    <div class="pb-2">
      <b class="mr-2">4. </b>
      El volumen de carga y el volumen de descarga son solo para el servicio de DATOS.
    </div>
  </div>`,
    dashboardNoConfigMessage:
      'Los parámetros del Dashboard no están configurados  para el usuario. Por favor establezca  la configuración para ver el Dashboard en "Mis Dashboard"',
    msgChoosePackageBundlePO: 'Por favor elija Paquete, Bundle u Ofertas de Precios',
    contentSelectAccountInQuote:
      'No se proporcionó un Id de Cuenta para la cotización. ¿Desea seleccionar una cuenta existente o crear una cuenta nueva para convertir esta cotización en Orden?',
    associateAllOtherQuotes: '¿Quiere asociar todas las otras cotizaciones pendientes a esta cuenta?',
    oldPassNotMatch: 'La contraseña anterior no coincide',
    confirmPasswordIncorrectly: 'Confirmación de contraseña incorrecta',
    oldPassNotSame: 'La contraseña anterior  y la nueva contraseña no pueden ser las mismas',
    noTypeDashboard: 'No queda ningún Dashboard de tipo único',
    useStartEndRange: 'Use el Número Inicial y Final para Seleccionar el Rango',
    orderTypesAlreadyConfigured: 'Todos los tipos de órdenes ya están configurados',
    noteBillingProfile:
      'El cambio en el domicilio de facturación, la frecuencia de facturación o la duración entrará en vigor a partir del próximo ciclo de facturación.',
    messageMultiSubsription:
      'Suscripción Múltiple deshabilitada. Primero habilite la propiedad para agregar varias suscripciones.',
    subscriptionNameNotDuplicate: 'El Nombre de la Suscripción no se puede duplicar.',
    noTopupMessage: 'No hay suscripciones de PREPAGO en la cuenta para recargar',
    percentageThresholdInfo: 'Ingrese “|” valores separados. Ejemplo: 80|90|100 o solo 100',
    messageQuickTaxes:
      'La secuencia de las filas es importante. Si cambia la secuencia de cómo se deben aplicar los impuestos, asegúrese entonces de que la expresión no tenga el nombre de una fila que se aplicará más adelante en la secuencia.',
    messageNotesTaxType: `La lista de nombres proviene de la Configuración de Tipo de Impuesto (códigos). Configure
      <a
      href=${`${window.location.origin}${RouterNames.taxTypeConfig.path}`}
      target="_self"
      class="text-link"
    >ç
    los Tipos de Impuestos
    </a> antes de configurar los códigos de impuesto`,
    messageNotesTaxType1: 'La lista de nombres proviene de la Configuración de Tipo de Impuesto (códigos). Configure',
    messageNotesTaxType2: 'los Tipos de Impuestos',
    messageNotesTaxType3: 'antes de configurar los códigos de impuesto',
    receiverNote:
      'CLIENTE – El cliente final recibe la correspondencia USUARIO – El administrador del sistema recibe la correspondencia',
    isPartialFulfillmentAllowedNote:
      'En caso de pedido multiservicio, cada servicio se instanciará en el sistema una vez que el aprovisionamiento del servicio esté completo. Por defecto, todos los servicios se instancian al final.',
    billingOnlyNote: 'El pedido no requiere aprovisionamiento y solo se ejecuta el flujo de facturación.',
    provisioningOnlyNote: 'El pedido no requiere facturación y solo se ejecuta el flujo de aprovisionamiento.',
    billOrderChargesNote: 'Los cargos aplicados para este pedido se facturan inmediatamente.',
    onErrorResubmitNote:
      'Durante el procesamiento del pedido, si hay un error en este paso y si esta bandera está habilitada, entonces Embrix detendrá el procesamiento adicional del pedido. Al reenviarlo, el pedido continuará procesándose desde este paso en adelante.',
    executeOnErrorNote:
      "En caso de error en un paso anterior y necesite que Embrix continúe procesando la secuencia pero omita ciertos pasos, esos pasos deben marcarse con esta bandera. Esto es mutuamente excluyente con 'Reenviar en caso de error'.",
    errorApiNameNote:
      'En caso de error con este paso y necesite que Embrix continúe procesando la secuencia pero salte a un paso específico, puede seleccionar la API a la que saltar. Embrix añadirá automáticamente la API como el último paso para que la gestione. Esto es mutuamente excluyente con las otras opciones de error.',
    smsTemplateNote:
      '<span>Estos marcadores de posición en el mensaje que se deben reemplazar por valores reales en tiempo de ejecución deben ser prefijados y sufijados con -- por ejemplo: Hola --nombre-- , este es un mensaje de bienvenida de Embrix. Tu número de cuenta es --númeroDeCuenta--. Esperamos que disfrutes de nuestro servicio.</span>',
    identityNote: 'Número/valor de Identificación del Cliente.',
    identityDocumentNote: 'Prueba de documento, como Pasaporte, Licencia, etc.',
    poDescription:
      'El valor en el campo Descripción, si se menciona, se usará para establecer la descripción de la transacción para la transacción calificada.',
    useAccumulatorBalanceInfo: 'Habilitado solo para descuenttos de volumen.',
    groupByOrderReportInfo: 'Seleccione esta opción si requiere una fila por orden. Sino, no selecciones la opción.',
    noOrderSubscription: 'No hay subscripciones activas de ningún tipo.',
    accountNotHaveProvisioning:
      'La cuenta no posee ninguna información de aprovisionamiento asociada. Por favor, seleccion una cuenta valida o revise la información de provisioning para la cuenta seleccionada.',
    selectUserCollectionsInfo:
      'Con el fin de agregar un usuario como agente de cobranza, asegúrese que dicho usuario posee información de contacto. Sino, el sistema no le permitirá agregar dicho usuario como agenge de cobranza.',
    exceptionDateInfo: 'La acción listada no será ejecutada durante el período entre la hora de inicio y fin.',
    percentageThresholdInfoExclusive:
      'Enter “|” separated values. e.g. 80|90|100 or just 100. The values must be between 0 and 100. This is mutually exclusive to the Value Threshold field.',
    valueThresholdInfoExclusive:
      'Enter “|” separated values. e.g. 80|90|100 or just 100. The values can be any numeric values. This is mutually exclusive to the Percentage Threshold field.',
    useNameAsInvoiceDescriptionTooltip:
      'If the flag is set, then the name of the price offer will be used as transaction description and in invoice presentment. Otherwise, the name of the item will be used (default).',
    processJobMessage: 'Está seguro que requiere procesar/reprocesar la tarea agendada?',
    waitTimeInfo:
      'Specify the Wait time in seconds. This is the time that Embrix will wait before executing the next step in the sequence.',
    oneManualProvisioningOnlyInfo:
      'In case any step fails and there is a requirement to make an outgoing call and we expect an incoming API call with some manual provisioning data provided, then the caller has to pass the “ManualProvisioning’ flag in the input request payload. That flag is an indicator for Embrix to only execute from the manual provisioning step.',
    retryCount:
      'Specify the retry count for Embrix in case this step has an error. Embrix will perform the specified number of retries before following the error sequence.',
    restartAPIonError:
      'In case this step fails and there is a requirement to make an outgoing call and we expect an incoming API call following that, then that incoming API call must include an attribute ‘restartProvisioning’ flag in the input request payment. This flag is an indicator for Embrix to restart the sequence from the API listed with this attribute.',
    errorStatusInfo: 'You can specify a specific value that you want Embrix to set and pass with the outgoing API call',
    rateWhenInactiveInfoPO:
      'When selected, the price unit instance of this price offer will be considered for rating even if the price unit instance status is INACTIVE, during billing.',
    rateWhenInactiveInfoDO:
      'When selected, the discount unit instance of this discount offer will be considered for rating even if the discount unit instance status is INACTIVE, during billing.',
    messageModifyJobSchedule: 'Are you sure you want to modify the job schedule?',
    skipAuthorizationInfo:
      'Skip Authorization when checked, indicates not to set authorization attributes when invoking the API',
    minimumThresholdTriggerInfo:
      'If the Minimum Threshold Trigger is true, the minimum threshold is used as a threshold trigger. The tax will not apply until the minimum threshold is it. But, once the minimum threshold is hit, it applies the tax to the entire amount till now. Example: If minimum threshold is 100 and flag is true and if the usage crosses the threshold to 110, then the entire 110 will be taxed. Otherwise, the flag is considered as a Minimum Limit which means that with the same example, the tax will only apply to quantity 10.',
    exemptOnTaxAmountInfo:
      'If the flag is false, the tax exemption applies to the taxable amount. The taxable amount will be reduced by the percentage and the tax will be calculated on the derived amount. If the flag is true, the tax exemption applies on the tax amount itself.',
    taxPercentOffsetInfo:
      'If the percent offset value is used instead of the percent field then the offset percentage applies to the tax rate. For example if IVA tax rate is 13%, and the offset is set as 9%, then 4% IVA will be applied. The user will have to be careful to not set the value as greater than the tax rate. Otherwise the applied tax would be 0.',
  },

  apiMessage: {
    // paymentAllocated: 'Payment allocated successfully!',
    createUserSuccessfully: '¡Usuario Creado con Éxito!',
    modifyUserSuccessfully: 'Modificación de Usuario Exitosa!',
    modifyUserFailed: 'Modificación de Usuario Fallida!',
    checkAuthorization: '¡Error en la Verificación de Autorización!',
    sentEmailSuccessfully: '¡Email Enviado con Éxito!',
    createFileSuccessfully: '¡Archivo Creado con Éxito!',
    modifyServiceLocationsSuccessfully: '¡Modificación de Ubicaciones del Servicio Exitosa!',
    modifyServiceSuccessfully: '¡Modificación del Servicio Exitosa!',
    paymentReversedSuccessfully: '¡Pago revertido con éxito!',
    paymentAllocatedSuccessfully: '¡Pago asignado con éxito!',
    modifyBillableServiceSuccessfully: '¡Modificación del Servicio Facturable con éxito!',
    reGeneratePaymentSuccessfully: '¡Regeneración del Pago con éxito!',
    updateProvisioningSuccessfully: '¡Actualización del Aprovisionamiento con éxito!',
    updatePaymentStatusSuccessfully: '¡Actualización  del Estado del Pago con éxito!',
    updateArOpsConfigSuccessfully: '¡Actualización de la Configuración de  Ops de CxC con éxito!',
    applyAdjustmentSuccessfully: '¡Ajuste aplicado con éxito!',
    applyDisputeSuccessfully: '¡Aplicación de Disputa con éxito!',
    settleADisputeSuccessfully: '¡Disputa Resuelta con éxito!',
    reverseWriteOffSuccessfully: '¡Reversión de Anulación exitosa!',
    applyWriteOffSuccessfully: '¡Se aplicó la Cancelación con éxito!',
    modifyArOpsItemMappingSuccessfully: '¡Se modificó el mapeo de elementos de Ops de CxC con éxito!',
    modifyReasonCodeSuccessfully: '¡Modificación del Código de Motivo con éxito!',
    createReasonCodeSuccessfully: '¡Creación del Código de Motivo con éxito!',
    createGroupsReasonSuccessfully: '¡Creación de Motivo de Grupos con éxito!',
    modifyARThresholdsSuccessfully: '¡Modificación de los Umbrales de CxC con éxito!',
    createARThresholdsSuccessfully: '¡Creación de Umbrales de CxC con éxito!',
    modifyCurrencySuccessfully: '¡Modificacón de Moneda con éxito!',
    createCurrencySuccessfully: '¡Creación de Moneda con éxito!',
    modifyGrantsSuccessfully: '¡Modificación de Subvenciones con éxito!',
    createGrantsSuccessfully: '¡Creación de Subvenciones con éxito!',
    modifyAccumulatorsSuccessfully: '¡Modificación de Acumuladores con éxito!',
    createAccumulatorsSuccessfully: '¡Creación de Acumuladores con éxito!',
    modifyRateUnitSuccessfully: '¡Modificación de Unidad de Tasación con éxito!',
    createRateUnitSuccessfully: '¡Creación de Unidad de Tasación  con éxito!',
    modifyTimeUnitSuccessfully: '¡Modificación de Unidad de Tiempo con éxito!',
    createTimeUnitSuccessfully: '¡Creación de Unidad de Tiempo con éxito!',
    modifyCustomRecordsSuccessfully: '¡Modificación de Registros Personalizados con éxito!',
    createCustomRecordsSuccessfully: '¡Creación de Registros Personalizados con éxito!',
    modifyFileMappingSuccessfully: '¡Modificación del Mapeo de Archivos con éxito!',
    createFileMappingSuccessfully: '¡Creación del Mapeo  de Archivos con éxito!',
    modifyProcessingStreamsSuccessfully: '¡Modificación de los flujos de Procesamiento con éxito!',
    createProcessingStreamsSuccessfully: '¡Creación de Flujos de Procesamiento con éxito!',
    modifyRateUnitMapSuccessfully: '¡Modificar Mapa de Unidades de Tarifa con éxito!',
    createRateUnitMapSuccessfully: '¡Creación de Mapa de Unidades de Tarifa con éxito!',
    modifyTaxCodeSuccessfully: '¡Modificación del Código de Impuestos con éxito!',
    createTaxCodeSuccessfully: '¡Creación de Código de Impuestos  con éxito!',
    modifyRegionMapSuccessfully: '¡Modificación del Mapa de la Región con éxito!',
    createRegionMapSuccessfully: '¡Creación de Mapa de Región con éxito!',
    modifyZeroRateUsageSuccessfully: '¡Modificacion de Tasa Cero en Uso con éxito!',
    createZeroRateUsageSuccessfully: '¡Creación de Tasa Cero en Uso  con éxito!',
    modifyOperatorMapSuccessfully: '¡Modificación del Mapa del Operador con éxito!',
    createOperatorMapSuccessfully: '¡Creación del Mapa del Operadores con éxito!',
    modifyTrunkRuleSuccessfully: '¡Modificacón de la Regla Troncal con éxito!',
    createTrunkRuleSuccessfully: '¡Creación de Regla Troncal con éxito!',
    modifyUnitOfMeasureSuccessfully: '¡Modificación de Unidad de Medida con éxito!',
    createUnitOfMeasureSuccessfully: '¡Creación de Unidad de Medida con éxito!',
    modifyUsageTypeSuccessfully: '¡Modificación de Tipo de Uso con éxito!',
    createUsageTypeSuccessfully: '¡Creación de Tipo de Uso con éxito!',
    modifyVoicePlanSuccessfully: '¡Modificación de Plan de Voz con éxito!',
    createVoicePlanSuccessfully: '¡Creación de Plan de Voz con éxito!',
    modifyCountryCodeSuccessfully: '¡Modificación de Código de País con éxito!',
    createCountryCodeSuccessfully: '¡Creación de  Código de País con éxito!',
    modifyDelayedBillingSuccessfully: '¡Modificación de  Facturación Retrasada con éxito!',
    createDelayedBillingSuccessfully: '¡Creación de Facturación Retrasada con éxito!',
    modifyAdvanceBillingSuccessfully: '¡Modificación de Facturación Anticipada con éxito!',
    createAdvanceBillingSuccessfully: '¡Creación de Facturación Anticipada con éxito!',
    modifyPendingBillingTriggerSuccessfully: '¡Modificación del disparador de Facturación Pendiente con éxito!',
    createPendingBillingTriggerSuccessfully: '¡Creación del disparador de Facturación Pendiente con éxito!',
    modifyBundleSuccessfully: '¡Modificación de Bundle con éxito!',
    createBundleSuccessfully: '¡Creación de Bundle con éxito!',
    updateBundleStatusSuccessfully: '¡Actualización del Estado del Bundle con éxito!',
    modifyPackageSuccessfully: '¡Modificación del Paquete con éxito!',
    createPackageSuccessfully: '¡Creación del Paquete con éxito!',
    updatePackageStatusSuccessfully: '¡Actualización del Estado del Paquete con éxito!',
    modifyDependencySuccessfully: '¡Modificación de Dependencia con éxito!',
    createDependencySuccessfully: '¡Creación de Dependencia con éxito!',
    modifyCollectionActionSuccessfully: '¡Modificación de Acción de Cobranza  con éxito!',
    createCollectionActionSuccessfully: '¡Creación de Acción de Cobranza con éxito!',
    modifyCollectionAgentSuccessfully: '¡Modificación de Agente de Cobro con éxito!',
    createCollectionAgentSuccessfully: '¡Creación de Agente de Cobro con éxito!',
    modifyCollectionScheduleSuccessfully: '¡Modificación de Programa de Cobro con éxito!',
    createCollectionScheduleSuccessfully: '¡Creación de Programa de Cobro con éxito!',
    reassignCollectionAgentSuccessfully: '¡Reasignación de Agente de Cobro con éxito!',
    modifyCollectionUnitSuccessfully: '¡Modificación de Unidad de Cobranza con éxito!',
    createCorrespondenceTemplateSuccessfully: '¡Creación de Plantilla de Correspondencia con éxito!',
    modifyCorrespondenceTemplateSuccessfully: '¡Modificación de Plantilla de Correspondencia con éxito!',
    createAccountSuccessfully: '¡Creación de Cuenta con éxito!',
    modifyAccountSuccessfully: '¡Modificación de  Cuenta con éxito!',
    updateAccountStatusSuccessfully: '¡Actualización del Estado de la Cuenta con éxito!',
    createCustomAttributesSuccessfully: '¡Creación de  Atributos Personalizados con éxito!',
    modifyCustomAttributesSuccessfully: '¡Modificación de Atributos Personalizados con éxito!',
    createCreditProfileSuccessfully: '¡Creación de Perfil de Crédito con éxito!',
    modifyCreditProfileSuccessfully: '¡Modificación de Perfil de Crédito con éxito!',
    manualInvoiceSuccessfully: '¡Facturación Manual exitosa!',
    runPendingBillSuccessfully: '¡Ejecución de Facturación Pendiente con éxito!',
    sendInoivceSuccessfully: '¡Envío de Factura con éxito!',
    regenerateInoivceSuccessfully: '¡Regeneración de Factura con éxito!',
    createExternalPOSuccessfully: '¡Creación de Orden de Compra externa con éxito!',
    modifyExternalPOSuccessfully: '¡Modificación de Orden de Compra externa con éxito!',
    modifyInvoiceStatusSuccessfully: '¡Modificación deL Estado de la Factura con éxito!',
    undoManualInvoiceSuccessfully: '¡Deshacer Factura Manual con éxito!',
    createSpecialPriceSuccessfully: '¡Creación de Precio Especial con éxito!',
    modifySpecialPriceSuccessfully: '¡Modificación de Precio Especial con éxito!',
    updateOrderStatusSuccessfully: '¡Actualización del Estado de la Orden con éxito!',
    updateNotesStatusSuccessfully: '¡Actualización del Estado de las Notas con éxito!',
    updateBillingAccountSuccessfully: '¡Actualización de Cuenta de Facturación con éxito!',
    modifyInvoiceSuccessfully: '¡Modificación de Factura con éxito!',
    createxChangeRatesSuccessfully: '¡Creación de Tipos de Cambio con éxito!',
    modifyxChangeRatesSuccessfully: '¡Modificación de Tipos de Cambio con éxito!',
    invoicedAccountSuccessfully: 'Cuenta Facturada exitosamente!',
    createPaymentInstallmentSuccessfully: '¡Creación de Cuota de Pago con éxito!',
    modifyPaymentInstallmentSuccessfully: '¡Modificación de Cuota de Pago con éxito!',
    invoicedVendoSuccessfully: 'Proveedor Facturado con éxito!',
    undoInvoiceCancelSuccessfully: '¡Deshacer Cancelación de Factura con éxito!',
    processInvoiceSuccessfully: '¡Factura Procesada con éxito!',
    modifyDashboardConfigSuccessfully: '¡Modificación de Configuración del Dashboard  con éxito!',
    createDashboardConfigSuccessfully: '¡Creación de Configuración de Dashboard con éxito!',
    modifyJobConfigSuccessfully: '¡Modificación de Configuración del Trabajo con éxito!',
    createJobConfigSuccessfully: '¡Creación de Configuración de Trabajo con éxito!',
    modifyJobScheduleSuccessfully: '¡Modificación de Programa de Trabajo con éxito!',
    processJobScheduleSucessfully: '¡Procesamiento del Programa de Trabajo con éxito!',
    retryJobScheduleSucessfully: '¡Reintento de Trabajo Programado con éxito!',
    reCreateJobScheduleSucessfully: '¡Re-Creación de Programa de Trabajo con éxito!',
    usernamePasswordNotCorrect: 'Usuario o contraseña incorrecta',
    createOrderSuccessfully: '¡Creación de Orden exitosa!',
    modifyOrderSuccessfully: '¡Modificación de Orden exitosa!',
    createOrderSequenceSuccessfully: '¡Creación de Secuencia de Orden con éxito!',
    modifyOrderSequenceSuccessfully: '¡Modificación de Secuencia de Orden con éxito!',
    createProvisioningTaskSequenceSuccessfully: '¡Creación de Secuencia de Tareas de Aprovisionamiento con éxito!',
    modifyProvisioningTaskSequenceSuccessfully: '¡Modificación de Secuencia de Tareas de Aprovisionamiento con éxito!',
    createProvisioningMappingSuccessfully: '¡Creación de Mapeo de Aprovisionamiento con éxito!',
    modifyProvisioningMappingSuccessfully: '¡Modificación del Mapeo de Aprovisionamiento con éxito!',
    createBillingAccountSuccessfully: '¡Creación de Cuenta de Facturación con éxito!',
    splitOrderAccountSuccessfully: '¡División de Orden en la Cuenta con éxito!',
    createOrderReasonCodesSuccessfully: '¡Creación exitosa de Códigos de Motivo de la Orden!',
    modifyOrderReasonCodesSuccessfully: '¡Modificacón exitosa de los Códigos de Motivo de la Orden!',
    createPaymentConfigSuccessfully: '¡Creación de Configuración de Pagos con éxito!',
    modifyPaymentConfigSuccessfully: '¡Modificación de la Configuración de Pagos con éxito!',
    applyPaymentSuspenseSuccessfully: '¡Aplicación exitosa de Pago en Suspenso!',
    paymentHasGoneToSuspense: 'El Pago ha ido a Suspenso',
    paymentIsSuccessful: '¡El Pago se ha realizado correctamente!',
    paymentAllocatedSuccessful: '¡Pago asignado con éxito!',
    modifyPaymentSuspenseSuccessfully: '¡Modificación de Pago en Suspenso con éxito!',
    modifyPaymentSurchargeSuccessfully: '¡Modificación de Recargo de Pago con éxito!',
    createPaymentSurchargeSuccessfully: '¡Creación de Recargo de Pago con éxito!',
    processBatchPaymentSuccessfully: '¡Procesamiento de Pago Batch con éxito!',
    modifyBatchPaymentSuccessfully: '¡Modificación de Pago Batch con éxito!',
    createBatchPaymentSuccessfully: '¡Creación de Pago Batch con éxito!',
    modifyItemMapSuccessfully: '¡Modificacón del mapa de Artículos con éxito!',
    createItemMapSuccessfully: '¡Creación del Mapa de Artículos con éxito!',
    modifyItemSuccessfully: '¡Modificación de Artículo con éxito!',
    createItemSuccessfully: '¡Creacíón de Artículo con éxito!',
    updateItemStatusSuccessfully: '¡Actualización del estado del Artículo con Éxito!',
    modifyPriceOfferSuccessfully: '¡Modificación de Oferta de Precios con éxito!',
    createPriceOfferSuccessfully: '¡Creación de Oferta de Precios con éxito!',
    updatePriceOfferStatusSuccessfully: '¡Actualización del Estado de la Oferta de Precios con Éxito!',
    modifyDiscountOfferSuccessfully: '¡Modificación de Oferta de Descuento con éxito!',
    createDiscountOfferSuccessfully: '¡Creación de Oferta de Descuento con éxito!',
    updateDiscountOfferStatusSuccessfully: '¡Actualización del Estado de la Oferta de Descuentos con Éxito!',
    modifyProductFamilySuccessfully: '¡Modificación de Familia de Productos con éxito!',
    createProductFamilySuccessfully: '¡Creación de Familia de Productos con éxito!',
    modifyItemServiceSuccessfully: '¡Modificación de Artículo de Servicio con éxito!',
    createItemServiceSuccessfully: '¡Creación de Artículo de Servicio con éxito!',
    modifyMilestoneSuccessfully: '¡Modificación de Milestone con éxito!',
    createMilestoneSuccessfully: '¡Creación de Milestone con éxito!',
    modifyExchangeRateSuccessfully: '¡Modificación de Tipo de Cambio con éxito!',
    createExchangeRateSuccessfully: '¡Creación de Tipo de Cambio con éxito!',
    modifyLocationSuccessfully: '¡Modificación de Ubicación con éxito!',
    createLocationSuccessfully: '¡Creación de Ubicación con éxito!',
    modifyEnterpriseSuccessfully: '¡Modificación de  Empresa con éxito!',
    createEnterpriseSuccessfully: '¡Creación de Empresa con éxito!',
    modifyDivisionSuccessfully: '¡Modificación de  División con éxito!',
    createDivisionSuccessfully: '¡Creación de División con éxito!',
    modifyLegalEntitySuccessfully: '¡Modificación de Entidad Legal con éxito!',
    createLegalEntitySuccessfully: '¡Creación de Entidad Legal con éxito!',
    modifyBusinessUnitSuccessfully: '¡Modificación de Unidad de Negocio con éxito!',
    createBusinessUnitSuccessfully: '¡Creación de Unidad de Negocio con éxito!',
    modifyGLAccountSuccessfully: '¡Modificación de Cuenta GL con éxito!',
    createGLAccountSuccessfully: '¡Creación de Cuenta GL con éxito!',
    modifyDepartmentSuccessfully: '¡Modificación de Departamento con éxito!',
    createDepartmentSuccessfully: '¡Creación de Departamento con éxito!',
    modifyCostCenterSuccessfully: '¡Modificación de Centro de Costo con éxito!',
    createCostCenterSuccessfully: '¡Creación de Centro de Costo con éxito!',
    modifyCalendarSuccessfully: '¡Modificacón de Calendario con éxito!',
    createCalendarSuccessfully: '¡Creación de Calendario con éxito!',
    modifyChartOfAccountSuccessfully: '¡Modificación de Catálogo de Cuentas con éxito!',
    createChartOfAccountSuccessfully: '¡Creación de Catálogo de Cuentas con éxito!',
    modifyAccountingPoliciesSuccessfully: '¡Modificación de Políticas Contables con éxito!',
    createAccountingPoliciesSuccessfully: '¡Creación de Políticas Contables con éxito!',
    modifyRevenueJournalSuccessfully: '¡Modificacón del Journal de Ingresos con éxito!',
    modifyTenantSuccessfully: '¡Modificación de Instancia con éxito!',
    createTenantSuccessfully: '¡Creación de Instancia con éxito!',
    modifyPropertiesSuccessfully: '¡Modificación de Propiedades con éxito!',
    modifyURLMappingSuccessfully: '¡Modificación de Mapeo de URL con éxito!',
    modifyPaymentGatewaySuccessfully: '¡Modificación del Gateway de Pagos con éxito!',
    createAPIMappingSuccessfully: '¡Creación de Mapeo de API con éxito!',
    modifyRequestResponseMappingSuccessfully: '¡Modificación de Mapeo de Solicitud/Respuesta con éxito.',
    modifySelfCareConfigSuccessfully: '¡Modificación de Configuración de Autoatención con éxito!',
    createSelfCareConfigSuccessfully: '¡Creación de Configuración de Autoatención con éxito!',
    modifyIdSequenceConfigSuccessfully: '¡Modificación de Configuración de Secuencia de Id con éxito!',
    createIdSequenceConfigSuccessfully: '¡Creación de Configuración de Secuencia de Id con éxito!',
    modifyRoleSuccessfully: '¡Modificación del Rol con éxito!',
    createRoleSuccessfully: '¡Creación del Rol con éxito!',
    modifyRoleGroupSuccessfully: '¡Modificación del Grupo de Roles con éxito!',
    createRoleGroupSuccessfully: '¡Creación de  Grupo de Roles con éxito!',
    modifyUserGroupSuccessfully: '¡Modificación de Grupo de Usuarios con éxito!',
    createUserGroupSuccessfully: '¡Creación de Grupo de Usuarios con éxito!',
    modifyApprovalSuccessfully: '¡Modificación de  Aprobación con éxito!',
    createApprovalSuccessfully: '¡Creación de Aprobación con éxito!',
    saveCostCenterMapSuccessfully: 'Guardado del Mapa del Centro de Costos con éxito!',
    reconcileDisputeSuccessfully: 'Conciliación de Disputas con éxito!',
    reconcileInvoiceSuccessfully: 'Conciliación de Facturas con éxito!',
    messageApprovalPendingOrder: 'Aprobación exitosa de Proyecto pendiente!',
    saveSplitOpportunity: 'Se ha guardado con éxito la División!',
    undoProcessSplitOrder: '¡El Proceso de Deshacer la División fue hecho con éxito!',
    saveAccountNonBillablesSuccessfully: '¡Cuenta de No Facturables Guardada con éxito!',
    moveAccountSuccessfully: '¡Cuenta Movida con Éxito!',
    createEmployeeSuccessfully: '¡Creación de Empleado Exitosa!',
    modifyClientSuccessfully: '¡Modificación de Empleado Exitosa!',
    createClientSuccessfully: '¡Creación de Cliente Exitosa!',
    deletePendingManualInvoiceSuccessfully: '¡Eliminación de Facturas Manuales Pendientes Exitosa!',
    saveManualInvoiceSuccessfully: '¡Guardado de Factura Manual Exitoso!',
    syncProjectAppSuccessfully: '¡Sincronización con la App de Proyectos  exitosa!',
    createRevenueJournalSuccessfully: '¡Creación del Journal de Ingresos Exitosa!',
    recognizeManualRevenueSuccessfully: '¡Reconocimiento de  Ingresos Manuales Exitoso!',
    resendEbsExtractSuccessfully: '¡Reenvío del Extracto de EBS exitoso!',
    saveReratingBatchSuccessfully: '¡El Batch de Retarificación fue Guardado con Éxito!',
    modifyReratingBatchSuccessfully: '¡El Batch de Retarificación fue Modificado exitosamente!',
    processSavedManualInvoiceSuccessfully: '¡Procesamiento de Factura Manual Guardada con éxito!',
    saveAccountMovementDataSuccessfully: '¡Guardado de los Datos de Movimiento de Cuenta con éxito!',
    deletePendingAccountMovementDataSuccessfully:
      '¡Eliminación de los Datos de Movimiento de Cuenta Pendientes con éxito!',
    modifyConfigUsagePlatformSuccessfully: '¡Modificación de la Configuración de la Plataforma de Uso Exitosa!',
    createConfigUsagePlatformSuccessfully: '¡Creación de la Configuración de la Plataforma de Uso Exitosa!',
    updateFileDetailRecordSuccessfully: '¡Actualización del Archivo de Registros Detallados  con Éxito!',
    initiateUsageReprocessSuccessfully: '¡El Reprocesamiento de Uso fue Iniciado con Éxito!',
    createTenantScheduleSucessfully: '¡Creación de Programación de Instancia exitosa!',
    resetSubsequentJobsFromErrorJobSucessfully: '¡Se Reestableció la  Programación  de trabajos  con éxito!',
    resendPaymentVendorSuccessfully: '¡Reenvío del Pago al Proveedor con Éxito!',
    resendNotesVendorSuccessfully: '¡Reenvío de Notas al Proveedor con Éxito!',
    deletePaymentFileSuccessfully: '¡Eliminación del Archivo de Pago Exitosa!',
    initiateReratingProcessSuccessfully: '¡Inicio de Retarificación de Uso Existosa!',
    folioSendEmailSuccessfully: 'El Folio fue enviado con éxito al Correo Electrónico !',
    saveOutputTemplateByTypeSuccessfully: '¡Plantilla de Salida Guardada con Éxito!',
    processSuccessfully: '¡Procesado Exitosamente!',
    createProvisioningAttributesConfigSuccessfully:
      '¡Creación de Configuración de Atributos de Aprovisionamiento Exitosa!',
    modifyProvisioningAttributesConfigSuccessfully:
      '¡Modificación de Configuración de Atributos de Aprovisionamiento Exitosa!',
    createSchedulePatternSuccessfully: '¡Creación de Patrón de Programación Existosa !',
    modifySchedulePatternSuccessfully: '¡Modificación de Patrón de Programación Existosa!',
    createAndProcessUsageRecordSuccessfully: '¡Creación y Procesamiento de Registros de Uso Exitosa!',
    createQuoteSuccessfully: '¡Creación de  Cotización Exitosa!',
    updateQuoteStatusSuccessfully: '¡Actualización del Estado de la Cotización Exitosa!',
    convertQuoteSuccessfully: '¡Conversión de la Cotización Exitosa!',
    associateQuoteToAccountSuccessfully: '¡Asociación de la Cotización con la Cuenta Exitosa!',
    syncTaskToExternalSuccessfully: '¡Sincronización de Tarea con Externo Exitosa!',
    modifyTaskSuccessfully: '¡Modificación de Tarea Exitosa!',
    createTaskSuccessfully: '¡Creación de Tarea Exitosa!',
    subscriptionTopUpSuccessfully: '¡Recarga de Suscripción Exitosa!',
    modifyPrepaidSystemOffersSuccessfully: '¡Modificación de Ofertas de Recarga Exitosa!',
    createPrepaidSystemOffersSuccessfully: '¡Creación de Ofertas de Recarga Exitosa!',
    createZoneUnitSuccessfully: '¡Creación  de Unidad de Zona Exitosa!',
    modifyZoneUnitSuccessfully: '¡Modificación de Unidad de Zona Exitosa!',
    modifyTaxTypeConfigSuccessfully: '¡Modificación de Tipo de Impuesto Exitosa!',
    createTaxTypeConfigSuccessfully: '¡Creación de Tipo de Impuesto Exitosa!',
    createCollectionProfilesSuccessfully: '¡Creación de Perfiles de Cobranza Exitosa!',
    modifyCollectionProfilesSuccessfully: '¡Modificación de Perfiles de Cobranza Exitosa!',
    setupMultiInvoicePaymentInstallmentSuccessfully:
      '¡Configuración del Pago en Parcialidades de Facturas Múltiples Exitosa!',
    createSharingGroupSuccessfully: '¡Creación de Grupo para Compartir Exitosa!',
    modifySharingGroupSuccessfully: '¡Creación de Grupo para Compartir Exitosa!',
    createRegulatoryProductCodesSuccessfully: 'Código de Producto Regulatorio,creado exitosamente!',
    modifyRegulatoryProductCodesSuccessfully: 'Modificación Exitosa de los Códigos de Producto Regulatorios!',
    deleteRegulatoryProductCodesSuccessfully: 'Eliminación Exitosa del Código de Producto Regulatorio!',
    createTemplateSuccessfully: 'Plantilla Creada Exitosamente!',
    modifyTemplateSuccessfully: 'Plantilla modicada Exitosamente!',
    modifyConfigPaymentAllocationSuccessfully: 'Modificación exitosa de la Configuración de Asignación de Pago!',
    createConfigPaymentAllocationSuccessfully: 'Creación exitosa de la Configuración de Asignación de Pago!',
    createExpensesConfigSuccessfully: 'Creación exitosa de la Configuración de Gastos!',
    modifyExpensesConfigSuccessfully: 'Modificación exitosa de la Configuración de Gastos!',
    createExpenseDataSuccessfully: 'Creación exitosa de los Datos de Gastos!',
    modifyExpenseDataSuccessfully: 'Modificación exitosa de los Datos de Gastos!',
    saveQuoteSuccessfully: 'Cotización guardada exitosamente!',
    createEmployeeBonusSuccessfully: 'Bono de empleado creado con éxito!',
    modifyEmployeeBonusSuccessfully: 'Bono de empleado modificado con éxito!',
    modifyFolioResponseSuccessfully: 'Folio modificado con éxito!',
    createFolioResponseSuccessfully: 'Folio creado con éxito!',
    removeItemSuccessfully: 'Item borrado con éxito!',
  },

  dropdown: {
    status: {
      ACTIVE: 'ACTIVO',
      INACTIVE: 'INACTIVO',
      CLOSED: 'CERRADO',
    },

    variableTypes: {
      VARCHAR: 'VARCHAR',
      BOOLEAN: 'BOOLEANO',
      NUMERIC: 'NUMÉRICO',
      INTEGER: 'ENTERO',
      ENUM: 'ENUM',
    },

    paymentTerm: {
      NETO_0: 'NETO_0',
      NETO_15: 'NETO_15',
      NET_30: 'NETO_30',
      NET_45: 'NETO_45',
      NET_60: 'NETO_60',
      NET_75: 'NETO_75',
      NET_90: 'NETO_90',
    },

    customerSegment: {
      B2B: 'B2B',
      B2C: 'B2C',
      B2B2C: 'B2B2C',
    },

    accountType: {
      DIRECT_CUSTOMER: 'CLIENTE_DIRECTO',
      PARTNER: 'ASOCIADO',
      PARTNER_CUSTOMER: 'CLIENTE_ASOCIADO',
      RESELLER: 'REVENDEDOR',
      RESELLER_CUSTOMER: 'CLIENTE_REVENDEDOR',
      INTERNAL_TEST_ACCOUNT: 'CUENTA_DE_PRUEBAS_INTERNA',
      EXTERNAL_TEST_ACCOUNT: 'CUENTA_DE_PRUEBAS_EXTERNA',
      BROKER_CHANNEL_PARTNER: 'BROKER_CANAL_ASOCIADO',
      DATA_SUPPLIER: 'PROVEEDOR_DE_DATOS',
      INTERCOMPANY: 'INTERCOMPAÑÍA',
      CONNECTOR_PARTNER: 'CONECTOR_ASOCIADO',
      AFFILIATE: 'AFILIADO',
      SALES_AGENT: 'AGENTE_VENTAS',
      SALES_CONSULTANT: 'VENTAS_CONSULTOR',
      CLIENT: 'CLIENTE',
      PARTY: 'PARTE',
      INTERCONNECT: 'INTERCONECTAR',
      NONE: 'NINGUNA',
      DISTRIBUTOR: 'DISTRIBUIDOR',
      CARRIER: 'CARRIER',
      ADMIN: 'ADMIN',
      EMPLOYEE: 'EMPLEADO',
      RESIDENTIAL: 'RESIDENCIAL',
      ENTERPRISE: 'EMPRESA',
      CORPORATE: 'CORPORATIVO',
    },

    salesChannel: {
      WEB: 'WEB',
      POS: 'POS',
      ECOMMERCE: 'COMERCIO_ELECTRÓNICO',
      ADVERTISEMENT: 'ANUNCIO',
      TELE_MARKET: 'TELE_MARKET',
      AGENT_CARE: 'AGENTE_ATENCIÓN',
      SELFCARE: 'AUTOATENCIÓN',
      NONE: 'NINGUNA',
    },

    marketSegment: {
      INDIVIDUAL: 'INDIVIDUAL',
      SMALL_ENTERPRISE: 'PEQUEÑA_EMPRESA',
      SMB: 'PYME',
      LARGE_ENTERPRISE: 'GRAN_EMPRESA',
      NONE: 'NINGUNA',
    },

    accountSubType: {
      SILVER: 'PLATA',
      GOLD: 'ORO',
      PLATINUM: 'PLATINO',
      NONE: 'NINGUNA',
    },

    reason: {
      PAYMENT_FAILURE: 'FALLO_EN_PAGO',
      CUSTOMER_REQUEST: 'SOLICITUD_DEL_CLIENTE',
      COLLECTIONS: 'COBRANZAS',
      INTERNAL_CONTROL: 'CONTROL_INTERNO',
      NONE: 'NINGUNA',
      JOB_TRIGGERED_HIERARCHY_CLOSURE: 'CIERRE_DE_JERARQUÍA_ACTIVADO_POR_TRABAJO',
    },

    phoneType: {
      WORK: 'TRABAJO',
      HOME: 'HOGAR',
      MOBILE: 'MÓVIL',
      FAX: 'FAX',
    },

    paymentMethod: {
      CHECK: 'CHEQUE',
      CREDIT_CARD: 'TARJETA_DE_CRÉDITO',
      NON_PAYING: 'NO_PAGADO',
      ECHECK: 'CHEQUE_ELECTRÓNICO',
      MANUAL: 'MANUAL',
    },

    paymentMethodAdmin: {
      MANUAL_PAYMENT: 'PAGO_MANUAL',
      CREDIT_CARD: 'TARJETA_DE_CRÉDITO',
    },

    billingFrequency: {
      MONTHLY: 'MENSUALMENTE',
      BI_MONTHLY: 'BIMESTRAL',
      QUARTERLY: 'TRIMESTRAL',
      SEMI_ANNUAL: 'SEMESTRAL',
      ANNUAL: 'ANUAL',
      DAYS: 'DÍAS',
    },

    serviceType: {
      DEFAULT: 'PREDETERMINADO',
      VOIP: 'VOIP',
      CABLE: 'CABLE',
      UTILITIES: 'SERVICIOS',
      RETAIL: 'MINORISTA',
      WHOLESALE: 'MAYOREO',
      WIRELINE: 'CONEXIÓN_POR_CABLE',
      TELEPHONY: 'TELEFONÍA',
      IPTV: 'IPTV',
      DATA: 'DATOS',
      PROFESSIONAL: 'PROFESIONAL',
      INTERNET: 'INTERNET',
      HOMESECURITY: 'SEGURIDAD_EN_CASA',
      VOICE: 'VOZ',
      SMS: 'SMS',
      MMS: 'MMS',
      EMAIL: 'CORREO_ELECTRÓNICO',
      EQUIPMENT: 'EQUIPO',
      DATACENTER: 'CENTRO_DE_DATOS',
      ALL: 'TODOS',
      LOGISTICS: 'LOGÍSTICA',
      TRANSPORT: 'TRANSPORTE',
      WATER: 'AGUA',
      ELECTRICITY: 'ELECTRICIDAD',
      SEWER: 'ALCANTARILLA',
      GAS: 'GAS',
    },

    arType: {
      BILL_ADJUSTMENT: 'AJUSTE_DE_FACTURA',
      TRANSACTION_ADJUSTMENT: 'AJUSTE_DE_TRANSACCIÓN',
      ACCOUNT_ADJUSTMENT: 'AJUSTE_DE_CUENTAS',
      INVOICE_LINE_ADJUSTMENT: 'AJUSTE_LÍNEA_DE_FACTURA',
      BILL_DISPUTE: 'DISPUTA_FACTURA',
      TRANSACTION_DISPUTE: 'DISPUTA_TRANSACCIÓN',
      BILL_SETTLEMENT: 'LIQUIDACIÓN_DE_FACTURA',
      ACCOUNT_WRITEOFF: 'CANCELACIÓN_DE_CUENTA',
      INVOICE_WRITEOFF: 'CANCELACIÓN_DE_FACTURA',
      TRANSACTION_SETTLEMENT: 'ACUERDO_DE_TRANSACCIÓN',
      WRITE_OFF: 'CANCELACIÓN',
      WRITE_OFF_REVERSAL: 'CANCELACIÓN_DE_REVERSIÓN',
      PAYMENT: 'PAGO',
      PAYMENT_REVERSAL: 'REVERSIÓN_DE_PAGO',
      REFUND: 'REEMBOLSO',
      CHARGEBACK: 'DEVOLUCIÓN',
      TAX: 'IMPUESTO',
      TAX_ADJUSTMENT: 'AJUSTE_DE_IMPUESTOS',
      TAX_SETTLEMENT: 'LIQUIDACIÓN_DE_IMPUESTOS',
      TAX_DISPUTE: 'DISPUTA_DE_IMPUESTOS',
      TAX_WRITEOFF: 'CANCELACIÓN_DE_IMPUESTOS',
      TAX_WRITEOFF_REVERSAL: 'REVERSIÓN_DE_LACANCELACIÓN_DE_IMPUESTOS',
      UNBILLED_LINE_ADJUSTMENT: 'AJUSTE_DE_LÍNEA_NO_FACTURADA',
    },

    types: {
      NEW: 'NUEVO',
      MODIFY: 'MODIFICAR',
      SUSPEND: 'SUSPENDER',
      RESUME: 'REANUDAR',
      CANCEL: 'CANCELAR',
      RENEW: 'RENOVAR',
      // OPT_IN: 'PARTICIPACIÓN',
      OPT_IN: 'ADHESIÓN',
      OPT_OUT: 'EXCLUSIÓN',
      UPGRADE: 'SUBIR_DE_CATEGORÍA',
      DOWNGRADE: 'BAJAR_DE_CATEGORÍA',
      TRIAL: 'PRUEBA',
      TRIAL_SERVICE: 'SERVICIO_PRUEBA',
      RELOCATION: 'REUBICACIÓN',
    },

    statusOrder: {
      CREATED: 'CREADO',
      PENDING: 'PENDIENTE',
      CANCELLED: 'CANCELADO',
      SUBMITTED: 'ENVIADO',
      APPROVED: 'APROBADO',
      PROCESSING: 'PROCESANDO',
      COMPLETED: 'COMPLETADO',
      FAILED: 'FALLIDO',
      PROVISIONING_INITIATED: 'APROVISIONAMIENTO_INICIADO',
      PROVISIONING_ERROR: 'ERROR_DE_APROVISIONAMIENTO',
      BILLING_ERROR: 'ERROR_EN_FACTURACIÓN',
      PROVISION_PROCESS: 'PROCESO_APROVISIONAMIENTO',
      PROVISIONED: 'APROVISIONADO',
      BILLED: 'FACTURADO',
    },

    initialTermUnit: {
      DAYS: 'DÍAS',
      MONTHS: 'MESES',
      NONE: 'NINGUNA',
      QUARTERS: 'TRIMESTRES',
      WEEKS: 'SEMANAS',
      YEARS: 'AÑOS',
    },

    recurringUnit: {
      MONTHS: 'MESES',
      QUARTERS: 'TRIMESTRES',
      YEARS: 'AÑOS',
      DAYS: 'DÍAS',
    },

    invoiceType: {
      SUMMARY: 'RESUMEN',
      DETAIL: 'DETALLE',
      DETAIL_SUPPRESS: 'SUPRIMIR_DETALLE',
    },

    invoiceDelivery: {
      DOWNLOAD: 'DESCARGAR',
      EMAIL: 'CORREO_ELECTRÓNICO',
      MAIL: 'CORREO',
    },

    timeUnit: {
      DAYS: 'DÍAS',
      WEEKS: 'SEMANAS',
      MONTHS: 'MESES',
      QUARTERS: 'TRIMESTRES',
      YEARS: 'AÑOS',
      UNBOUND: 'NO_VINCULADO',
    },

    statusPricing: {
      CREATE: 'CREAR',
      APPROVED: 'APROBADO',
      SELLABLE: 'VENDIBLE',
      NON_SELLABLE: 'NO_VENDIBLE',
      SUNSET: 'EXPIRACIÓN',
    },

    paymentWorkingDay: {
      NEXT_WORKING_DAY: 'SIGUIENTE_DÍA_HÁBIL',
      LAST_WORKING_DAY: 'ULTIMO_DÍA_DE_TRABAJO',
    },

    source: {
      EXTERNAL: 'EXTERNO',
      SELF_CARE: 'AUTO_ATENCIÓN',
      AGENT_CARE: 'AGENTE_ATENCIÓN',
      RERATING: 'RECALIFICACIÓN',
      PAYMENTS: 'PAGOS',
      PAYMENT_SUSPENSE: 'SUSPENSIÓN_DE_PAGO',
      INTERNAL_BATCH_JOBS: 'TRABAJOS_POR_LOTES_INTERNOS',
    },

    statusPayment: {
      OPEN: 'ABIERTO',
      CLOSED: 'CERRADO',
      REVERSED: 'REVOCADO',
      REVERSE_ALLOCATED: 'REVERSIÓN_ASIGNADA',
      // CANCELLATION_STAMPING_REQUESTED: 'CANCELACIÓN_DE_ESTAMPADO_SOLICITADA',
      // CANCELLATION_STAMPED: 'CANCELACIÓN_ESTAMPADA',
      // STAMPING_REQUESTED: 'ESTAMPADO_SOLICITADO',
      // STAMPED: 'ESTAMPADO',
      REVERSED_TO_SUSPENSE: 'REVERTIDO_A_SUSPENSO',
    },

    action: {
      FAILED_PAYMENT_EMAIL: 'EMAIL_PAGO_FALLIDO',
      FIRST_REMINDER_EMAIL: 'EMAIL_PRIMER_RECORDATORIO',
      SECOND_REMINDER_EMAIL: 'EMAIL_SEGUNDO_RECORDATORIO',
      THIRD_REMINDER_EMAIL: 'EMAIL_TERCER_RECORDATORIO',
      FIRST_PAYMENT_RETRY: 'PRIMER_REINTENTO_DE_PAGO',
      SECOND_PAYMENT_RETRY: 'SEGUNDO_REINTENTO_DE_PAGO',
      THIRD_PAYMENT_RETRY: 'TERCER_REINTENGO_DE_PAGO',
      INACTIVATE_SUBSCRIPTION: 'INACTIVAR_SUSCRIPCIÓN',
      WRITEOFF_ACCOUNT: 'CANCELACIÓN_CUENTA',
      CLOSE_ACCOUNT: 'CIERRE_DE_CUENTA',
    },

    statusCollections: {
      OPEN: 'ABIERTO',
      CLOSED: 'CERRADO',
      CUSTOMER_CONTACT_INITIATED: 'CONTACTO_CON_CLIENTE_INICIADO',
      CUSTOMER_CONTACT_ESTABLISHED: 'CONTACTO_CON_CLIENTE_ESTABLECIDO',
      PROMISE_TO_PAY: 'PROMESA_DE_PAGO',
      PROMISE_BROKEN: 'PROMESA_INCUMPLIDA',
      SUSPEND: 'SUSPENDER',
      WRITEOFF: 'CANCELACIÓN',
      GRACE_PERIOD_REQUESTED: 'PERIODO_DE_GRACIA_SOLICITADO',
    },

    reasonConfig: {
      SMALL_BALANCE_WRITE_OFF: 'AMORTIZAR_SALDO_PEQUEÑO',
      BAD_DEBT_WRITE_OFF: 'AMORTIZACIÓN_DEUDA_INCOBRABLE',
      CUSTOMER_SATISFACTION: 'SATISFACCIÓN_CLIENTE',
      RERATING: 'RECALIFICACIÓN',
      QUALITY_OF_SERVICE: 'CALIDAD_DE_SERVICIO',
      DATA_ERROR: 'ERROR_DE_DATOS',
      SETUP_ERROR: 'ERROR_DE_CONFIGURACIÓN',
      PRICING_ERROR: 'ERROR_DE_PRECIOS',
      PAYMENT: 'PAGO',
      PAYMENT_REVERSAL: 'REVERSIÓN_DE_PAGO',
    },

    CRDRType: {
      CREDIT: 'CRÉDITO',
      DEBIT: 'DÉBITO',
    },

    taxRule: {
      WITH_TAX: 'CON_IMPUESTO',
      WITHOUT_TAX: 'SIN_IMPUESTO',
      TAX_ONLY: 'SOLO_IMPUESTO',
    },

    disputeStatus: {
      OPEN: 'ABIERTO',
      CLOSED: 'CERRADO',
    },

    invoiceStatus: {
      ACTIVE: 'ACTIVO',
      CLOSED: 'CERRADO',
      COLLECTION: 'COBRANZA',
      // SENT: 'ENVIADO',
      PENDING_APPROVAL: 'APROBACIÓN_PENDIENTE',
      APPROVED: 'APROBADO',
      DISAPPROVED: 'DESAPROBAR',
      SYNC_FAILED: 'SINCRONIZACIÓN_FALLIDA',
      // SEND_FAILED: 'ENVÍO_FALLIDO',
      // STAMPING_REQUESTED: 'ESTAMPADO_SOLICITADO',
      // STAMPED: 'ESTAMPADO',
      // CANCELLATION_STAMPING_REQUESTED: 'CANCELACIÓN_DE_ESTAMPADO_SOLICITADA',
      // CANCELLATION_STAMPED: 'CANCELACIÓN_ESTAMPADA',
    },

    invoiceUnitType: {
      REGULAR: 'REGULAR',
      CORRECTIVE: 'CORRECTIVO',
      REGENERATED: 'REGENERADO',
    },

    permissionStatus: {
      PENDING: 'PENDIENTE',
      ACTIVE: 'ACTIVO',
      SUNSET: 'EXPIRACIÓN',
    },

    category: {
      AGENT: 'AGENTE',
      // AGENT: 'AGENT',
      CUSTOMER: 'CLIENTE',
      SYSTEM: 'SISTEMA',
      // SYSTEM: 'SYSTEM',
    },

    // jobTypes: {
    //   BILLING: 'FACTURACIÓN',
    //   INVOICING: 'FACTURAS',
    //   PAYMENT: 'PAGO',
    //   COLLECTION_CREATE: 'COLLECTION_CREATE',
    //   COLLECTION_ACTIONS: 'COLLECTION_ACTIONS',
    //   BILLING_NON_PAYING: 'BILLING_NON_PAYING',
    //   BILLING_CC: 'BILLING_CC',
    //   BILLING_CHECK: 'BILLING_CHECK',
    //   INVOICING_CC: 'INVOICING_CC',
    //   INVOICING_CHECK: 'INVOICING_CHECK',
    //   PAYMENT_CC: 'PAYMENT_CC',
    //   FUTURE_ORDERS: 'ORDENES_FUTURAS',
    //   FUTURE_PURCHASE: 'COMPRA_FUTURA',
    //   FUTURE_CANCEL: 'CANCELACIÓN_FUTURA',
    // },

    jobTypes: {
      COLLECTION_CREATE: 'CREAR_COBRANZA',
      COLLECTION_ACTIONS: 'ACCIONES_COBRANZA',
      BILL: 'FACTURACIÓN',
      BILL_NON_PAYING: 'FACTURACIÓN_NO_PAGADA',
      BILL_CC: 'FACTURACIÓN_T.C.',
      BILL_CHECK: 'FACTURACIÓN_CHEQUE',
      IN_ADVANCE_BILL: 'FACTURA_ANTICIPADA',
      IN_ADVANCE_BILL_NON_PAYING: 'FACTURACIÓN_ANTICIPADA_NO_PAGO',
      IN_ADVANCE_BILL_CC: 'FACTURACIÓN_ANTICIPADA_TC',
      IN_ADVANCE_BILL_CHECK: 'FACTURACIÓN_ANTICIPADA_CHEQUE',
      PENDING_BILL_TRIGGER_PARENT: 'DISPARADOR_FACTURACIÓN_PENDIENTE_PADRE',
      PENDING_BILL_TRIGGER_CHILD: 'DISPARADOR_FACTURACIÓN_PENDIENTE_HIJO',
      INVOICE: 'FACTURA',
      // INVOICE: 'INVOICE',
      INVOICE_NON_PAYING: 'FACTURA_NO_PAGO',
      INVOICE_CC: 'FACTURAS_T.C.',
      INVOICE_CHECK: 'FACTURAS_CHEQUE',
      PAYMENT_CC: 'PAGO_T.C.',
      FUTURE_ORDERS: 'ORDENES_FUTURAS',
      FUTURE_PURCHASE: 'COMPRA_FUTURA',
      FUTURE_CANCEL: 'CANCELACIÓN_FUTURA',
      CANCEL_SUBSCRIPTION: 'CANCELAR_SUSCRIPCIÓN',
      CANCEL_SERVICE: 'CANCELAR_SERVICIO',
      TRIAL_TO_PAID_SUBSCRIPTION: 'SUSCRIPCIÓN_DE_PRUEBA_A_SUCRIPCIÓN_PAGADA',
      TRIAL_TO_PAID_SERVICE: 'SERVICIOS_DE_PRUEBA_A_SERVICIO_PAGADO',
      RENEWAL_DUE_REMINDER: 'RECORDATORIO_DE_VENCIMIENTO_DE_RENOVACIÓN',
      CRDR_NOTES: 'NOTAS_DE_CRÉDITO/DÉBITO',
      INVOICE_TRANSFER: 'FACTURA_TRANSFERENCIA',
      INVOICE_DUE_REMINDER: 'RECORDATORIO_VENCIMIENTO_FACTURA',
      TRIAL_EXPIRY_REMINDER: 'RECORDATORIO_DE_EXPIRACIÓN_DE_PRUEBA',
      RECOGNIZE_REVENUE: 'RECONOCER_INGRESOS',
      MANUAL_BILLING_PARENT: 'FACTURACIÓN_MANUAL_PADRES',
      MANUAL_BILLING_CHILD: 'FACTURACIÓN_MANUAL_HIJOS',
      CLOSE_HIERARCHY_PARENT: 'CERRAR_JERARQUÍA_PADRES',
      CLOSE_HIERARCHY_CHILD: 'CERRAR_JERARQUÍA_HIJOS',
      BACKOUT_CREDIT_NOTES: 'RETIRAR_NOTAS_DE_CRÉDITO',
      TRIGGER_FUTURE_CYCLE: 'DISPARAR_CICLO_FUTURO',
      SEND_INVOICE_EMAIL: 'ENVIAR_CORREO_DE_FACTURA',
      FINANCE_SYNC_ACCOUNT: 'CUENTA_SINCRONIZACIÓN_FINANCIERA',
      FINANCE_SYNC_ACCOUNT_MODIFY: 'MODIFICAR_CUENTA_SINCRONIZACIÓN_FINANCIERA',
      FINANCE_SYNC_INVOICE: 'FACTURA_SINCRONIZACIÓN_FINANCIERA',
      FINANCE_SYNC_PAYMENT: 'PAGO_SINCRONIZACIÓN_FINANCIERA',
      FINANCE_SYNC_CREDIT_NOTES: 'NOTAS_DE_CRÉDITO_SINCRONIZACIÓN_FINANCIERA',
      FINANCE_SYNC_REVENUE: 'INGRESOS_SINCRONIZACIÓN_FINANCIERA',
      FINANCE_SYNC_AROPS: 'OPS_DE_CxC_SINCRONIZACIÓN_FINANCIERA',
      MANAGE_PAC_INVOICE: 'GESTIONAR_FACTURA_PAC',
      MANAGE_PAC_CREDITNOTE: 'GESTIONAR_NOTA_DE_CRÉDITO_PAC',
      EBS_EXTRACT: 'EXTRACTO_EBS',
      AGENT_COMMISSION: 'AGENTE_COMISIÓN',
      MOVE_ACCOUNT: 'MOVER_CUENTA',
      UPDATE_BILLING_ACCOUNT: 'ACTUALIZAR_CUENTA_DE_FACTURACIÓN',
      FUTURE_CANCEL_DISCOUNT: 'DESCUENTO_CANCELACIÓN_FUTURA',
      RERATING: 'RECALIFICACIÓN',
      SUSPEND_SUBSCRIPTION: 'SUSPENDER_SUSCRIPCIÓN',
      BILL_PREPAID_AS_POSTPAID_SUBS: 'FACTURA_PREPAGO_COMO_SUSCRIPCIÓN_POSTPAGO',
      PROCESS_CHARGE_SHARE: 'PROCESAR_CARGO_COMPARTIDO',
      BILL_SHARING_GROUP_OWNER: 'PROPIETARIO_DEL_GRUPO_DE_FACTURA_COMPARTIDA',
      SEND_GROUP_INVOICE: 'ENVIAR_FACTURA_DE_GRUPO',
      CREDIT_LIMIT_ACTIONS: 'ACCIONES_DE_LIMITE_DE_CREDITO',
    },

    roundingMethod: {
      UP: 'ARRIBA',
      DOWN: 'ABAJO',
      HALF_UP: 'MITAD_ARRIBA',
      HALF_DOWN: 'MITAD_ABAJO',
      NEAREST: 'MÁS_CERCANO',
    },

    selectBool: {
      TRUE: 'VERDADERO',
      FALSE: 'FALSO',
    },

    transactionType: {
      ONE_TIME: 'UNA_VEZ',
      ONE_TIME_CANCEL: 'CANCELAR_UNA_VEZ',
      // FORWARD_RECURRING: 'ADELANTOS_RECURRENTES',
      FORWARD_RECURRING: 'RECURRENTE_ANTICIPADO',
      ARREARS_RECURRING: 'RECURRENTE_VENCIDO',
      USAGE: 'USO',
      VOLUME_DISCOUNT: 'DESCUENTO_POR_VOLUMEN',
      BILL_DISCOUNT: 'DESCUENTO_FACTURA',
      TAX: 'IMPUESTO',
      MANUAL_TRANSACTION: 'TRANSACCIÓN_MANUAL',
      TRUE_UP: 'TRUE_UP (INGRESO COMPENSABLE)',
      NON_TAX: 'SIN_IMPUESTO',
      NONE: 'NINGUNA',
    },

    arCrDrType: {
      DEBIT: 'DÉBITO',
      CREDIT: 'CRÉDITO',
    },

    paymentTransactionType: {
      PAYMENT: 'PAGO',
      PAYMENT_REVERSAL: 'REVERSIÓN_DE_PAGO',
      PAYMENT_SUSPENSE: 'SUSPENSIÓN_DE_PAGO',
    },

    subscriptionReason: {
      NONE: 'NINGUNA',
      CUSTOMER_RELOCATION: 'REUBICACIÓN_CLIENTES',
      NOT_HAPPY: 'NO_CONTENTO',
      QOS: 'CALIDAD_DEL_SERVICIO',
      PRICING: 'PRECIOS',
      CREDIT_LIMIT_EXCEEDED: 'CREDIT_LIMIT_EXCEEDED',
    },

    jobStatus: {
      PENDING: 'PENDIENTE',
      COMPLETED: 'COMPLETADO',
      ERROR: 'ERROR',
      PROCESSING: 'PROCESANDO',
      REPROCESSING: 'REPROCESAMIENTO',
      // REPROCESSING: 'REPROCESSING',
      SUSPENDED: 'SUSPENDIDO',
      CANCELED: 'CANCELADO',
      FETCH: 'BÚSQUEDA',
    },

    trialLevel: {
      SUBSCRIPTION: 'SUSCRIPCIÓN',
      SERVICEUNIT: 'UNIDAD DE SERVICIO',
      // SERVICEUNIT: 'SERVICEUNIT',
    },

    trialTypes: {
      OPT_IN_TRIAL: 'PRUEBA_OPT_IN (Adhesión)',
      OPT_OUT_TRIAL: 'PRUEBA_OPT_OUT (Exclusión)',
    },

    trialTermUnit: {
      DAYS: 'DÍAS',
      WEEKS: 'SEMANAS',
      MONTHS: 'MESES',
      QUARTERS: 'TRIMESTRES',
      YEARS: 'AÑOS',
    },

    subscriptionStatus: {
      ACTIVE: 'ACTIVO',
      SUSPENDED: 'SUSPENDIDO',
      CLOSED: 'CERRADO',
    },

    suspenseReason: {
      ACCOUNT_NOT_FOUND: 'CUENTA_NO_ENCONTRADA',
      ACCOUNT_CLOSED: 'CUENTA_CERRADA',
      INVOICE_NOT_FOUND: 'FACTURA_NO_ENCONTRADA',
      PREDATES_ACCOUNT: 'CUENTA_ANTERIOR',
      PREDATES_INVOICE: 'FACTURA_PREVIA',
      INVALID_ACCOUNT: 'CUENTA_INVÁLIDA',
      NOT_A_BILLING_ACCOUNT: 'NO_ES_UNA_CUENTA_DE_FACTURACIÓN',
      STATEMENT_RECORD: 'REGISTRO_DE_ESTADO',
      UNDER_PAYMENT: 'PAGO_INFERIOR',
      OVER_PAYMENT: 'SOBRE_PAGO',
    },

    arLevel: {
      BILL: 'FACTURACIÓN',
      TRANSACTION: 'TRANSACCIÓN',
      ACCOUNT: 'CUENTA',
    },

    pricingModel: {
      FLAT: 'PRECIO_FIJO',
      TIERED: 'ESCALONADO',
      RECURRING: 'RECURRENTE',
      RECURRING_TIERED: 'RECURRENTE_ESCALONADO',
      TRANSACTION_ATTRIBUTE_BASED: 'TRANSACCIÓN_BASADA_EN_ATRIBUTO',
      CUSTOMER_ATTRIBUTE_BASED: 'CLIENTE_BASADO_EN_ATRIBUTO ',
      USAGE_ATTRIBUTE_BASED: 'USO_BASADO_EN_ATRIBUTO',
      COMPLEX: 'COMPLEJO',
      NONE: 'NINGUNA',
    },

    streamName: {
      PRE_PROCESSING_SVC: 'PRE_PROCESAMIENTO_SVC',
      POSTPAID_RATING_SVC: 'TASACIÓN_POSTPAGO_SVC',
      PREPAID_RATING_SVC: 'TASACIÓN_PREPAGO_SVC',
      SHARING_MODEL_SVC: 'MODELO_COMPARTIDO_SVC',
      SETTLEMENT_SVC: 'LIQUIDACION_SVC',
      POST_PROCESSING_SVC: 'POST_PROCESAMIENTO_SVC',
    },

    revenueRecognitionType: {
      IMMEDIATE: 'INMEDIATO',
      DEFERRED: 'DIFERIDO',
      REVENUE_ON_DEFERRAL_AUTO: 'INGRESO_EN_DIFERIMIENTO_AUTO',
      REVENUE_ON_DEFERRAL_MANUAL: 'INGRESO_EN_DIFERIMIENTO_MANUAL',
      DAILY_STRAIGHT_LINE_AMORTIZATION: 'AMORTIZACIÓN_LÍNEA_RECTA_DIARIA',
      MONTHLY_STRAIGHT_LINE_AMORTIZATION: 'AMORTIZACIÓN_LÍNEA_RECTA_MENSUAL',
      TRUE_UP: 'TRUE_UP (INGRESO COMPENSABLE)',
      MILESTONE: 'HITO',
    },

    revenueType: {
      TRANSACTION: 'TRANSACCIÓN',
      // TRANSACTION: 'TRANSACTION',
      SUBSCRIPTION: 'SUSCRIPCIÓN',
      PROFESSIONAL_SERVICES: 'SERVICIOS_PROFESIONALES',
      LABOR_EXPENSE: 'GASTO_LABORAL',
      LABOR_TIME: 'TIEMPO_LABORAL',
      LICENSE: 'LICENCIA',
      // EXPENSE: 'EXPENSE',
      // LIABILITY: 'LIABILITY',
      // RECEIVABLE: 'RECEIVABLE',
      // PAYABLE: 'PAYABLE',
    },

    prorationType: {
      FULL: 'LLENO',
      ZERO: 'CERO',
      PRORATE: 'PRORRATEAR',
    },

    timeUnitPricing: {
      WORKWEEK: 'SEMANA_DE_TRABAJO',
      WEEKEND: 'FIN_DE_SEMANA',
      PEAKHOURS: 'HORAS_PICO',
      OFFPEAKHOURS: 'HORAS_DE_POCA_ACTIVIDAD',
      HOLIDAYSPECIAL: 'DÍAFESTIVO_ESPECIAL',
      ANYTIME: 'EN_CUALQUIER_MOMENTO',
      NONE: 'NINGUNA',
    },

    tierReference: {
      QUANTITY: 'CANTIDAD',
      BALANCE: 'SALDO',
    },

    tierType: {
      PROGRESSIVE: 'PROGRESIVA',
      PROSPECTIVE: 'PROSPECTIVA',
    },

    volumeTreatment: {
      ACTUAL: 'REAL',
      AVERAGE: 'PROMEDIO',
      ANNUALIZE: 'ANUALIZAR',
    },

    dependencyType: {
      MUTUALLY_EXCLUSIVE: 'MUTUAMENTE_EXCLUYENTES',
      PRE_REQUISITE: 'PRE_REQUISITO',
      UPGRADE: 'SUBIR_DE_CATEGORÍA',
      DOWNGRADE: 'BAJAR_DE_CATEGORÍA',
    },

    recordExpression: {
      DETAIL: 'DETALLE',
    },

    fieldExpression: {
      startDate: 'fechaDeInicio',
      endDate: 'fechaFinal',
      processDate: 'fechaDeProcesamiento',
      quantity: 'cantidad',
      upload: 'Cargar',
      download: 'Descargar',
    },

    selectUnit: {
      ONE: 'UNO',
      KILOBYTE: 'KILOBYTE',
      MEGABYTE: 'MEGABYTE',
      GIGABYTE: 'GIGABYTE',
      SECOND: 'SEGUNDO',
      MINUTE: 'MINUTO',
      HOUR: 'HORA',
      NONE: 'NINGUNA',
    },

    statusRateUnit: {
      ACTIVE: 'ACTIVO',
      CLOSED: 'CERRADO',
    },

    statusTenant: {
      ACTIVE: 'ACTIVO',
      INACTIVE: 'INACTIVO',
    },

    dayOfWeek: {
      SUNDAY: 'DOMINGO',
      MONDAY: 'LUNES',
      TUESDAY: 'MARTES',
      WEDNESDAY: 'MIÉRCOLES',
      THURSDAY: 'JUEVES',
      FRIDAY: 'VIERNES',
      SATURDAY: 'SÁBADO',
    },

    mandatory: {
      Y: 'Y',
      N: 'N',
    },

    recordIndicator: {
      PREPAID: 'PREPAGO',
      POSTPAID: 'POSTPAGO',
      PRERATED: 'PRECALIFICADO',
      MANUAL_TRANSACTION: 'TRANSACCIÓN_MANUAL',
    },

    moduleName: {
      ACCOUNT_LOOKUP_MODULE: 'MÓDULO_BÚSQUEDA_DE_CUENTA',
      BALANCE_LOOKUP_MODULE: 'MÓDULO_BÚSQUEDA_DE_BALANCE',
      RATE_UNIT_MODULE: 'MÓDULO_UNIDAD_TARIFARIA',
      TIME_UNIT_MODULE: 'MÓDULO_UNIDAD_TIEMPO',
      ZONE_UNIT_MODULE: 'MÓDULO_UNIDAD_ZONA',
      IMPACT_UNIT_MODULE: 'MÓDULO_UNIDAD_IMPACTO',
      ROUTER_MODULE: 'MÓDULO_ENRUTADOR',
      PO_PRICE_UNIT_ANLYS_MODULE: 'MÓDULO_ANÁLISIS_PRECIO_UNITARIO_OC',
      PO_RATING_MODULE: 'MÓDULO_TASACIÓN_OC',
      PO_DISCOUNT_UNIT_ANLYS_MODULE: 'MÓDULO_ANÁLISIS_UNIDAD_DESCTO_OC',
      PO_DISCOUNTING_MODULE: 'MÓDULO_DESCUENTO_OC',
      PP_SESSION_MGMT_MODULE: 'MÓDULO_GESTIÓN_SESIÓN_PP',
      PP_BALANCE_MGMT_MODULE: 'MÓDULO_GESTIÓN_SALDOS_PP',
      PP_PRICE_UNIT_ANLYS_MODULE: 'MÓDULO_ANÁLISIS_UNIDAD_PRECIO_PP',
      PP_RATING_MODULE: 'MÓDULO_TASACIÓN_PP',
      PP_DISCOUNT_UNIT_ANLYS_MODULE: 'MÓDULO_ANÁLISIS_UNIDAD_DESCTO_PP',
      PP_DISCOUNTING_MODULE: 'MÓDULO_DESCUENTO_PP',
      REV_SHARE_LOOKUP_MODULE: 'MÓDULO_BÚSQUEDA_INGRESOS_COMPARTIDOS',
      REV_SHARE_MODULE: 'MÓDULO_INGRESOS_COMPARTIDOS',
      UPDATE_BALANCE_UNIT_MODULE: 'MÓDULO_ACTUALIZACIÓN_UNIDAD_SALDO',
      UPDATE_BILL_UNIT_MODULE: 'MÓDULO_ACTUALIZACIÓN_UNIDAD_DE_CUENTA',
      CREATE_TRANS_UNIT_MODULE: 'MÓDULO_CREAR_UNIDAD_TRANS',
      REVENUE_RECOGNITION_MODULE: 'MÓDULO_DE_RECONOCIMIENTO_DE_INGRESOS',
      DUPLICATE_CHECK: 'VERIFICACIÓN_DE_DUPLICADOS',
    },

    merchantName: {
      BRAINTREE: 'BRAINTREE',
      AVALARA: 'AVALARA',
      QUICKBOOKS: 'QUICKBOOKS',
      EMBRIX: 'EMBRIX',
      SFDC: 'SFDC',
      SERVICE_NOW: 'SERVICE_NOW',
      ZENDESK: 'ZENDESK',
      DEFAULT: 'PREDETERMINADO',
      PROJECT_APP: 'PROYECTO_APLICACIÓN',
      COMPUTEC: 'COMPUTEC',
      JARS: 'JARS',
      NOKIA: 'NOKIA',
      NETUP: 'NETUP',
      PORTAONE: 'PORTAONE',
      NOC: 'NOC',
      COOPEWEB: 'COOPEWEB',
      HIBOX: 'HIBOX',
      MOTV: 'MOTV',
    },

    taxGetWayType: {
      BASE_URL: 'URL_BASE',
      CALCULATE_TAX: 'CALCULAR_IMPUESTOS',
      CREATE_CONFIG: 'CREAR CONFIGURACIÓN',
      MODIFY_CONFIG: 'MODIFICAR_CONFIGURACIÓN',
      READ_CONFIG: 'LEER_CONFIGURACIÓN',
      ADDRESS_LOOKUP: 'BÚSQUEDA_DE_DIRECCIÓN',
    },

    authType: {
      HTTP_BASIC: 'HTTP_BÁSICO',
      JSON_WEB_TOKEN: 'TOKEN_WEB_JSON',
      OAUTH2: 'OAUTH2',
      API_KEY: 'API_KEY',
    },

    paymentGatewayType: {
      BATCH: 'BATCH / POR LOTES',
      AUTHORIZE: 'AUTORIZAR',
      CAPTURE: 'CAPTURAR',
      CREDIT: 'CRÉDITO',
      VOID: 'INVÁLIDO',
      BASE_URL: 'URL_BASE',
    },

    financeGatewayType: {
      BASE_URL: 'URL_BASE',
      MODIFY_COA: 'MODIFICAR_CATÁLOGO_DE_CUENTAS',
      MODIFY_JOURNAL: 'MODIFICAR_DIARIO',
      READ_COA: 'LEER_CATÁLOGO_DE_CUENTAS',
      RUN_REPORT: 'EJECUTAR_REPORTE',
      CREATE_COA: 'CREAR_CATÁLOGO_DE_CUENTAS',
      CREATE_JOURNAL: 'CREAR_DIARIO',
      CREATE_CUSTOMER: 'CREAR_CLIENTE',
      CREATE_INVOICE: 'CREAR_FACTURA',
      CREATE_PAYMENT: 'CREAR_PAGO',
      MODIFY_CUSTOMER: 'MODIFICAR_CLIENTE',
      CREATE_CREDIT_NOTES: 'CREAR_NOTAS_DE_CRÉDITO',
      CREATE_REVENUE: 'CREAR_INGRESOS',
      CREATE_AROPS: 'CREAR_OPSCxC',
      SEND_INVOICE: 'ENVIAR_FACTURA',
      SEND_ACCOUNTING_EXTRACT: 'ENVIAR_EXTRACTO_CONTABLE',
      GET_AUTHORIZATION_TOKEN: 'OBTENER_TOKEN_DE_AUTORIZACIÓN',
    },

    accountRole: {
      BILLING: 'FACTURACIÓN',
      PAYMENT: 'PAGO',
      SERVICE: 'SERVICIO',
      SOLD_TO: 'VENDIDO_A',
    },

    selfCareUserType: {
      NONE: 'NINGUNA',
      READ_ONLY: 'SOLO_LECTURA',
      READ_WRITE: 'LEER_ESCRIBIR',
    },

    dashboardType: {
      CUSTOMER_ONBOARDING: 'INCORPORACIÓN_DEL_CLIENTE',
      CUSTOMER_CHURN: 'ROTACIÓN_DE_CLIENTES',
      FAILED_ORDER: 'ORDEN_FALLIDA',
      ACTIVE_SUBSCRIPTIONS: 'SUSCRIPCIONES_ACTIVAS',
      TRIAL_TO_PAID_CONVERSION: 'CONVERSIÓN_DE_PRUEBA_A_SERVICIO_PAGADO',
      SUBSCRIPTION_CANCELLATIONS: 'CANCELACIÓN_DE_SUSCRIPCIONES',
      BILLED_REVENUE: 'INGRESOS_FACTURADOS',
      BILLED_CUSTOMER: 'CLIENTE_FACTURADO',
      PAYMENT_SUCCESS: 'PAGO EXITOSO',
      PAYMENT_SUSPENSE: 'SUSPENSIÓN_DE_PAGO',
      PAYMENT_FAILED: 'PAGO_FALLIDO',
      ACCOUNTS_WITH_FAILED_PAYMENT: 'CUENTAS_CON_PAGO_FALLIDO',
      COLLECTION_0_30_DAYS: 'COBRANZA_0_30_DÍAS',
      COLLECTION_30_60_DAYS: 'COBRANZA_30_60_DÍAS',
      COLLECTION_60_90_DAYS: 'COBRANZA_60_90_DÍAS',
      COLLECTION_OVER_90_DAYS: 'COBRANZA_MAS_DE_90_DÍAS',
      TOTAL_CREDIT_ADJUSTMENTS: 'AJUSTES_TOTALES_DE_CREDITO',
      TOTAL_DISPUTES: 'DISPUTAS_TOTALES',
      TOTAL_SETTLEMENTS: 'LIQUIDACIONES_TOTALES',
      TOTAL_REVENUE: 'TOTAL_INGRESOS',
      BILLS_FAILED: 'CUENTA_FALLIDA',
      INVOICES_FAILED: 'FACTURAS_FALLIDAS',
      JOBS_FAILED: 'TRABAJOS_FALLIDOS',
      SUBMITTED_ORDER: 'ORDEN_ENVIADA',
      USAGE_TRANSACTION: 'TRANSACCION_DE_USO',
      RATED_USAGE_TRANSACTION: 'TRANSACCIÓN_DE_USO_TASADA',
      TOTAL_JOBS: 'TOTAL_DE_TRABAJOS',
      RECOGNIZED_REVENUE: 'INGRESOS_RECONOCIDOS',
      TOTAL_WRITEOFF: 'TOTAL_CANCELACIONES',
      TOTAL_DEBIT_ADJUSTMENTS: 'TOTAL_DE_AJUSTES_DÉBITO',
      REVENUE_BY_PRODUCT_COMPANY: 'INGRESOS_POR_PRODUCTO_COMPAÑÍA',
      REVENUE_BY_REVENUE_TYPE: 'INGRESOS_POR_TIPO_DE_INGRESOS',
      CUSTOMER_COUNT_BY_ACCOUNT_TYPE: 'RECUENTO_DE_CLIENTES_POR_TIPO_DE_CUENTA',
      CUSTOMER_COUNT_BY_SELLING_COMPANY: 'RECUENTO_DE_CLIENTES_POR_COMPAÑÍA_VENDEDORA',
      REVENUE_FORECAST: 'PRONÓSTICO_DE_INGRESOS',
      MONTHLY_RECURRING_REVENUE: 'INGRESOS_MENSUALES_RECURRENTES',
      FOLIO_COUNT: 'NÚMERO_DE_FOLIOS',
    },

    entityType: {
      ORDER: 'ORDEN',
      CUSTOMER: 'CLIENTE',
      CONTACT: 'CONTACTO',
      ADDRESS: 'DIRECCIÓN',
      ITEM: 'ARTÍCULO',
      PAYMENT_PROFILE: 'PERFIL_PAGO',
      BILLING_PROFILE: 'PERFIL_DE_FACTURACIÓN',
      PRICE_OFFER: 'PRECIO_DE_OFERTA',
      BUNDLE: 'BUNDLE',
      PACKAGE: 'PAQUETE',
      DEPENDENCY: 'DEPENDENCIA',
      AROPS: 'CXCOPS',
      IDENTITY: 'IDENTIDAD',
      TAX: 'IMPUESTO',
      PAYMENT: 'PAGO',
      COLLECTION: 'COBRANZA',
      INTEGRATION: 'INTEGRACIÓN',
      PAYMENT_SUSPENSE: 'SUSPENSIÓN_DE_PAGO',
      PAYMENT_REVERSAL: 'REVERSIÓN_DE_PAGO',
      DISCOUNT_OFFER: 'OFERTA_DE_DESCUENTO',
      CUSTOM_ATTRIBUTES: 'ATRIBUTOS_PERSONALIZADOS',
      ACCOUNT_INFO: 'INFORMACIÓN_DE_LA_CUENTA',
      BILLING: 'FACTURACIÓN',
      PROVISIONING: 'APROVISIONAMIENTO',
      // PROVISIONING: 'PROVISIONING',
    },

    recurringType: {
      FORWARD: 'ANTICIPADO',
      ARREARS: 'MORA',
    },

    rateUnit: {
      EACH: 'CADA',
      DURATION: 'DURACIÓN',
      VOLUME: 'VOLUMEN',
      QOS: 'QOS',
      DOWNLINK: 'ENLACE_DESCENDENTE',
      UPLINK: 'ENLACE_ASCENDENTE',
      SIZE: 'TAMAÑO',
      QUANTITY: 'CANTIDAD',
      VOLUMEDOWNLOAD: 'VOLUMEN_DE_DESCARGA',
      VOLUMEUPLOAD: 'VOLUMEN_DE_CARGA',
      NONE: 'NINGUNA',
    },

    grantType: {
      // GRANT: 'GRANT',
      GRANT: 'SUBVENCIÓN',
      CONSUME: 'CONSUMIR',
    },

    monthOfYear: {
      Jan: 'Ene',
      Feb: 'Feb',
      Mar: 'Mar',
      Apr: 'Abr',
      May: 'Mayo',
      Jun: 'Jun',
      Jul: 'Jul',
      Aug: 'Ago',
      Sep: 'Sep',
      Oct: 'Oct',
      Nov: 'Nov',
      Dec: 'Dic',
    },

    attributeTypes: {
      VARCHAR: 'VARCHAR',
      INTEGER: 'ENTERO',
      BOOLEAN: 'BOOLEANO',
      DECIMAL: 'DECIMAL',
      DATE: 'FECHA',
      TIMESTAMP: 'MARCA_DE_TIEMPO',
    },

    txnTypeTenant: {
      PAYMENT: 'PAGOS',
      AUTHORIZE: 'AUTORIZAR',
      VOID: 'INVÁLIDO',
      REFUND: 'REEMBOLSO',
      CAPTURE: 'CAPTURAR',
      CREDIT: 'CRÉDITO',
      SETTLEMENT: 'ASENTAMIENTO',
      CHARGEBACK: 'DEVOLUCIÓN',
      PAYMENT_REVERSAL: 'REVERSIÓN_DE_PAGO',
      PAYMENT_WIDGET: 'WIDGET_DE_PAGO',
      RECONCILIATION: 'CONCILIACIÓN',
      REPORT: 'REPORTE',
    },

    gatewayName: {
      PAYMENT_GATEWAY: 'GATEWAY_DE_PAGOS',
      TAX_GATEWAY: 'GATEWAY_IMPUESTOS',
      FINANCE_GATEWAY: 'GATEWAY_FINANCIERO',
      PROVISIONING_GATEWAY: 'GATEWAY_DE_APROVISIONAMIENTO',
      CUSTOMER_SUPPORT_GATEWAY: 'GATEWAY_DE_SOPORTE_AL_CLIENTE',
      CRM_GATEWAY: 'GATEWAY_DE_CRM',
      DOCUMENT_GATEWAY: 'GATEWAY_DE_DOCUMENTOS',
      OPERATION_GATEWAY: 'GATEWAY_OPERACIONES',
      PROJECT_GATEWAY: 'GATEWAY_DE_PROYECTOS',
    },

    gatewayApi: {
      CALCULATE_TAX: 'CALCULAR_IMPUESTOS',
      CREATE_CONFIG: 'CREAR_CONFIGURACIÓN',
      MODIFY_CONFIG: 'MODIFICAR_CONFIGURACIÓN',
      READ_CONFIG: 'LEER_CONFIGURACIÓN',
      ADDRESS_LOOKUP: 'BÚSQUEDA_DE_DIRECCIÓN',
      BATCH_PAYMENT: 'PAGO_POR_LOTES',
      AUTHORIZE_CREDIT_CARD: 'AUTORIZAR_TARJETA_CRÉDITO',
      CAPTURE_CREDIT_CARD: 'CAPTURAR_TARJETA_DE_CRÉDITO',
      CREDIT_CREDIT_CARD: 'CRÉDITO_TARJETA_DE_CRÉDITO',
      VOID_CREDIT_CARD: 'TARJETA_DE_CRÉDITO_INVÁLIDA',
      AUTHORIZE_ECHECK: 'AUTORIZAR_CHEQUE_ELECTRÓNICO',
      CAPTURE_ECHECK: 'CAPTURAR_CHEQUE_ELECTRÓNICO',
      CREDIT_ECHECK: 'CRÉDITO_CHEQUE_ELECTRÓNICO',
      VOID_ECHECK: 'CHEQUE_ELECTRÓNICO_INVÁLIDO ',
      AUTHORIZE_ACH: 'AUTORIZAR_TRANSFERENCIAS_ELECTRÓNICAS',
      CAPTURE_ACH: 'CAPTURAR_TRANSFERENCIAS_ELECTRÓNICAS',
      CREDIT_ACH: 'CRÉDITO_TRANSFERENCIAS',
      VOID_ACH: 'TRANSFERENCIAS_INVÁLIDAS',
      CREATE_COA: 'CREAR_CATÁLOGO_DE_CUENTAS',
      MODIFY_COA: 'MODIFICAR_CATÁLOGO_DE_CUENTAS',
      READ_COA: 'LEER_CATÁLOGO_DE_CUENTAS',
      CREATE_JOURNAL: 'CREAR_DIARIO',
      MODIFY_JOURNAL: 'MODIFICAR_DIARIO',
      RUN_REPORT: 'EJECUTAR_REPORTE',
      CREATE_CUSTOMER: 'CREAR_CLIENTE',
      CREATE_INVOICE: 'CREAR_FACTURA',
      CREATE_PAYMENT: 'CREAR_PAGO',
      CREATE_CREDIT_NOTES: 'CREAR_NOTAS_DE_CRÉDITO',
      CREATE_REVENUE: 'CREAR_INGRESOS',
      CREATE_AROPS: 'CREAR_OPSCxC',
      SEND_INVOICE: 'ENVIAR_FACTURA',
      CREATE_ORGANIZATION: 'CREAR_ORGANIZACIÓN',
      CREATE_USER: 'CREAR_USUARIO',
      CREATE_ITEM: 'CREAR_ARTÍCULO',
      CREATE_ERP_SERVICE: 'CREAR_SERVICIO_ERP',
      CREATE_CLIENT: 'CREAR_CLIENTE',
      CREATE_ACCOUNT: 'CREAR_CUENTA',
      CREATE_POINTS: 'CREAR_PUNTOS',
      CREATE_PROVISIONING_ORDER: 'CREAR_ORDEN_DE_APROVISIONAMIENTO',
      CREATE_ORDER_SERVICE: 'CREAR_ORDEN_DE_SERVICIO',
      REMOVE_DID: 'ELIMINAR_DID',
      REMOVE_DID_LIST: 'ELIMINAR_LISTA_DID',
      UPDATE_DID: 'ACTUALIZAR_DID',
      UPDATE_DID_LIST: 'ACTUALIZAR_LISTA_DID',
      REMOVE_IP_ADDRESS: 'ELIMINAR_DIRECCIÓN_IP',
      UPDATE_IP_ADDRESS: 'ACTUALIZAR_DIRECCIÓN_IP',
      REMOVE_TRUNK: 'ELIMINAR_TRONCAL',
      UPDATE_TRUNK: 'ACTUALIZAR_TRONCAL',
      UPDATE_STATUS: 'ACTUALIZAR_ESTÁTUS',
      GET_SERVICE_STATUS: 'OBTENER_ESTATUS_SERVICIO',
      GENERATE_MOVE_ORDER: 'GENERAR_MOVE_ORDER',
      GET_ADMIN_STATUS: 'OBTENER_ESTATUS_ADMIN',
      GET_OPERATIVE_STATUS: 'OBTENER_ESTATUS_OPERATIVO',
      EMBRIX_ORDER: 'ORDEN_EMBRIX',
      PRICING_SYNC: 'SINCRONIZACIÓN_DE_PRECIOS',
      GET_ORDER_SUMMARY_BY_ACCOUNT_ID: 'OBTENER_RESUMEN_DE_ORDEN_POR_ID_DE_CUENTA',
      CREATE_XML_INVOICE: 'CREACIÓN_DE_FACTURA_XML',
      CREATE_STAMP_INVOICE: 'CREAR_FACTURA_ESTAMPADA',
      GET_PDF_INVOICE: 'OBTENER_PDF_DE_FACTURA',
      CREATE_XML_PAYMENT: 'CREAR_PAGO_XML',
      CREATE_STAMP_PAYMENT: 'CREAR_PAGO_ESTAMPADO',
      GET_PDF_PAYMENT: 'OBENER_PDF_DE_PAGO',
      UPDATE_OPERATIVE_STATUS: 'ACTUALIZAR_ESTÁTUS_OPERATIVO',
      UPDATE_ADMIN_STATUS: 'ACTUALIZAR_ESTÁTUS_ADMINISTRATIVO',
      UPDATE_OPERATIVE_ADMIN_STATUS: 'ACTUALIZAR_ESTÁTUS_OPERATIVO_ADMINISTRATIVO',
      APPLY_PAYMENT: 'APLICAR_PAGO',
      AUTHORIZE: 'AUTORIZAR',
      CANCEL_ORDER: 'CANCELAR_ORDEN',
      CAPTURE: 'CAPTURAR',
      CREATE_LADING_BILL: 'CREACIÓN_DE_CARTA_PORTE',
      CREATE_TICKET: 'CREAR_TICKET',
      CREATE_TRANSFER_DOCUMENT: 'CREACIÓN_DE_TRANSFERENCIA_DE_DOCUMENTO',
      CREDIT: 'CRÉDITO',
      GET_EXCHANGE_RATE: 'OBTENER_TIPOS_DE_CAMBIO',
      GET_CLIENT_SERVICES: 'OBTENER_SERVICIOS_CLIENTES',
      GET_REASONS_CODES: 'OBTENER_CÓDIGOS_DE_MOTIVOS',
      GET_ITEM_SERVICES: 'OBTENER_ARTICULOS_DE_SERVICIOS',
      GET_USAGE_ATTRIBUTES: 'OBTENER_ATRIBUTOS_DE_USO',
      GET_PROV_ATTR_BY_NAME: 'OBTENER_ATRIBUTOS_DE_APROVISIONAMIENTO_POR_NOMBRE',
      MANUAL_BILLING: 'FACTURACIÓN_MANUAL',
      MODIFY_CUSTOMER: 'MODIFICAR_CLIENTE',
      ORDER_APPROVAL: 'APROBACIÓN_ORDEN',
      UPDATE_ORGANIZATION: 'ACTUALIZAR_ORGANIZACIÓN',
      UPDATE_PROVISIONING_REQUEST: 'ACTUALIZAR_SOLICITUD_DE_APROVISIONAMIENTO',
      USER_LOGIN: 'INICIO_DE_SESIÓN_DE_USUARIO',
      VIEW_TICKET_BY_ORGANIZATION: 'VER_TICKET_POR_ORGANIZACIÓN',
      VOID: 'INVÁLIDO',
      CREATE_STAMP_RETENTION: 'CREACIÓN_DE_ESTAMPADO_DE_RETENCIONES',
      GET_ACCOUNTS_INVOICES: 'OBTENER_CUENTAS_FACTURAS',
      SEND_SMS_NOTIFICATION: 'ENVIAR_NOTIFICACIÓN_SMS',
      UPDATE_WORK_ORDER: 'ACTUALIZAR_ORDEN_DE_TRABAJO',
      UPDATE_PROVISIONING_IDENTIFIER: 'ACTUALIZAR_IDENTIFICADOR_DE_APROVISIONAMIENTO',
      UPDATE_ENTERPRISE_PROVISIONING_REQUEST: 'UPDATE_ENTERPRISE_PROVISIONING_REQUEST',
      REVERSE_PAYMENT: 'REVERSIÓN_DE_PAGO',
      PAYMENT_NOTIFICATION: 'NOTIFICACIÓN_DE_PAGO',
      PROCESS_ACCOUNT_AND_ORDER: 'PROCESAR_CUENTA_Y_ORDEN',
      GET_PROVISIONING_TEMPLATE: 'OBTENER_PLANTILLA_DE_APROVISIONAMIENTO',
      GET_SPACE_DATA: 'OBTENER_DATOS_DE_ESPACIO',
      GET_LOCATION_DATA: 'OBTENER_DATOS_DE_UBICACIÓN',
      CREATE_XML_CREDIT_NOTE: 'CREAR_XML_DE_NOTA_DE_CRÉDITO',
      CREATE_REPROVISIONING_ORDER: 'CREAR_ORDEN_DE_REAPROVISIONAMIENTO',
      CREATE_PROVISION_IPTV_ORDER: 'CREAR_APROVISIONAMIENTO_ORDEN_IPTV',
      CREATE_DEPROVISIONING_ORDER: 'CREAR_ORDEN_DE_DESPROVISIONAMIENTO',
      SEND_ACCOUNTING_EXTRACT: 'ENVIAR_EXTRACTO_CONTABLE',
      CREATE_MANUAL_SUSPEND_ORDER: 'CREAR_ORDEN_MANUAL_DE_SUSPENSION',
      CREATE_MANUAL_RESUME_ORDER: 'CREAR_ORDEN_MANUAL_DE_RECONEXION',
      CREATE_SUSPEND_RECONNECT_ORDER: 'CREATE_SUSPEND_RECONNECT_ORDER',
      GET_AUTHORIZATION_TOKEN: 'OBTENER_TOKEN_DE_AUTORIZACIÓN',
      USER_LOGOUT: 'CIERRE_DE_SESIÓN_DEL_USUARIO',
      CREATE_XML_DEBIT_NOTE: 'CREAR_NOTA_DE_DEBITO_XML',
      PREPAID_TOP_UP: 'PREPAID_TOP_UP',
      CREATE_REPROVISION_IPTV_ORDER: 'CREAR_ORDEN_REAPROVISIONAMIENTO_IPTV',
      CREATE_UTILITIES_ORDER_TRIGGER: 'CREATE_UTILITIES_ORDER_TRIGGER',
      GET_ACCOUNT_PROVISIONING_DATA: 'GET_ACCOUNT_PROVISIONING_DATA',
      GET_BALANCE: 'GET_BALANCE',
      GET_TOP_UP: 'GET_TOP_UP',
      MIGRATE_HISTORIC_BALANCES: 'MIGRATE_HISTORIC_BALANCES',
      PROCESS_FOLIO_STAMPING: 'PROCESS_FOLIO_STAMPING',
      PROCESS_ADJUSTMENT: 'PROCESS_ADJUSTMENT',
      UPDATE_OPTICAL_DATA: 'UPDATE_OPTICAL_DATA',
      UPDATE_PROVISIONING_TEMPLATE_DETAILS: 'UPDATE_PROVISIONING_TEMPLATE_DETAILS',
      SESSION_LOGIN: 'SESSION_LOGIN',
      CREATE_STB_PROFILE_ORDER: 'CREATE_STB_PROFILE_ORDER',
      CREATE_STB_PROVISIONING_ORDER: 'CREATE_STB_PROVISIONING_ORDER',
      CREATE_STB_DEPROVISIONING_ORDER: 'CREATE_STB_DEPROVISIONING_ORDER',
      UPDATE_STB_PROVISIONING_STATUS: 'UPDATE_STB_PROVISIONING_STATUS',
      CREATE_STB_ORDER_SERVICE: 'CREATE_STB_ORDER_SERVICE',
      DELETE_CUSTOMER: 'DELETE_CUSTOMER',
    },

    arTypeConfig: {
      PAYMENT: 'PAGOS',
      PAYMENT_REVERSAL: 'REVERSIÓN_DE_PAGO',
      CREDIT_ADJUSTMENT: 'AJUSTE_DE_CRÉDITO',
      DEBIT_ADJUSTMENT: 'AJUSTE_DE_DÉBITO',
      DISPUTE: 'DISPUTAS',
      SETTLEMENT: 'ASENTAMIENTO',
      WRITE_OFF: 'CANCELACIÓN',
      WRITE_OFF_REVERSAL: 'CANCELACIÓN_DE_REVERSIÓN',
      REFUND: 'REEMBOLSO',
      ACCOUNT_ADJUSTMENT: 'AJUSTE_DE_CUENTAS',
    },

    itemType: {
      REVENUE: 'INGRESOS',
      EXPENSE: 'GASTO',
      RECEIVABLE: 'POR_COBRAR',
      LIABILITY: 'RESPONSABILIDAD',
      PAYABLE: 'POR_PAGAR',
    },

    milestoneStatus: {
      PENDING: 'PENDIENTE',
      ACTIVE: 'ACTIVO',
      INACTIVE: 'INACTIVO',
      SUNSET: 'EXPIRACIÓN',
    },

    milestoneUnits: {
      DAYS: 'DÍAS',
      MONTHS: 'MESES',
      BILLCYCLES: 'CICLOS_DE_FACTURACIÓN',
    },

    usageRecStatus: {
      PENDING: 'PENDIENTE',
      PROCESSED: 'PROCESADO',
      SUSPENDED: 'SUSPENDIDO',
      FAILED: 'FALLIDO',
    },

    usageRecIndicator: {
      PREPAID: 'PREPAGO',
      POSTPAID: 'POSTPAGO',
      PRERATED: 'PRECALIFICADO',
      MANUAL_TRANSACTION: 'TRANSACCIÓN_MANUAL',
      PREPAID_AAA: 'PREPAID_AAA',
    },

    jurisdiction: {
      FEDERAL: 'FEDERAL',
      STATE: 'ESTADO',
      COUNTY: 'MUNICIPIO',
      CITY: 'CIUDAD',
      COUNTRY: 'PAÍS',
    },

    objectType: {
      ACCOUNT: 'CUENTA',
      BILLING: 'FACTURACIÓN',
      BUNDLE: 'BUNDLE',
      CUSTOMER_SUPPORT: 'SOPORTE_AL_CLIENTE',
      DOCUMENT: 'DOCUMENTO',
      FINANCE: 'FINANZAS',
      INVOICE: 'FACTURA',
      ITEM: 'ARTÍCULO',
      ITEM_SERVICE: 'SERVICIO_DE_ARTÍCULO',
      OPERATION: 'OPERACIÓN',
      ORDER: 'ORDEN',
      PACKAGE: 'PAQUETE',
      PAYMENT: 'PAGO',
      PRICE_OFFER: 'PRECIO_DE_OFERTA',
      PROJECT: 'PROYECTO',
      PROVISIONING_ATTRIBUTES: 'ATRIBUTOS_DE_APROVISIONAMIENTO',
      PROVISIONING_REQUEST: 'SOLICITUD_DE_APROVISIONAMIENTO',
      PROVISIONING_RESPONSE: 'RESPUESTA_DE_APROVISIONAMIENTO',
      REASON_CODES: 'CÓDIGOS_DE_MOTIVO',
      SERVICE: 'SERVICIO',
      TAX: 'IMPUESTO',
      USAGE: 'USO',
      ACCOUNT_AND_ORDER: 'CUENTA_Y_ORDEN',
      SUBSCRIPTION: 'SUSCRIPCIÓN',
      RATING: 'RECALIFICACIÓN',
    },

    exchangeRateType: {
      PEGGED: 'VINCULADO',
      FLOATING: 'FLOTANTE',
      FORWARD: 'ANTICIPADO',
      SPOT: 'CONTADO',
      DUAL: 'DUAL',
      CROSS: 'CRUZAR',
    },

    billUnitType: {
      REGULAR: 'REGULAR',
      ONE_TIME: 'UNA_VEZ',
      REGENERATED: 'REGENERADO',
      MANUAL_BILL: 'FACTURA_MANUAL',
      IN_ADVANCE_BILL: 'FACTURA_ANTICIPADA',
    },

    arOpsType: {
      BILL_ADJUSTMENT: 'AJUSTE_DE_FACTURA',
      TRANSACTION_ADJUSTMENT: 'AJUSTE_DE_TRANSACCIÓN',
      BILL_DISPUTE: 'DISPUTA_FACTURA ',
      TRANSACTION_DISPUTE: 'DISPUTA_TRANSACCIÓN',
      BILL_SETTLEMENT: 'LIQUIDACIÓN_DE_FACTURA',
      ACCOUNT_WRITEOFF: 'CANCELACIÓN_DE_CUENTA',
      INVOICE_WRITEOFF: 'CANCELACIÓN_DE_FACTURA',
      TRANSACTION_SETTLEMENT: 'ACUERDO_DE_TRANSACCIÓN',
      WRITE_OFF: 'CANCELACIÓN',
      WRITE_OFF_REVERSAL: 'CANCELACIÓN_DE_REVERSIÓN',
      PAYMENT_REVERSAL: 'REVERSIÓN_DE_PAGO',
      PAYMENT: 'PAGOS',
      REFUND: 'REEMBOLSO',
      CHARGEBACK: 'DEVOLUCIÓN',
      TAX: 'IMPUESTO',
      TAX_ADJUSTMENT: 'AJUSTE_DE_IMPUESTOS',
      TAX_SETTLEMENT: 'LIQUIDACIÓN_DE_IMPUESTOS',
      TAX_DISPUTE: 'DISPUTA_DE_IMPUESTOS',
      TAX_WRITEOFF: 'CANCELACIÓN_DE_IMPUESTOS',
      TAX_WRITEOFF_REVERSAL: 'REVERSIÓN_DE_LACANCELACIÓN_DE_IMPUESTOS',
    },

    trialStatus: {
      // OPT_IN: 'PARTICIPACIÓN',
      OPT_IN: 'ADHESIÓN',
      OPT_OUT: 'EXCLUSIÓN',
    },

    accountStatementLineType: {
      INVOICE: 'FACTURA',
      CREDIT_NOTE: 'NOTA_DE_CRÉDITO',
      DEBIT_NOTE: 'NOTA_DE_DÉBITO',
      PAYMENT: 'PAGO',
      PAYMENT_REVERSAL: 'REVERSIÓN_DE_PAGO',
      WRITE_OFF: 'CANCELACIÓN',
      WRITE_OFF_REVERSAL: 'CANCELACIÓN_DE_REVERSIÓN',
      CHARGEBACK: 'DEVOLUCIÓN',
      REFUND: 'REEMBOLSO',
      ADJUSTMENT: 'AJUSTES',
      SETTLEMENT: 'ASENTAMIENTO',
      DISPUTE: 'DISPUTAS',
      UNDO_CREDIT_NOTE: 'DESHACER_NOTA_DE_CRÉDITO',
      UNDO_DEBIT_NOTE: 'DESHACER_NOTA_DE_DÉBITO',
    },

    revenueConfigType: {
      GL_ACCOUNT_SEGMENT: 'SEGMENTO_DE_LA_CUENTA_DE_LIBRO_MAYOR',
      COMPANY_LOCATION: 'UBICACIÓN_DE_LA_COMPAÑÍA',
      ENTERPRISE: 'EMPRESA',
      DIVISIONS: 'DIVISIONES',
      LEGAL_ENTITY: 'ENTIDAD_LEGAL',
      BUSINESS_UNIT: 'UNIDAD_DE_NEGOCIO',
      DEPARTMENT: 'DEPARTAMENTO',
      COST_CENTER: 'CENTRO_DE_COSTOS',
      GENERAL_LEDGER: 'LIBRO_MAYOR',
      CHART_OF_ACCOUNT: 'CATÁLOGO_DE_CUENTAS',
    },

    billStatus: {
      PENDING: 'PENDIENTE',
      ACTIVE: 'ACTIVO',
    },

    typeActionBill: {
      APPROVE: 'APROBAR',
      DISAPPROVE: 'DESAPROBAR',
      REGENERATE_INVOICE: 'REGENRAR_FACTURA',
      EMAIL_INVOICE: 'EMAIL_FACTURA',
      UNDO_MANUAL_INVOICE: 'DESHACER_FACTURA_MANUAL',
      RESYNC_INVOICE: 'RESINCRONIZACIÓN_FACTURA',
      EXTEND_DUE_DATE: 'EXTENDER_FECHA_DE_VENCIMIENTO',
      GENERATE_INVOICE: 'GENERAR_FACTURA',
      RESEND_TO_VENDOR: 'REENVIAR_AL_PROVEEDOR',
      UNDO_INVOICE_CANCEL: 'DESHACER_CANCELACIÓN_FACTURA',
    },

    adjustmentARType: {
      BILL_ADJUSTMENT: 'AJUSTE_DE_FACTURA',
      TRANSACTION_ADJUSTMENT: 'AJUSTE_DE_TRANSACCIÓN',
      ACCOUNT_ADJUSTMENT: 'AJUSTE_DE_CUENTAS',
      INVOICE_LINE_ADJUSTMENT: 'AJUSTE_DE_LINEA_DE_FACTURA',
      UNBILLED_LINE_ADJUSTMENT: 'AJUSTE_DE_LÍNEA_NO_FACTURADA',
    },

    disputeARType: {
      BILL_DISPUTE: 'DISPUTA_FACTURA',
      TRANSACTION_DISPUTE: 'DISPUTA_TRANSACCIÓN',
    },

    writeOffARType: {
      ACCOUNT_WRITEOFF: 'CANCELACIÓN_DE_CUENTA',
      INVOICE_WRITEOFF: 'CANCELACIÓN_DE_FACTURA',
    },

    costType: {
      INCOME: 'INGRESOS',
      EXPENSES: 'GASTOS',
      INCOME_AND_EXPENSES: 'INGRESOS_Y_GASTOS',
    },

    calendarUnits: {
      WEEKS: 'SEMANAS',
      MONTHS: 'MESES',
      QUARTERS: 'TRIMESTRES',
    },

    calendarStatus: {
      PENDING: 'PENDIENTE',
      ACTIVE: 'ACTIVO',
      INACTIVE: 'INACTIVO',
    },

    GlAccountType: {
      ASSET: 'ACTIVO',
      EXPENSE: 'GASTO',
      LIABILITY: 'RESPONSABILIDAD',
      EQUITY: 'CAPITAL',
      NON_OPERATING_REVENUE: 'INGRESOS_NO_OPERATIVOS',
      NON_OPERATING_EXPENSE: 'GASTOS_NO_OPERATIVOS',
    },

    GlSegmentName: {
      COMPANY: 'EMPRESA',
      DEPARTMENT: 'DEPARTAMENTO',
      DIVISION: 'DIVISIONES',
      COST_CENTER: 'CENTRO_DE_COSTOS',
      ACCOUNT: 'CUENTA',
      SUB_ACCOUNT: 'SUB_CUENTA',
      INTERCOMPANY: 'INTERCOMPAÑÍA',
      LOCAL_USE: 'USO_LOCAL',
      MANAGEMENT: 'ADMINISTRACIÓN',
      FUTURE1: 'FUTURO1',
      FUTURE2: 'FUTURO2',
      FUTURE3: 'FUTURO3',
      FUTURE4: 'FUTURO4',
      FUTURE5: 'FUTURO5',
      FUTURE6: 'FUTURO6',
    },

    discountingModel: {
      FLAT: 'PRECIO_FIJO',
      TIERED: 'ESCALONADO',
      RECURRING: 'RECURRENTE',
      RECURRING_TIERED: 'RECURRENTE_ESCALONADO',
      TRANSACTION_ATTRIBUTE_BASED: 'TRANSACCIÓN_BASADA_EN_ATRIBUTO',
      CUSTOMER_ATTRIBUTE_BASED: 'CLIENTE_BASADO_EN_ATRIBUTO ',
      USAGE_ATTRIBUTE_BASED: 'USO_BASADO_EN_ATRIBUTO',
      COMPLEX: 'COMPLEJO',
    },

    transactionTypeDiscount: {
      ONE_TIME: 'UNA_VEZ',
      ONE_TIME_CANCEL: 'CANCELAR_UNA_VEZ',
      // FORWARD_RECURRING: 'FORWARD_RECURRING',
      FORWARD_RECURRING: 'RECURRENTE_ANTICIPADO',
      // ARREARS_RECURRING: 'ARREARS_RECURRING',
      ARREARS_RECURRING: 'RECURRENTE_VENCIDO',
      USAGE: 'USO',
      VOLUME_DISCOUNT: 'DESCUENTO_POR_VOLUMEN',
      DEFAULT: 'PREDETERMINADO',
      TAX: 'IMPUESTO',
    },

    noteStatus: {
      ACTIVE: 'ACTIVO',
      CLOSED: 'CERRADO',
      PENDING_APPROVAL: 'APROBACIÓN_PENDIENTE',
      APPROVED: 'APROBADO',
      DISAPPROVED: 'DESAPROBAR',
      SENT: 'ENVIADO',
      COLLECTION: 'COBRANZA',
      SYNC_FAILED: 'SINCRONIZACIÓN_FALLIDA',
      SEND_FAILED: 'ENVÍO_FALLIDO',
      // STAMPING_REQUESTED: 'ESTAMPADO_SOLICITADO',
      // STAMPED: 'ESTAMPADO',
      // CANCELLATION_STAMPING_REQUESTED: 'CANCELACIÓN_DE_ESTAMPADO_SOLICITADA',
      // CANCELLATION_STAMPED: 'CANCELACIÓN_ESTAMPADA',
    },

    arNotesType: {
      CREDIT_NOTE: 'NOTA_DE_CRÉDITO',
      DEBIT_NOTE: 'NOTA_DE_DÉBITO',
    },

    pricingType: {
      CUSTOMER: 'CLIENTE',
      SHARING: 'COMPARTIENDO',
      NONE: 'NINGUNA',
    },

    fileType: {
      IMAGE: 'IMAGEN',
      VIDEO: 'VIDEO',
    },

    categoryUploadFile: {
      TENANT: 'INSTANCIA',
      MIGRATION: 'MIGRACIÓN',
      INVOICE: 'FACTURA',
      CREDITNOTE: 'NOTA_DE_CRÉDITO',
      DEBITNOTE: 'NOTA_DE_DÉBITO',
      PAYMENT: 'PAHOS',
      USAGE: 'USO',
    },

    filePaymentType: {
      SANTANDER: 'SANTANDER',
      BANCOMER: 'BANCOMER',
      BANAMEX: 'BANAMEX',
      BANORTE: 'BANORTE',
      // BANAMEX_STATEMENT: 'BANAMEX_STATEMENT',
      // BANCOMER_AMEX: 'BANCOMER_AMEX',
    },

    paymentStatus: {
      PENDING: 'PENDIENTE',
      SUCCESS: 'ÉXITO',
      FAILED: 'FALLIDO',
      NOT_TO_BE_PROCESSED: 'NO_SER_PROCESADO',
      PROCESSED: 'PROCESADO',
      SUSPENDED: 'SUSPENDIDO',
    },

    omsTasks: {
      NONE: 'NINGUNA',
      PROVISION_ORDER: 'ORDEN_DE_APROVISIONAMIENTO',
      BILL_ORDER: 'ORDEN_DE_FACTURACIÓN',
      NOTIFY_ORDER: 'ORDEN_DE_NOTIFICACION',
      APPROVE_ORDER: 'APROBAR_ORDEN',
      PROJECT_APPROVAL: 'APROBACIÓN_DEL_PROYECTO',
      SPLIT_ORDER: 'DIVIDIR_ORDEN',
    },

    apiCategory: {
      ORDER: 'ORDEN',
      ACCOUNT: 'CUENTA',
      ITEM: 'ARTÍCULO',
    },

    commonStatus: {
      ACTIVE: 'ACTIVO',
      INACTIVE: 'INACTIVO',
    },

    openCloseStatus: {
      OPEN: 'ABIERTO',
      CLOSED: 'CERRADO',
    },

    arWriteoffStatus: {
      WRITTEN_OFF: 'DADOS_DE_BAJA',
      CLOSED: 'CERRADO',
    },

    provisionGatewayUrlType: {
      BASE_URL: 'URL_BASE',
      CREATE_ITEM: 'CREAR_ARTÍCULO',
      CREATE_ERP_SERVICE: 'CREAR_SERVICIO_ERP',
      CREATE_CLIENT: 'CREAR_CLIENTE',
      CREATE_ACCOUNT: 'CREAR_CUENTA',
      CREATE_POINTS: 'CREAR_PUNTOS',
      CREATE_PROVISIONING_ORDER: 'CREAR_ORDEN_DE_APROVISIONAMIENTO',
      CREATE_ORDER_SERVICE: 'CREAR_ORDEN_DE_SERVICIO',
      REMOVE_DID: 'ELIMINAR_DID',
      REMOVE_DID_LIST: 'ELIMINAR_LISTA_DID',
      UPDATE_DID: 'ACTUALIZAR_DID',
      UPDATE_DID_LIST: 'ACTUALIZAR_LISTA_DID',
      REMOVE_IP_ADDRESS: 'ELIMINAR_DIRECCIÓN_IP',
      UPDATE_IP_ADDRESS: 'ACTUALIZAR_DIRECCIÓN_IP',
      REMOVE_TRUNK: 'ELIMINAR_TRONCAL',
      UPDATE_TRUNK: 'ACTUALIZAR_TRONCAL',
      UPDATE_STATUS: 'ACTUALIZAR_ESTÁTUS',
      GET_SERVICE_STATUS: 'OBTENER_ESTATUS_SERVICIO',
      GENERATE_MOVE_ORDER: 'GENERAR_MOVE_ORDER',
      UPDATE_ADMIN_STATUS: 'ACTUALIZAR_ESTÁTUS_ADMINISTRATIVO',
      UPDATE_OPERATIVE_STATUS: 'ACTUALIZAR_ESTÁTUS_OPERATIVO',
      UPDATE_OPERATIVE_ADMIN_STATUS: 'ACTUALIZAR_ESTÁTUS_OPERATIVO_ADMINISTRATIVO',
      API_ENDPOINT_TEST: 'PRUEBA_ENDPOINT_API',
      GET_LOCATION_DATA: 'OBTENER_DATOS_DE_UBICACIÓN',
      GET_SPACE_DATA: 'OBTENER_DATOS_DE_ESPACIO',
      UPDATE_WORK_ORDER: 'ACTUALIZAR_ORDEN_DE_TRABAJO',
      CREATE_PROVISION_IPTV_ORDER: 'CREAR_APROVISIONAMIENTO_ORDEN_IPTV',
      UPDATE_PROVISIONING_REQUEST: 'ACTUALIZACIÓN_SOLICITUD_APROVISIONAMIENTO',
      UPDATE_ENTERPRISE_PROVISIONING_REQUEST: 'UPDATE_ENTERPRISE_PROVISIONING_REQUEST',
      UPDATE_PROVISIONING_IDENTIFIER: 'ACTUALIZAR_IDENTIFICADOR_DE_APROVISIONAMIENTO',
      CREATE_DEPROVISIONING_ORDER: 'CREAR_ORDEN_DE_DESPROVISIONAMIENTO',
      CREATE_REPROVISIONING_ORDER: 'CREAR_ORDEN_DE_REAPROVISIONAMIENTO',
      GET_PROVISIONING_TEMPLATE: 'OBTENER_PLANTILLA_DE_APROVISIONAMIENTO',
      CREATE_SUSPEND_RECONNECT_ORDER: 'CREATE_SUSPEND_RECONNECT_ORDER',
      GET_AUTHORIZATION_TOKEN: 'OBTENER_TOKEN_DE_AUTORIZACIÓN',
      CREATE_CUSTOMER: 'CREAR_CLIENTE',
      USER_LOGIN: 'INICIO_DE_SESIÓN_DE_USUARIO',
      USER_LOGOUT: 'CIERRE_DE_SESIÓN_DEL_USUARIO',
      CREATE_MANUAL_SUSPEND_ORDER: 'CREAR_ORDEN_MANUAL_DE_SUSPENSION',
      CREATE_MANUAL_RESUME_ORDER: 'CREAR_ORDEN_MANUAL_DE_RECONEXION',
      CREATE_REPROVISION_IPTV_ORDER: 'CREAR_ORDEN_REAPROVISIONAMIENTO_IPTV',
      CREATE_UTILITIES_ORDER_TRIGGER: 'CREATE_UTILITIES_ORDER_TRIGGER',
      UPDATE_PROVISIONING_TEMPLATE_DETAILS: 'UPDATE_PROVISIONING_TEMPLATE_DETAILS',
      GET_ACCOUNT_PROVISIONING_DATA: 'GET_ACCOUNT_PROVISIONING_DATA',
      UPDATE_OPTICAL_DATA: 'UPDATE_OPTICAL_DATA',
      SESSION_LOGIN: 'SESSION_LOGIN',
      CREATE_STB_PROFILE_ORDER: 'CREATE_STB_PROFILE_ORDER',
      CREATE_STB_PROVISIONING_ORDER: 'CREATE_STB_PROVISIONING_ORDER',
      CREATE_STB_DEPROVISIONING_ORDER: 'CREATE_STB_DEPROVISIONING_ORDER',
      UPDATE_STB_PROVISIONING_STATUS: 'UPDATE_STB_PROVISIONING_STATUS',
      CREATE_STB_ORDER_SERVICE: 'CREATE_STB_ORDER_SERVICE',
      DELETE_CUSTOMER: 'DELETE_CUSTOMER',
    },

    arAction: {
      ADJUSTMENT: 'AJUSTES',
      DISPUTE: 'DISPUTAS',
      WRITEOFF: 'CANCELACIÓN',
      PAYMENT: 'PAGO',
      PAYMENT_REVERSAL: 'REVERSIÓN_DE_PAGO',
      SETTLEMENT: 'ASENTAMIENTO',
      WRITEOFF_REVERSAL: 'REVERSIÓN_DE_CANCELACIÓN',
    },

    objectTypeApproval: {
      ITEM: 'ARTÍCULO',
      PRICE_OFFER: 'PRECIO_DE_OFERTA',
      DISCOUNT_OFFER: 'OFERTA_DE_DESCUENTO',
      BUNDLE: 'BUNDLE',
      PACKAGE: 'PAQUETE',
      INVOICE: 'FACTURA',
      CREDIT_NOTES: 'CREAR_NOTAS_DE_CRÉDITO',
      DEBIT_NOTES: 'CREAR_NOTAS_DE_DÉBITO',
      NOTES: 'NOTAS',
      PAYMENT: 'PAGO',
    },

    statusOrderLines: {
      CREATED: 'CREADO',
      EDITED: 'EDITADO',
      CANCELLED: 'CANCELADO',
      SUBMITTED: 'ENVIADO',
      PROCESSING: 'PROCESANDO',
      BILLED: 'FACTURADO',
      PROVISIONED: 'APROVISIONADO',
      COMPLETED: 'COMPLETADO',
      FAILED: 'FALLIDO',
      BILLING_INITIATED: 'FACTURACIÓN_INICIADA',
      BILLING_ERROR: 'ERROR_EN_FACTURACIÓN',
      PROVISIONING_INITIATED: 'APROVISIONAMIENTO_INICIADO',
      PROVISIONING_ERROR: 'ERROR_DE_APROVISIONAMIENTO',
      PROVISION_PROCESS: 'PROCESO_APROVISIONAMIENTO',
    },

    grantTypeConfig: {
      AMOUNT: 'MONTO',
      QUANTITY: 'CANTIDAD',
      COUNT: 'CONTEO',
    },

    creditNoteActions: {
      APPROVED: 'APROBADO',
      DISAPPROVED: 'DESAPROBAR',
      RESEND_TO_VENDOR: 'REENVIAR_AL_PROVEEDOR',
    },

    objectStatus: {
      ACTIVE: 'ACTIVO',
      PENDING: 'PENDIENTE',
      CLOSED: 'CERRADO',
    },

    flexAttribute: {
      FLEXATTR1: 'FLEXATTR1',
      FLEXATTR2: 'FLEXATTR2',
      FLEXATTR3: 'FLEXATTR3',
      FLEXATTR4: 'FLEXATTR4',
      FLEXATTR5: 'FLEXATTR5',
      FLEXATTR6: 'FLEXATTR6',
      FLEXATTR7: 'FLEXATTR7',
      FLEXATTR8: 'FLEXATTR8',
      FLEXATTR9: 'FLEXATTR9',
      FLEXATTR10: 'FLEXATTR10',
      FLEXATTR11: 'FLEXATTR11',
      FLEXATTR12: 'FLEXATTR12',
      FLEXATTR13: 'FLEXATTR13',
      FLEXATTR14: 'FLEXATTR14',
      FLEXATTR15: 'FLEXATTR15',
      FLEXATTR16: 'FLEXATTR16',
      FLEXATTR17: 'FLEXATTR17',
      FLEXATTR18: 'FLEXATTR18',
      FLEXATTR19: 'FLEXATTR19',
      FLEXATTR20: 'FLEXATTR20',
      FLEXATTR21: 'FLEXATTR21',
      FLEXATTR22: 'FLEXATTR22',
      FLEXATTR23: 'FLEXATTR23',
      FLEXATTR24: 'FLEXATTR24',
      FLEXATTR25: 'FLEXATTR25',
    },

    mandatoryFlag: {
      Y: 'Y',
      N: 'N',
    },

    orderAction: {
      ADD: 'AGREGAR',
      MODIFY: 'MODIFICAR',
      CANCEL: 'CANCELAR',
      SUSPEND: 'SUSPENDER',
      RESUME: 'REANUDAR',
      ALL: 'TODOS',
    },

    statusProvisioning: {
      ACTIVE: 'ACTIVO',
      CANCELLED: 'CANCELADO',
    },

    debitAccountType: {
      BILLED_REVENUE: 'INGRESO_FACTURADO',
      UNBILLED_REVENUE: 'INGRESO_SIN_FACTURAR',
      BILLED_EARNED_REVENUE: 'INGRESOS_DEVENGADOS_FACTURADOS',
      BILLED_UNEARNED_REVENUE: 'INGRESO_NO DEVENGADO_FACTURADO',
      UNBILLED_EARNED_REVENUE: 'INGRESO_DEVENGADO_SIN FACTURAR ',
      CASH: 'DINERO_EN_EFECTIVO',
      CASH_RESERVE: 'RESERVA_DE_EFECTIVO',
      RESERVES: 'RESERVAS',
      BAD_DEBT: 'DEUDA_INCOBRABLE',
      CURRENCY_EXCHANGE: 'CAMBIO_DE_DIVISAS',
      TAX: 'IMPUESTO',
      NON_TAX: 'SIN_IMPUESTO',
      BILLED_CUSTOMER: 'CLIENTE_FACTURADO',
      EXCHANGE_RATE_DELTA: 'DIFERENCIA_EN_TIPO_DE_CAMBIO',
      REALIZED_GAIN: 'GANANCIA_EFECTIVA',
      REALIZED_LOSS: 'PÉRDIDA_EFECTIVA',
      TAX_PAID: 'IMPUESTO_PAGADO',
      IEPS_INVOICED: 'IEPS_FACTURADO',
      IVA_INVOICED: 'IVA_FACTURADO',
      IEPS_PAID: 'IEPS_PAGADO',
      IVA_PAID: 'IVA_PAGADO',
    },

    accountingRulesObjectType: {
      INVOICE: 'FACTURA',
      CREDIT_NOTE: 'NOTA_DE_CRÉDITO',
      DEBIT_NOTE: 'NOTA_DE_DÉBITO',
      PAYMENT: 'PAGO',
      ADJUSTMENTS: 'AJUSTES',
      INVOICE_RECOGNITION: 'RECONOCIMIENTO_DE_FACTURA',
      WRITEOFF: 'CANCELAR',
      INVOICE_LINE_ADJUSTMENT: 'AJUSTE_DE_LINEA_DE_FACTURA',
      INVOICE_LINE_ADJUSTMENT_RECOGNITION: 'RECONOCIMIENTO_DE_AJUSTE_DE_LINEA_DE_FACTURA',
      EXCHANGE_RATE: 'TIPO_DE_CAMBIO',
      PAYMENT_RECEIPTS: 'COMPLEMENTOS_DE_PAGO',
      PAYMENT_RECEIPTS_REVERSAL: 'REVERSIÓN_COMPLEMENTO_DE_PAGO',
      PAYMENT_REVERSAL: 'REVERSIÓN_DE_PAGO',
      DISPUTE: 'DISPUTAS',
    },

    barChartDashboardType: {
      USAGE_DATA_BY_SERVICE_TYPE: 'DATOS_DE_USO_POR_TIPO_DE_SERVICIO ',
      USAGE_DATA_BY_CALL_TYPE: 'DATOS_DE_USO_POR_TIPO_DE_LLAMADA',
    },

    xaxisParameter: {
      OPERATOR: 'OPERADOR',
      ORGANIZATION: 'ORGANIZACIÓN',
      SERVICETYPE: 'TIPO_DE_SERVICIO',
      CALLTYPE: 'TIPO_DE_LLAMADA',
    },

    yaxisParameter: {
      COUNT: 'CONTEO',
      QUANTITY: 'CANTIDAD',
      AMOUNT: 'MONTO',
    },

    chargeType: {
      R: 'R',
      U: 'U',
      M: 'M',
    },

    batchPaymentType: {
      SANTANDER: 'SANTANDER',
      BANCOMER: 'BANCOMER',
      BANORTE: 'BANORTE',
      BANAMEX: 'BANAMEX',
      BANAMEX_STATEMENT: 'BANAMEX_EDO_DE_CUENTA',
      BANCOMER_AMEX: 'BANCOMER_AMEX',
    },

    provisioningLevel: {
      LINE: 'LÍNEA',
      // LINE: 'LINE',
      SERVICE: 'SERVICIO',
      ORDER: 'ORDEN',
    },

    orderSource: {
      INTERNAL: 'INTERNO',
      // INTERNAL: 'INTERNAL',
      EXTERNAL: 'EXTERNO',
      INTERNAL_BATCH_JOBS: 'TRABAJOS_BATCH_INTERNOS',
    },

    typeProvisioning: {
      TRUNK: 'TRONCAL',
      // TRUNK: 'TRUNK',
      DID: 'DID',
      IP: 'IP',
      DEVICE: 'DISPOSITIVO',
    },

    switchName: {
      SMS: 'SMS',
      DATA: 'DATOS',
      MET: 'MET',
      METII: 'METII',
      METITX: 'METITX',
      SSMETMX: 'SSMETMX',
      SSMET: 'SSMET',
      BROADSOFT: 'BROADSOFT',
      BROADSOFTMTY: 'BROADSOFTMTY',
      NEWSMS: 'NUEVO_SMS',
    },

    paymentAction: {
      ALLOCATE_PAYMENT: 'ASIGNAR_PAGO',
      REVERSE_PAYMENT: 'REVERSIÓN_DE_PAGO',
      RE_GENERATE_FOLIO: 'RE_GENERAR_FOLIO',
      // MANUAL_CANCELLATION_UPDATE: 'ACTUALIZACIÓN_CANCELACIÓN_MANUAL',
      // CANCEL_PAYMENT: 'CANCELAR_PAGO',
      RESEND_TO_VENDOR: 'REENVIAR_AL_PROVEEDOR',
    },

    paymentReversalReason: {
      INTERNAL_ERROR: 'ERROR_INTERNO',
      WRONG_ALLOCATION: 'ASIGNACIÓN_ERRÓNEA',
      CUSTOMER_REQUEST: 'SOLICITUD_DEL_CLIENTE',
      WRONG_ACCOUNT: 'CUENTA_EQUIVOCADA',
      WRONG_INVOICE: 'FACTURA_EQUIVOCADA',
    },

    notificationType: {
      PAYMENT_FAILURE: 'FALLO_EN_PAGO',
      FIRST_PAYMENT_REMINDER: 'PRIMER_RECORDATORIO_DE_PAGO',
      SECOND_PAYMENT_REMINDER: 'SEGUNDO_RECORDATORIO_DE_PAGO',
      THIRD_PAYMENT_REMINDER: 'TERCER_RECORDATORIO_DE_PAGO',
      PAYMENT_SUCCESS: 'PAGO_EXITOSO',
      INVOICE_DUE_REMINDER: 'RECORDATORIO_DE_VENCIMIENTO_DE_FACTURA',
      NEW_SUBSCRIPTION: 'NUEVA_SUSCRIPCIÓN',
      CANCEL_SUBSCRIPTION: 'CANCELAR_SUSCRIPCIÓN',
      RENEWAL_DUE: 'VENCIMIENTO_RENOVACIÓN',
      INVOICE_READY: 'FACTURA_LISTA',
      TRIAL_EXPIRY: 'VENCIMIENTO_DE_PRUEBA',
      TRIAL_EXPIRY_OPT_IN: 'VENCIMIENTO_DE_PRUEBA_OPT_IN',
      TRIAL_EXPIRY_OPT_OUT: 'VENCIMIENTO_DE_PRUEBA_OPT_OUT',
      CREDIT_LIMIT_BREACH: 'INCUMPLIMIENTO_DEL_LÍMITE_DE_CRÉDITO',
      CREDIT_THRESHOLD_BREACH: 'INCUMPLIMIENTO_DEL_UMBRAL_DE_CRÉDITO',
      JOB_SCHEDULE_ERROR: 'ERROR_DE_PROGRAMA_DE_TRABAJO',
      INVOICE_READY_FOR_APPROVAL: 'FACTURA_LISTA_PARA_APROBACIÓN',
      CREDIT_NOTE_APPROVAL: 'APROBACIÓN_NOTA_DE_CRÉDITO',
      USAGE_PROCESS_STATUS: 'ESTÁTUS_DEL_PROCESO_DE_USO',
      ADHOC: 'ADHOC',
      CREDIT_NOTE_READY: 'NOTA_DE_CRÉDITO_LISTA',
      SUSPEND_SUBSCRIPTION: 'SUSPENDER_SUSCRIPCIÓN',
      COLLECTION_ENTRY: 'ENTRADA_DE_COBRANZA',
      RESUME_SUBSCRIPTION: 'REANUDAR_SUSCRIPCIÓN',
      GROUP_INVOICE_READY: 'GROUP_INVOICE_READY',
      BALANCE_TOPUP: 'BALANCE_TOPUP',
      MONTH_END_BALANCE_UPDATE: 'MONTH_END_BALANCE_UPDATE',
      PREPAID_SUFFICIENT_CREDIT: 'PREPAID_SUFFICIENT_CREDIT',
      PREPAID_INSUFFICIENT_CREDIT: 'PREPAID_INSUFFICIENT_CREDIT',
      PREPAID_OVERAGE: 'PREPAID_OVERAGE',
    },

    planType: {
      LDI: 'LDI',
      LDN: 'LDN',
    },

    deltaPaymentSplitType: {
      WITH_THRESHOLD: 'CON_UMBRAL',
      WITHOUT_THRESHOLD: 'SIN_UMBRAL',
    },

    usageDirection: {
      IN: 'ENTRANTE',
      OUT: 'SALIENTE',
      INOUT: 'ENTRANTE-SALIENTE',
    },

    customDBSeqObjectType: {
      INVOICE_DB_SEQ: 'SECUENCIA_BD_FACTURAS',
      PAYMENT_DB_SEQ: 'SECUENCIA_BD_PAGOS',
      CREDIT_NOTE_DB_SEQ: 'SECUENCIA_BD_NOTAS_DE_CRÉDITO',
      DEBIT_NOTE_DB_SEQ: 'SECUENCIA_BD_NOTAS_DE_DÉBITO',
    },

    revenueActivityType: {
      PURCHASE: 'COMPRA',
      CANCEL: 'CANCELAR',
      BILLING: 'FACTURACIÓN',
      PAYMENT: 'PAGO',
      ADJUSTMENT: 'AJUSTES',
      WRITEOFF: 'CANCELACIÓN',
      MANUAL: 'MANUAL',
      USAGE: 'USO',
      PAYMENT_RECEIPTS: 'COMPLEMENTOS_DE_PAGO',
      PAYMENT_RECEIPTS_REVERSAL: 'REVERSIÓN_COMPLEMENTO_DE_PAGO',
    },

    revenueJournalStatus: {
      RECORDED: 'GRABADO',
      BILLED: 'FACTURADO',
      POSTED: 'PUBLICADO',
      PENDING_APPROVAL: 'APROBACIÓN_PENDIENTE',
      CANCELLED: 'CANCELADO',
      PENDING: 'PENDIENTE',
    },

    sourceSystem: {
      SSMETMX: 'SSMETMX',
      METITX: 'METITX',
      SSMET: 'SSMET',
      METII: 'METII',
      MET: 'MET',
      BROADSOFTMTY: 'BROADSOFTMTY',
      SMS: 'SMS',
      BROADSOFT: 'BROADSOFT',
    },

    ratingDescription: {
      NPA_RATE: 'TARIFA_NPA',
      BNUMBERPERFIX_RATE: 'TARIFA_PREFIJOBNUMBER',
    },

    activityAction: {
      CREATE: 'CREAR',
      MODIFY: 'MODIFICAR',
      DELETE: 'ELIMINAR',
      MERGE: 'FUSIONAR',
      DISPERSE: 'DISPERSAR',
      UNDO_MERGE: 'DESHACER_FUSIÓN',
      UNDO_DISPERSE: 'DESHACER_DISPERSIÓN',
    },

    gatewayType: {
      PAYMENT_GATEWAY: 'GATEWAY_DE_PAGOS',
      TAX_GATEWAY: 'GATEWAY_IMPUESTOS',
      FINANCE_GATEWAY: 'GATEWAY_FINANCIERO',
      CRM_GATEWAY: 'GATEWAY_DE_CRM',
      PROVISIONING_GATEWAY: 'GATEWAY_DE_APROVISIONAMIENTO',
      CUSTOMER_SUPPORT_GATEWAY: 'GATEWAY_DE_SOPORTE_AL_CLIENTE',
      DOCUMENT_GATEWAY: 'GATEWAY_DE_DOCUMENTOS',
      SUPPORT_GATEWAY: 'GATEWAY_DE_SOPORTE',
      SSO_GATEWAY: 'GATEWAY_SSO',
      USAGE_GATEWAY: 'GATEWAY_DE_USO',
      PROJECT_GATEWAY: 'GATEWAY_DE_PROYECTOS',
    },

    groupByType: {
      ACCOUNT: 'CUENTA',
      INVOICE: 'FACTURA',
      AGING_BUCKET: 'BALDE_ANTIGÜEDAD',
    },

    paymentReportCategory: {
      PAYMENT: 'PAGO',
      SUSPENSE: 'SUSPENSO',
      // SUSPENSE: 'SUSPENSE',
      ALL: 'TODOS',
    },

    operativeStatus: {
      DELIVERED: 'ENTREGADO',
      ASSIGNED: 'ASIGNADO',
      SUSPENDED: 'SUSPENDIDO',
      CANCELED: 'CANCELADO',
      CONFIGURED: 'CONFIGURADO',
      IMPLEMENTATION: 'IMPLEMENTACIÓN',
    },

    administrativeStatus: {
      ACTIVO: 'ACTIVO',
      SUSPENDIDO: 'SUSPENDIDO',
      CANCELADO: 'CANCELADO',
      EN_IMPLEMENTACION: 'EN_IMPLEMENTACION',
      REACTIVACION: 'REACTIVACION',
    },

    reportStatusItemService: {
      ACTIVE: 'ACTIVO',
      SUSPENDED: 'SUSPENDIDO',
      CANCELLED: 'CANCELADO',
      TECHNICAL_SUSPEND: 'SUSPENSIÓN_TÉCNICA',
    },

    revenueReportObjectType: {
      INVOICE: 'FACTURA',
      NOTES: 'NOTAS',
      ADJUSTMENT: 'AJUSTES',
      WRITEOFF: 'CANCELACIÓN',
      PAYMENT: 'PAGO',
    },

    ebsExtractStatus: {
      PENDING: 'PENDIENTE',
      SENT: 'ENVIADO',
      REGENERATED: 'REGENERADO',
      FAILED: 'FALLIDO',
      RESENT: 'REENVIADO',
    },

    ebsExtractType: {
      INVOICE: 'FACTURA',
      ADJUSTMENT: 'AJUSTES',
      CREDIT_NOTE: 'NOTA_DE_CRÉDITO',
      DEBIT_NOTE: 'NOTA_DE_DÉBITO',
      WRITE_OFF: 'CANCELACIÓN',
      PAYMENT: 'PAGO',
      INVOICE_RECOGNITION: 'RECONOCIMIENTO_DE_FACTURA',
      INVOICE_LINE_ADJUSTMENT: 'AJUSTE_LÍNEA_DE_FACTURA',
      INVOICE_LINE_ADJUSTMENT_RECOGNITION: 'RECONOCIMIENTO_DE_AJUSTE_DE_LINEA_DE_FACTURA',
      EXCHANGE_RATE: 'TIPO_DE_CAMBIO',
      PAYMENT_REVERSAL: 'REVERSIÓN_DE_PAGO',
      PAYMENT_RECEIPTS: 'COMPLEMENTOS_DE_PAGO',
      DISPUTE: 'DISPUTAS',
      ACCOUNT_RECEIVABLE: 'CUENTA_DE_INGRESOS',
      PAYMENT_RECEIPTS_REVERSAL: 'REVERSIÓN_COMPLEMENTO_DE_PAGO',
      TOP_UP: 'TOP_UP',
    },

    activitySource: {
      EXTERNAL: 'EXTERNO',
      SELF_CARE: 'AUTO_ATENCIÓN',
      AGENT_CARE: 'AGENTE_ATENCIÓN',
      CORE_JOBS: 'TRABAJOS_PRINCIPALES',
      PAYMENT: 'PAGO',
      PAYMENT_SUSPENSE: 'SUSPENSIÓN_DE_PAGO',
      INTERNAL_BATCH_JOBS: 'TRABAJOS_POR_LOTES_INTERNOS',
      MANUAL: 'MANUAL',
      INTERNAL: 'INTERNO',
      EMBRIX: 'EMBRIX',
      CORE_UI: 'INTERFAZ_DE_USUARIO_PRINCIPAL',
    },

    projectGatewayUrlType: {
      BASE_URL: 'URL_BASE',
      CREATE_CLIENT: 'CREAR_CLIENTE',
      CANCEL_ORDER: 'CANCELAR_ORDEN',
    },

    customerSupportGatewayUrlType: {
      BASE_URL: 'URL_BASE',
      CREATE_ORGANIZATION: 'CREAR_ORGANIZACIÓN',
      UPDATE_ORGANIZATION: 'ACTUALIZAR_ORGANIZACIÓN',
      CREATE_USER: 'CREAR_USUARIO',
      CREATE_TICKET: 'CREAR_TICKET',
      VIEW_TICKET_BY_ORGANIZATION: 'VER_TICKET_POR_ORGANIZACIÓN',
    },

    documentGatewayUrlType: {
      BASE_URL: 'URL_BASE',
      CREATE_XML_INVOICE: 'CREACIÓN_DE_FACTURA_XML',
      CREATE_STAMP_INVOICE: 'CREAR_FACTURA_ESTAMPADA',
      GET_PDF_INVOICE: 'OBTENER_PDF_DE_FACTURA',
      CREATE_XML_PAYMENT: 'CREAR_PAGO_XML',
      CREATE_STAMP_PAYMENT: 'CREAR_PAGO_ESTAMPADO',
      GET_PDF_PAYMENT: 'OBENER_PDF_DE_PAGO',
      CREATE_LADING_BILL: 'CREACIÓN_DE_CARTA_PORTE',
      CREATE_TRANSFER_DOCUMENT: 'CREACIÓN_DE_TRANSFERENCIA_DE_DOCUMENTO',
      CREATE_STAMP_RETENTION: 'CREACIÓN_DE_ESTAMPADO_DE_RETENCIONES',
      CREATE_XML_CREDIT_NOTE: 'CREAR_XML_DE_NOTA_DE_CRÉDITO',
      CREATE_XML_DEBIT_NOTE: 'CREAR_NOTA_DE_DEBITO_XML',
    },

    operationGatewayUrlType: {
      BASE_URL: 'URL_BASE',
      GET_EXCHANGE_RATE: 'OBTENER_TIPOS_DE_CAMBIO',
      SEND_SMS_NOTIFICATION: 'ENVIAR_NOTIFICACIÓN_SMS',
      GET_AUTHORIZATION_TOKEN: 'OBTENER_TOKEN_DE_AUTORIZACIÓN',
    },

    crmGatewayUrlType: {
      EMBRIX_ORDER: 'ORDEN_EMBRIX',
      CREATE_ACCOUNT: 'CREAR_CUENTA',
      GET_ORDER_SUMMARY_BY_ACCOUNT_ID: 'OBTENER_RESUMEN_DE_ORDEN_POR_ID_DE_CUENTA',
      ORDER_APPROVAL: 'APROBACIÓN_ORDEN',
      APPLY_PAYMENT: 'APLICAR_PAGO',
      MANUAL_BILLING: 'FACTURACIÓN_MANUAL',
      CREATE_LADING_BILL: 'CREACIÓN_DE_CARTA_PORTE',
      GET_CLIENT_SERVICES: 'OBTENER_SERVICIOS_CLIENTES',
      GET_REASONS_CODES: 'OBTENER_CÓDIGOS_DE_MOTIVOS',
      GET_ITEM_SERVICES: 'OBTENER_ARTICULOS_DE_SERVICIOS',
      GET_USAGE_ATTRIBUTES: 'OBTENER_ATRIBUTOS_DE_USO',
      GET_PROV_ATTR_BY_NAME: 'OBTENER_ATRIBUTOS_DE_APROVISIONAMIENTO_POR_NOMBRE',
      USER_LOGIN: 'INICIO_DE_SESIÓN_DE_USUARIO',
      GET_ACCOUNTS_INVOICES: 'OBTENER_CUENTAS_FACTURAS',
      PROCESS_ACCOUNT_AND_ORDER: 'PROCESAR_CUENTA_Y_ORDEN',
      PRICING_SYNC: 'SINCRONIZACIÓN_DE_PRECIOS',
      PAYMENT_NOTIFICATION: 'NOTIFICACIÓN_DE_PAGO',
      BASE_URL: 'URL_BASE',
      REVERSE_PAYMENT: 'REVERSIÓN_DE_PAGO',
      GET_AUTHORIZATION_TOKEN: 'OBTENER_TOKEN_DE_AUTORIZACIÓN',
      PREPAID_TOP_UP: 'PREPAID_TOP_UP',
      GET_BALANCE: 'GET_BALANCE',
      GET_TOP_UP: 'OBTENER_RECARGA',
      MIGRATE_HISTORIC_BALANCES: 'MIGRATE_HISTORIC_BALANCES',
      PROCESS_FOLIO_STAMPING: 'PROCESS_FOLIO_STAMPING',
      PROCESS_ADJUSTMENT: 'PROCESS_ADJUSTMENT',
    },

    switchNameRawFileData: {
      BROADSOFT: 'BROADSOFT',
      BROADSOFTMTY: 'BROADSOFTMTY',
    },

    switchNameRawNonBroadsoft: {
      MET: 'MET',
      METII: 'METII',
      METITX: 'METITX',
      SSMETMX: 'SSMETMX',
      SSMET: 'SSMET',
      DIDWW: 'DIDWW',
      TWILIO: 'TWILIO',
    },

    operatorFileStatus: {
      PENDING: 'PENDIENTE',
      PROCESSED: 'PROCESADO',
      RECONCILED: 'CONCILIADOS',
    },

    customAttributeCategory: {
      CUSTOMER: 'CLIENTE',
      ORDER: 'ORDEN',
      RATING: 'RECALIFICACIÓN',
      MEDIATION: 'MEDIACIÓN',
      BILLING: 'FACTURACIÓN',
      INVOICING: 'EMISIÓN_FACTURAS',
      REPORTS: 'REPORTES',
      PAYMENTS: 'PAGOS',
      TAXATION: 'IMPUESTOS',
      AR: 'CXC',
      COLLECTION: 'COBRANZA',
      REVENUE: 'INGRESOS',
      CORRESPONDENCE: 'CORRESPONDENCIA',
      EMBRIX_INTERNAL: 'EMBRIX_INTERNO',
      DEFAULT: 'PREDETERMINADO',
    },

    ladingType: {
      LADING_BILL: 'CARTA_PORTE',
      TRANSFER: 'TRANSFERIR',
    },

    grantUnit: {
      DAYS: 'DÍAS',
      WEEKS: 'SEMANAS',
      MONTHS: 'MESES',
      QUARTERS: 'TRIMESTRES',
      YEARS: 'AÑOS',
      FOREVER: 'SIEMPRE',
    },

    accumulatorUnit: {
      DAYS: 'DÍAS',
      WEEKS: 'SEMANAS',
      MONTHS: 'MESES',
      QUARTERS: 'TRIMESTRES',
      YEARS: 'AÑOS',
      FOREVER: 'SIEMPRE',
    },

    pacRecordStatus: {
      PENDING: 'PENDIENTE',
      SENT: 'ENVIADO',
      COMPLETE: 'COMPLETADO',
      CLOSED: 'CERRADO',
    },

    pacRecordType: {
      INVOICE: 'FACTURA',
      CREDITNOTE: 'NOTA_DE_CRÉDITO',
      PAYMENT: 'PAGO',
      TRANSFER_DOCUMENT: 'TRANSFERIR_DOCUMENTO',
      LADING_BILL: 'CARTA_PORTE',
    },

    pacExtractType: {
      REGULAR: 'REGULAR',
      CANCELLATION: 'CANCELACIÓN',
      SUBSTITUTION: 'SUSTITUCIÓN',
    },

    chargeTypeNoM: {
      R: 'R',
      U: 'U',
    },

    statusSaveAccountNonBillables: {
      ACTIVE: 'ACTIVO',
      CANCELLED: 'CANCELADO',
      SUSPENDED: 'SUSPENDIDO',
    },

    timeAndBillingObjectStatus: {
      ACTIVE: 'ACTIVO',
      ENDED: 'FINALIZADO',
    },

    clientPricingUnit: {
      HOUR: 'HORA',
      DAY: 'DÍA',
      MONTH: 'MES',
      QUARTER: 'TRIMESTRE',
      HALF_YEAR: 'MEDIO_AÑO',
      YEAR: 'AÑOS',
    },

    // END 3

    quarter: {
      1: 'TRIMESTRES 1',
      2: 'TRIMESTRES 2',
      3: 'TRIMESTRES 3',
      4: 'TRIMESTRES 4',
    },

    cutOffDOM: {
      '-2': 'USO',
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: '6',
      7: '7',
      8: '8',
      9: '9',
      10: '10',
      11: '11',
      12: '12',
      13: '13',
      14: '14',
      15: '15',
      16: '16',
      17: '17',
      18: '18',
      19: '19',
      20: '20',
      21: '21',
      22: '22',
      23: '23',
      24: '24',
      25: '25',
      26: '26',
      27: '27',
      28: '28',
      29: '29',
      30: '30',
      31: '31',
    },

    syncAPI: {
      SYNC_PROJECT: 'SINCRONIZAR_PROYECTO',
    },

    switchNameRawSmsFile: {
      SMS: 'SMS',
      NEWSMS: 'NUEVO_SMS',
    },

    matchStatus: {
      MATCH: 'COINCIDE',
      NOT_MATCH: 'NO_COINCIDE',
    },

    journalCreate: {
      CREATE_REV_REC: 'CREAR_REC_INGRESOS',
      COPY_TO_CREATE: 'COPIAR_PARA_CREAR',
    },

    longDistanceType: {
      N: 'N - Nacional',
      I: 'I - Internacional',
    },

    userType: {
      AGENT: 'AGENTE',
      ADI: 'ADI',
      AutoCuidado: 'AutoCuidado',
      'MCM Rol De Solo Lectura': 'MCM Rol De Solo Lectura',
      'Precios Leer Solamente': 'Precios Leer Solamente',
      'Administrador De Precios': 'Administrador De Precios',
      'Customer Care': 'Atención al Cliente',
    },

    ERPExtractsAction: {
      RE_EXTRACT_RE_SEND: 'RE_EXTRAER_RE_ENVIAR',
      RE_SEND: 'RE_ENVIAR',
    },

    manualInvoiceStatus: {
      PENDING: 'PENDIENTE',
      CLOSED: 'CERRADO',
      PROCESSING: 'PROCESANDO',
      ERRORED: 'CON_ERRORES',
    },

    mergeSelectOptions: {
      NEW_MERGE: 'NUEVA_FUSIÓN',
      UNDO_PREVIOUS_DISPERSE: 'DESHACER_DISPERSIÓN_PREVIA',
      SELECT_SAVED_MOVEMENT: 'SELECCIONAR_MOVIMIENTO_SALVADO',
    },

    reratingBatchStatus: {
      PENDING: 'PENDIENTE',
      CANCELLED: 'CANCELADO',
      PROCESSED: 'PROCESADO',
    },

    disperseSelectOptions: {
      NEW_DISPERSE: 'NUEVO_DISPERSAR',
      UNDO_PREVIOUS_DISPERSE: 'DESHACER_DISPERSIÓN_PREVIA',
      SELECT_SAVED_MOVEMENT: 'SELECCIONAR_MOVIMIENTO_SALVADO',
    },

    invoiceUnitStatus: {
      ACTIVE: 'ACTIVO',
      CLOSED: 'CERRADO',
      COLLECTION: 'COBRANZA',
      // SENT: 'ENVIADO',
      PENDING_APPROVAL: 'APROBACIÓN_PENDIENTE',
      APPROVED: 'APROBADO',
      DISAPPROVED: 'DESAPROBAR',
      SYNC_FAILED: 'SINCRONIZACIÓN_FALLIDA',
      // SEND_FAILED: 'ENVÍO_FALLIDO',
      // STAMPING_REQUESTED: 'ESTAMPADO_SOLICITADO',
      // STAMPED: 'ESTAMPADO',
      // CANCELLATION_STAMPING_REQUESTED: 'CANCELACIÓN_DE_ESTAMPADO_SOLICITADA',
      // CANCELLATION_STAMPED: 'CANCELACIÓN_ESTAMPADA',
    },

    notesCrDrType: {
      CR: 'CRÉDITO',
      DR: 'DÉBITO',
    },

    rerateReasonCodes: {
      PRICING_CHANGE: 'CAMBIO_DE_PRECIOS',
      WRONG_ORDER: 'ORDEN_INCORRECTA',
      CUSTOMER_REQUEST: 'SOLICITUD_DEL_CLIENTE',
      PROVISIONING_ERROR: 'ERROR_DE_APROVISIONAMIENTO',
      SOURCE_USAGE_DATA_ERROR: 'ERROR_EN_DATOS_FUENTE_DE_USO ',
      INTERNAL_RATING_ERROR: 'ERROR_EN_TARIFICACIÓN_INTERNA',
    },

    revenueObjectType: {
      INVOICE: 'FACTURA',
      CREDIT_NOTE: 'NOTA_DE_CRÉDITO',
      DEBIT_NOTE: 'NOTA_DE_DÉBITO',
      ADJUSTMENT: 'AJUSTES',
      WRITEOFF: 'CANCELACIÓN',
      PAYMENT: 'PAGO',
    },

    interfaceType: {
      FILE: 'ARCHIVO',
      API: 'API',
      QUEUE: 'QUEUE',
      DB_QUERY: 'CONSULTA_BASE_DE_DATOS',
    },

    itemServiceStatus: {
      CREATE: 'CREAR',
      APPROVED: 'APROBADO',
      SELLABLE: 'VENDIBLE',
      NON_SELLABLE: 'NO_VENDIBLE',
      SUNSET: 'EXPIRACIÓN',
    },

    documentType: {
      INVOICE: 'FACTURA',
      TRANSFER: 'TRANSFERIR',
      LADING_BILL: 'CARTA_PORTE',
      CREDIT_NOTE: 'NOTA_DE_CRÉDITO',
      DEBIT_NOTE: 'NOTA_DE_DÉBITO',
      PAYMENT: 'PAGO',
    },

    satCode: {
      '01': '01: Comprobante Emitido Con Errores Con Relación',
      '02': '02: Comprobante Emitido Con Errores Sin Relación',
      '03': '03: No Se Llevó a Cabo La Operación',
    },

    trafficType: {
      TERMINATION: 'TERMINACIÓN',
      TRANSIT: 'TRÁNSITO',
    },

    batchPaymentStatus: {
      PENDING: 'PENDIENTE',
      SUCCESS: 'ÉXITO',
      FAILED: 'FALLIDO',
      NOT_TO_BE_PROCESSED: 'NO_SER_PROCESADO',
      PROCESSED: 'PROCESADO',
      SUSPENDED: 'SUSPENDIDO',
    },

    batchPaymentFileStatus: {
      PENDING_LOAD: 'CARGA_PENDIENTE',
      LOADED: 'CARGADO',
      PROCESSING: 'PROCESANDO',
      PROCESSED: 'PROCESADO',
    },

    monthOfYearFull: {
      January: 'Enero',
      February: 'Febrero',
      March: 'Marzo',
      April: 'Abril',
      May: 'Mayo',
      June: 'Junio',
      July: 'Julio',
      August: 'Agosto',
      September: 'Septiembre',
      October: 'Octubre',
      November: 'Noviembre',
      December: 'Diciembre',
    },

    paymentSuspenseStatus: {
      CLOSED: 'CERRADO',
      OPEN: 'ABIERTO',
    },

    paymentActionHistory: {
      ALLOCATE_PAYMENT: 'ASIGNAR_PAGO',
      AUTO_ALLOCATE: 'AUTO_APLICAR',
      REVERSE_PAYMENT: 'REVERSIÓN_DE_PAGO',
      EMAIL_FOLIO: 'FOLIO_DEL_EMAIL',
      RESEND_TO_VENDOR: 'REENVIAR_AL_PROVEEDOR',
    },

    collectionStatus: {
      CLOSED: 'CERRADO',
      CUSTOMER_CONTACT_INITIATED: 'CONTACTO_CON_CLIENTE_INICIADO',
      CUSTOMER_CONTACT_ESTABLISHED: 'CONTACTO_CON_CLIENTE_ESTABLECIDO',
      GRACE_PERIOD_REQUESTED: 'PERIODO_DE_GRACIA_SOLICITADO',
      OPEN: 'ABIERTO',
      PROMISE_TO_PAY: 'PROMESA_DE_PAGO',
      PROMISE_BROKEN: 'PROMESA_INCUMPLIDA',
      SUSPEND: 'SUSPENDER',
      WRITEOFF: 'CANCELACIÓN',
    },

    folioStatus: {
      STAMPING_REQUESTED: 'ESTAMPADO_SOLICITADO',
      STAMPED: 'ESTAMPADO',
      CANCELLATION_STAMPING_REQUESTED: 'CANCELACIÓN_DE_ESTAMPADO_SOLICITADA',
      CANCELLATION_STAMPED: 'CANCELACIÓN_ESTAMPADA',
      AWAITING_CANCELLATION_STAMPING: 'EN_ESPERA_DE_CANCELACIÓN_DEL_ESTAMPADO',
    },

    commitmentTerm: {
      LATEST_TERM: 'ÚLTIMO_PLAZO',
      PREVIOUS_TERM: 'PLAZO_ANTERIOR',
    },

    installmentStatus: {
      PAID: 'PAGADO',
      PENDING: 'PENDIENTE',
    },

    collectionReportGroupByType: {
      ACCOUNT: 'CUENTA',
      INVOICE: 'FACTURA',
      AGING_BUCKET: 'BALDE_ANTIGÜEDAD',
      ACCOUNT_CATEGORY: 'CATEGORÍA_DE_CUENTA',
    },

    paymentAgeingReportGroupByType: {
      ACCOUNT: 'CUENTA',
      AGING_BUCKET: 'BALDE_ANTIGÜEDAD',
      PAYMENT: 'PAGO',
      ACCOUNT_CATEGORY: 'CATEGORÍA_DE_CUENTA',
    },

    paymentActionSuspense: {
      PROCESS_SUSPENSE: 'PROCESAR_SUSPENSO',
      CLOSE_SUSPENSE: 'CERRAR_SUSPENSO',
      MODIFY_SUSPENSE: 'MODIFICAR_SUSPENSO',
    },

    closeSuspenseReason: {
      'IGNORE AS INVALID PAYMENT': 'IGNORAR COMO PAGO INVÁLIDO',
      'CUSTOMER REFUND': 'DEVOLUCIONES A CLIENTES',
    },

    planStatus: {
      ACTIVE: 'ACTIVO',
      INACTIVE: 'INACTIVO',
    },

    errorCodeProcessService: {
      NO_SERVICE_FOR_THE_PROVISIONING_ID: 'NO_HAY_SERVICIO_PARA_EL_ID_DE_APROVISIONAMIENTO',
      NO_MATCHING_PRICING_FOR_USAGE_RECORD: 'NO_HAY_COINCIDENCIA_DE_PRECIO_PARA_EL_REGISTRO_DE_USO',
      NO_MATCHING_OPERATOR_PRICING_FOR_USAGE_RECORD:
        'NO_HAY_COINCIDENCIA_DE_PRECIO_DE OPERADOR_PARA_EL_REGISTRO_DE_USO',
      NO_MATCHING_BILLABLE_SVC_PRICING_FOR_USAGE_RECORD:
        'NO_HAY_COINCIDENCIA_DE_PRECIO_DE_SERVICIO_FACTURABLE_PARA_EL_REGISTRO_DE_USO',
      NO_ACTIVE_SERVICE_FOR_THE_PROVISIONING_ID: 'NO_HAY_SERVICIO_ACTIVO_PARA_EL_ID_DE_APROVISIONAMIENTO',
      NO_ACTIVE_PRICE_UNIT_FOR_THE_SERVICE: 'NO_HAY_PRECIO_UNITARIO_ACTIVO_PARA_EL_SERVICIO',
      NO_MATCHING_PRICE_UNIT_FOR_THE_SERVICE: 'NO_HAY_COINCIDENCIA_DE_PRECIO_UNITARIO_PARA_EL_SERVICIO',
      BILLING_NOT_YET_DONE: 'LA_FACTURACIÓN_AÚN_NO_ESTÁ_HECHA',
      BALANCE_NOT_FOUND: 'SALDO_NO_ENCONTRADO',
      ITEM_NOT_FOUND: 'ARTÍCULO_NO_ENCONTRADO',
      START_OR_END_DATE_MISSING_IN_USAGE_CONTAINER: 'FECHA_DE_INICIO_O_FIN_FALTANTE_EN_EL_CONTENEDOR_DE_USO',
      SERVICE_TYPE_MISSING_IN_USAGE_CONTAINER: 'TIPO_DE_SERVICIO_FALTANTE_EN_CONTENEDOR_DE_USO',
      REC_INDICATOR_MISSING_IN_USAGE_CONTAINER: 'INDICADOR_DE_REGISTRO_FALTANTE_EN_CONTENEDOR_DE_USO',
      MISSING_MANDATORY_ACCNT_ID_OR_PROV_ID: 'ID_DE_CTA_O_ID_DE_APROVISIONAMIENTO_OBLIGATORIOS_FALTANTE',
      NO_PRICE_OFFER_IN_DB_FOR_GIVEN_ID: 'NO_HAY_OFERTA_DE_PRECIOS_EN_LA_BD_PARA_EL_ID_DADO',
      TRANSACTION_PRICING_NOT_FOUND: 'PRECIO_DE_LA_TRANSACCIÓN_NO_ENCONTRADO',
      TIER_PRICING_NOT_FOUND: 'PRECIO_DE_BRACKET (ESCALONADO)_NO_ENCONTRADO',
      TIER_EVALUATION_FAILED: 'EVALUACIÓN_DE_BRACKET_FALLIDA',
      APPLICABLE_PRICE_GRANT_ACCUM_NOT_FOUND_IN_PO: 'PRECIO_APLICABLE_AL_SUBSIDIO_ACUMULADO_NO_ENCONTRADO_EN_O.C.',
      COMPLEX_PRICING_NOT_FOUND: 'PRECIO_COMPLEJO_NO_ENCONTRADO',
      COMPLEX_TIER_PRICING_NOT_FOUND: 'PRECIO_COMPLEJO_DE_BRACKETS_NO_ENCONTRADO',
      PLSQL_EXCEPTION: 'EXCEPCIÓN_PLSQL',
      UNKNOWN_ERROR: 'ERROR_DESCONOCIDO',
    },

    usageRecStatusFailedRecord: {
      SUSPENDED: 'SUSPENDIDO',
      FAILED: 'FALLIDO',
    },

    apiType: {
      INCOMING: 'ENTRANTE',
      OUTGOING: 'SALIENTE',
    },

    schedulePatternType: {
      DAILY: 'DIARIAMENTE',
      WEEKLY: 'SEMANALMENTE',
      MONTHLY: 'MENSUALMENTE',
      YEARLY: 'ANUALMENTE',
    },

    monthOfYearWithNumber: {
      1: 'Ene',
      2: 'Feb',
      3: 'Mar',
      4: 'Abr',
      5: 'Mayo',
      6: 'Jun',
      7: 'Jul',
      8: 'Ago',
      9: 'Sep',
      10: 'Oct',
      11: 'Nov',
      12: 'Dic',
    },

    arReasonCodeStatus: {
      ACTIVE: 'ACTIVO',
      CANCELLED: 'CANCELADO',
    },

    arReasonCodeObjectType: {
      BUNDLE: 'BUNDLE',
      CREDIT_NOTES: 'CREAR_NOTAS_DE_CRÉDITO',
      DEBIT_NOTES: 'CREAR_NOTAS_DE_DÉBITO',
      DISCOUNT_OFFER: 'OFERTA_DE_DESCUENTO',
      INVOICE: 'FACTURA',
      CANCELLED: 'CANCELADO',
      ITEM: 'ARTÍCULO',
      NOTES: 'NOTAS',
      PAYMENT: 'PAGO',
      PACKAGE: 'PAQUETE',
      PRICE_OFFER: 'PRECIO_DE_OFERTA',
    },

    // end 4

    fileRecordType: {
      HEADER: 'ENCABEZADO',
      DETAIL: 'DETALLE',
      TRAILER: 'TRAILER',
    },

    scaleUnit: {
      GIGABYTE: 'GIGABYTE',
      HOUR: 'HORA',
      KILOBYTE: 'KILOBYTE',
      MEGABYTE: 'MEGABYTE',
      MINUTE: 'MINUTO',
      NONE: 'NINGUNA',
      ONE: 'UNO',
      SECOND: 'SEGUNDO',
      MEGAWATT: 'MEGAWATT',
      KILOWATT: 'KILOWATT',
      KILOGRAM: 'KILOGRAMO',
      POUND: 'LIBRA',
      LITRE: 'LITRO',
      GALLON: 'GALÓN',
      KWH: 'KWH',
    },

    folioType: {
      PAYMENT: 'PAGO',
      CREDIT_NOTE: 'NOTA_DE_CRÉDITO',
      INVOICE: 'FACTURA',
      DEBIT_NOTE: 'NOTA_DE_DÉBITO',
      TRANSFER: 'TRANSFERIR',
      LADING_BILL: 'CARTA_PORTE',
    },

    // TODO add translation
    omsTaskType: {
      PROVISIONING_ONLY: 'SOLO_APROVISIONAMIENTO',
      BILLING_ONLY: 'SÓLO_FACTURACIÓN',
      BOTH: 'AMBOS',
    },

    evaluationComponent: {
      BALANCE_OF_ACCUMULATOR: 'SALDO_DEL_ACUMULADOR',
      USAGE_AMOUNT: 'MONTO_DEL_USO',
      USAGE_QUANTITY: 'CANTIDAD_DE_USO',
      USAGE_CONTAINER_FIELD: 'CAMPO_CONTENEDOR_DE_USO',
      PROVISIONING_ATTRIBUTE_NAME: 'NOMBRE_DEL_ATRIBUTO_DE_APROVISIONAMIENTO',
    },

    comparisonOperator: {
      LESS_THAN: 'MENOR_QUE',
      LESS_THAN_OR_EQUAL_TO: 'MENOR_QUE_O_IGUAL_A',
      GREATER_THAN: 'MAYOR_QUE',
      GREATER_THAN_OR_EQUAL_TO: 'MAYOR_QUE_O_IGUAL_A',
      EQUAL_TO: 'IGUAL_A',
      NOT_EQUAL_TO: 'DIFERENTE_A',
    },

    transactionBalanceType: {
      ACCUMULATOR: 'ACUMULADORE',
      ADJUSTMENT: 'AJUSTES',
      DISCOUNTING: 'DESCUENTO',
      DISPUTE: 'DISPUTAS',
      GRANT: 'SUBVENCIÓN',
      MANUAL_TRANSACTION: 'TRANSACCIÓN_MANUAL',
      PAYMENT: 'PAGO',
      PAYMENT_REVERSAL: 'REVERSIÓN_DE_PAGO',
      RATING: 'RECALIFICACIÓN',
      SETTLEMENT: 'ASENTAMIENTO',
      TAXATION: 'IMPUESTOS',
      WRITE_OFF: 'CANCELACIÓN',
      WRITE_OFF_REVERSAL: 'CANCELACIÓN_DE_REVERSIÓN',
    },

    provisioningStatus: {
      PENDING: 'PENDIENTE',
      PARTIAL: 'PARCIAL',
      COMPLETED: 'COMPLETADO',
      CANCELLED: 'CANCELADO',
      SKIPPED: 'OMITIDO',
      FAILED: 'FALLIDO',
      NOT_REQUIRED: 'NO_REQUERIDO',
    },

    quoteStatus: {
      CREATED: 'CREADO',
      CANCELLED: 'CANCELADO',
      SUBMITTED: 'ENVIADO',
      APPROVED: 'APROBADO',
      COMPLETED: 'COMPLETADO',
      CONVERTED: 'CONVERTIDO',
      FAILED_IN_CONVERT: 'FALLO_AL_CONVERTIR',
    },

    quoteType: {
      NEW: 'NUEVO',
      TRIAL: 'PRUEBA',
      MODIFY: 'MODIFICAR',
      RENEW: 'RENOVAR',
      OPT_IN: 'ADHESIÓN',
      OPT_OUT: 'EXCLUSIÓN',
      UPGRADE: 'SUBIR_DE_CATEGORÍA',
      DOWNGRADE: 'BAJAR_DE_CATEGORÍA',
      TRIAL_SERVICE: 'SERVICIO_PRUEBA',
    },

    trialType: {
      OPT_IN_TRIAL: 'PRUEBA_OPT_IN (Adhesión)',
      OPT_OUT_TRIAL: 'PRUEBA_OPT_OUT (Exclusión)',
    },

    accountCategory: {
      PREPAID: 'PREPAGO',
      POSTPAID: 'POSTPAGO',
      HYBRID: 'HÍBRIDO',
    },

    serviceUnitStatus: {
      ACTIVE: 'ACTIVO',
      CLOSED: 'CERRADO',
      SUSPENDED: 'SUSPENDIDO',
    },

    quotePricingLevel: {
      SIMPLE: 'SIMPLE',
      MEDIUM: 'MEDIO',
      COMPLEX: 'COMPLEJO',
    },

    subscriptionCategory: {
      PREPAID: 'PREPAGO',
      POSTPAID: 'POSTPAGO',
      PREPAID_AS_POSTPAID: 'PREPAGO_COMO_POSTPAGO',
    },

    termUnit: {
      DAYS: 'DÍAS',
      MONTHS: 'MESES',
      NONE: 'NINGUNA',
      QUARTERS: 'TRIMESTRES',
      WEEKS: 'SEMANAS',
      YEARS: 'AÑOS',
    },

    merchantType: {
      CRM_GATEWAY: 'GATEWAY_DE_CRM',
      CUSTOMER_SUPPORT_GATEWAY: 'GATEWAY_DE_SOPORTE_AL_CLIENTE',
      DOCUMENT_GATEWAY: 'GATEWAY_DE_DOCUMENTOS',
      FINANCE_GATEWAY: 'GATEWAY_FINANCIERO',
      OPERATION_GATEWAY: 'GATEWAY_OPERACIONES',
      PAYMENT_GATEWAY: 'GATEWAY_DE_PAGOS',
      PROJECT_GATEWAY: 'GATEWAY_DE_PROYECTOS',
      PROVISIONING_GATEWAY: 'GATEWAY_DE_APROVISIONAMIENTO',
      TAX_GATEWAY: 'GATEWAY_IMPUESTOS',
    },

    genericStatus: {
      ACTIVE: 'ACTIVO',
      INACTIVE: 'INACTIVO',
      CLOSED: 'CERRADO',
    },

    creditLimitBreachActions: {
      SUBSCRIPTION_SUSPENSION: 'SUSPENSIÓN_DE_LA_SUSCRIPCIÓN',
      SUBSCRIPTION_CLOSURE: 'CIERRE_DE_LA_SUSCRIPCIÓN',
    },

    additionalApiGetway: {
      UPDATE_PROVISIONING_REQUEST: 'ACTUALIZACIÓN_SOLICITUD_APROVISIONAMIENTO',
      GET_LOCATION_DATA: 'OBTENER_DATOS_DE_UBICACIÓN',
      GET_SPACE_DATA: 'OBTENER_DATOS_DE_ESPACIO',
      UPDATE_WORK_ORDER: 'ACTUALIZAR_ORDEN_DE_TRABAJO',
    },

    taxConfigRegular: {
      AMOUNT_TAXED: 'MONTO_GRAVADO',
    },

    webApiType: {
      REST: 'REST',
      SOAP: 'SOAP',
    },

    zoneType: {
      GEOGRAPHICAL: 'GEOGRÁFICO',
      STANDARD: 'ESTÁNDAR',
    },

    taxType: {
      MAIN_TAX: 'IMPUESTO_PRINCIPAL',
      ADDITIONAL_TAX: 'IMPUESTO ADICIONAL',
    },

    messageType: {
      USER: 'USUARIO',
      CUSTOMER: 'CLIENTE',
    },

    notificationTemplateType: {
      SMS: 'SMS',
      EMAIL: 'CORREO_ELECTRÓNICO',
      BOTH: 'AMBOS',
    },

    transactionSource: {
      ADJUSTMENT: 'AJUSTES',
      BACKOUT: 'RETROCEDER',
      BILLING: 'FACTURACIÓN',
      COLLECTION: 'COBRANZA',
      JOBS: 'TRABAJOS',
      MEDIATION: 'MEDIACIÓN',
      ORDERS: 'ORDENES',
      RERATING: 'RECALIFICACIÓN',
    },

    collectionAction: {
      CANCEL_SUBSCRIPTION: 'CANCELAR_SUSCRIPCIÓN',
      CLOSE_ACCOUNT: 'CIERRE_DE_CUENTA',
      FAILED_PAYMENT_EMAIL: 'EMAIL_PAGO_FALLIDO',
      FIRST_PAYMENT_RETRY: 'PRIMER_REINTENTO_DE_PAGO',
      FIRST_REMINDER_EMAIL: 'EMAIL_PRIMER_RECORDATORIO',
      INACTIVATE_SUBSCRIPTION: 'INACTIVAR_SUSCRIPCIÓN',
      SECOND_PAYMENT_RETRY: 'SEGUNDO_REINTENTO_DE_PAGO',
      SECOND_REMINDER_EMAIL: 'EMAIL_SEGUNDO_RECORDATORIO',
      THIRD_PAYMENT_RETRY: 'TERCER_REINTENGO_DE_PAGO',
      THIRD_REMINDER_EMAIL: 'EMAIL_TERCER_RECORDATORIO',
      WRITEOFF_ACCOUNT: 'CANCELACIÓN_CUENTA',
    },

    arTaxRule: {
      WITH_TAX: 'CON_IMPUESTO',
      WITHOUT_TAX: 'SIN_IMPUESTO',
      TAX_ONLY: 'SOLO_IMPUESTO',
    },

    arSource: {
      AGENT_CARE: 'AGENTE_ATENCIÓN',
      EXTERNAL: 'EXTERNO',
      INTERNAL_BATCH_JOBS: 'TRABAJOS_POR_LOTES_INTERNOS',
      PAYMENT_SUSPENSE: 'SUSPENSIÓN_DE_PAGO',
      PAYMENTS: 'PAGOS',
      RERATING: 'RECALIFICACIÓN',
      SELF_CARE: 'AUTO_ATENCIÓN',
    },

    exemptionLevel: {
      CITY: 'CIUDAD',
      COUNTY: 'MUNICIPIO',
      FEDERAL: 'FEDERAL',
      STATE: 'ESTADO',
    },

    sharingType: {
      CHARGE: 'CARGO',
      DISCOUNT: 'DESCUENTO',
      REVENUE: 'INGRESOS',
    },

    apiProtocol: {
      REST: 'REST (Representational State Transfer)',
      SOAP: 'SOAP (Simple Object Access Protocol)',
      GRAPHQL: 'GRAPHQL',
    },

    paymentGatewayUrlType: {
      BASE_URL: 'URL_BASE',
      AUTHORIZE_CREDIT_CARD: 'AUTORIZAR_TARJETA_CRÉDITO',
      CAPTURE_CREDIT_CARD: 'CAPTURAR_TARJETA_DE_CRÉDITO',
      CREDIT_CREDIT_CARD: 'CRÉDITO_TARJETA_DE_CRÉDITO',
      VOID_CREDIT_CARD: 'TARJETA_DE_CRÉDITO_INVÁLIDA',
      AUTHORIZE_ECHECK: 'AUTORIZAR_CHEQUE_ELECTRÓNICO',
      CAPTURE_ECHECK: 'CAPTURAR_CHEQUE_ELECTRÓNICO',
      CREDIT_ECHECK: 'CRÉDITO_CHEQUE_ELECTRÓNICO',
      VOID_ECHECK: 'CHEQUE_ELECTRÓNICO_INVÁLIDO',
    },

    collectionActionWorkingDay: {
      NEXT_WORKING_DAY: 'SIGUIENTE_DÍA_HÁBIL',
    },

    templateType: {
      CREDIT_NOTE: 'NOTA_DE_CRÉDITO',
      DEBIT_NOTE: 'NOTA_DE_DÉBITO',
      INVOICE: 'FACTURA',
      LADING_BILL: 'CARTA_PORTE',
      PAYMENT: 'PAGO',
      TRANSFER_DOCUMENT: 'TRANSFERIR_DOCUMENTO',
      RETENTION: 'RETENCIÓN',
      PAYMENT_AGREEMENT: 'ACUERDO_DE_PAGO',
      PAYMENT_INSTALLMENT_REPORT: 'INFORME_DE_PAGOS_A_PLAZOS',
      USAGE_CONSUMPTION_STATEMENT: 'REPORTE_DE_CONSUMO',
    },

    templateFileType: {
      HTML: 'HTML',
      XSLT: 'XSLT',
    },

    templateFileStatus: {
      ACTIVE: 'ACTIVO',
      INACTIVE: 'INACTIVO',
    },

    invoiceSummaryType: {
      RECURRING: 'RECURRENTE',
      NON_RECURRING: 'NO_RECURRENTES',
      USAGE: 'USO',
      TAX: 'IMPUESTO',
    },

    billUnitStatus: {
      ACTIVE: 'ACTIVO',
      PENDING: 'PENDIENTE',
    },

    activityType: {
      ACCOUNT_INFO: 'INFORMACIÓN_DE_LA_CUENTA',
      ADDRESS: 'DIRECCIÓN',
      AROPS: 'CXCOPS',
      BILLING: 'FACTURACIÓN',
      BILLING_PROFILE: 'PERFIL_DE_FACTURACIÓN',
      BUNDLE: 'BUNDLE',
      COLLECTION: 'COBRANZA',
      CONTACT: 'CONTACTO',
      CUSTOM_ATTRIBUTES: 'ATRIBUTOS_PERSONALIZADOS',
      CUSTOMER: 'CLIENTE',
      DEPENDENCY: 'DEPENDENCIA',
      DISCOUNT_OFFER: 'OFERTA_DE_DESCUENTO',
      IDENTITY: 'IDENTIDAD',
      INTEGRATION: 'INTEGRACIÓN',
      ITEM: 'ARTÍCULO',
      ORDER: 'ORDEN',
      PACKAGE: 'PAQUETE',
      PAYMENT: 'PAGO',
      PAYMENT_PROFILE: 'PERFIL_PAGO',
      PAYMENT_REVERSAL: 'REVERSIÓN_DE_PAGO',
      PAYMENT_SUSPENSE: 'SUSPENSIÓN_DE_PAGO',
      PRICE_OFFER: 'PRECIO_DE_OFERTA',
      PROVISIONING: 'APROVISIONAMIENTO',
      QUOTE: 'COTIZACIÓN',
      TAX: 'IMPUESTO',
    },

    expensePeriodUnit: {
      MONTH: 'MES',
      QUARTER: 'TRIMESTRE',
      YEARS: 'AÑOS',
    },

    moveProvisioningTypeActions: {
      MOVE_PROVISIONING: 'CAMBIO_OLT',
      CHANGE_PROVISIONING: 'CAMBIO_ONT',
      MOVE_TO_STD_PROVISIONING: 'MIGRACIÓN',
    },

    quoteSource: {
      EXTERNAL: 'EXTERNO',
      INTERNAL: 'INTERNO',
    },

    bonusPeriodUnit: {
      MONTH: 'MES',
      QUARTER: 'TRIMESTRE',
      YEARS: 'AÑOS',
    },

    objectFileType: {
      CSV: 'CSV',
      HTML: 'HTML',
      PDF: 'PDF',
      TXT: 'TXT',
      XML: 'XML',
      XSLT: 'XSLT',
    },

    scheduleFrequency: {
      DAILY: 'DIARIAMENTE',
      SCHEDULED: 'SCHEDULED',
    },

    apiKeyAddTo: {
      HEADER: 'ENCABEZADO',
      QUERY_PARAMS: 'QUERY_PARAMS',
    },
  },
};
